<app-mobile-preview>
  <div class="packet-container">
    <div class="preview-header">
      <div class="preview-header-content">
        <div class="title">Услуги интерактивного ТВ</div>
        <app-preview-box
          width="100%"
          height="64px"
          [cornerRadius]="12"
          style="margin-bottom: 12px"
        ></app-preview-box>
        <app-preview-box width="100%" height="35px" [cornerRadius]="6"></app-preview-box>
      </div>
    </div>
    <div class="preview-body">
      <div class="packet-item">
        <div class="packet-item-body">
          <div class="row">
            <div class="col-auto">
              <div class="icon" style="background-image: url(&quot;{{ iconPreview }}&quot;)">
                <div *ngIf="!iconPreview" class="icon-preview"></div>
              </div>
            </div>
            <div class="col">
              <div *ngIf="title == undefined" class="packet-item-title-placeholder">
                Название пакета
              </div>
              <div *ngIf="title != undefined" class="packet-item-title">{{ title }}</div>
              <div *ngIf="numberOfChannels == undefined" class="packet-item-secondary">
                Количество каналов
              </div>
              <div *ngIf="numberOfChannels != undefined" class="packet-item-channels-number">
                Количество каналов: {{ numberOfChannels }}
              </div>
              <div class="channels-list">Список каналов<i class="ti ti-chevron-right"></i></div>
            </div>
          </div>

          <div *ngIf="desc == undefined" class="packet-item-secondary" style="margin-top: 8px">
            Описание пакета каналов
          </div>
          <div *ngIf="desc != undefined" class="packet-item-secondary" style="margin-top: 8px">
            {{ desc }}
          </div>
        </div>
        <hr />
        <div class="packet-item-footer">
          <div class="row" style="display: flex; align-items: center">
            <div *ngIf="cost != undefined" class="col-6 cost">
              {{ cost }}<span class="cost-dot">.</span
              ><span class="cost-zeros">{{ fracPart }}</span> ₽/ мес.
            </div>
            <div *ngIf="cost == undefined" class="col-6 cost-placeholder">Стоимость</div>
            <div class="col-6">
              <div style="float: right">
                <input class="tgl tgl-flat" id="cb4" type="checkbox" />
                <label class="tgl-btn" for="cb4"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="152px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
    </div>
  </div>
</app-mobile-preview>
