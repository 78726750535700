export class Performer {
  constructor(public name: string) {}
}

export class ConnectionRequest {
  constructor(
    public id: number,
    public buildingId: number,
    public flat: number,
    public userId: number | null,
    public requested_type: string,
    public phoneNumber: string,
    public wantedTime: number,
    public tariffId: number,
    public eventId: number,
    public connectionType: string,
    public city: string,
    public dailyLink: string,
  ) {}

  public getWantedTime() {
    return new Date(this.wantedTime).toLocaleDateString('ru-RU');
  }
}
