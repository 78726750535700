import { FormGroup } from '@angular/forms';
import { IServiceExtAttributesFactory } from './iservice-ext-attributes-factory';
import { ISmotreshkaCinemaAttributes } from '../../../../../data/dto/ismotreshka-cinema-attributes';

export class SmotreshkaOnlineCinemaAttributes implements IServiceExtAttributesFactory {
  createAttributes(form: FormGroup): ISmotreshkaCinemaAttributes {
    return {
      cinema_type: form.value['cinemaType'],
    };
  }
}
