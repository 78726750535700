<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <app-abonent-services-form
    [abonentServiceForm]="updateAbonentServiceForm"
    [submitButtonText]="'Сохранить'"
    [imagePreview]="imagePreview"
    [title]="title"
    [chosenType]="chosenType"
    (abonentServiceFormData)="onAbonentServiceFormData($event)"
    (outputCroppedIcon)="onOutputCroppedIcon($event)"
    (outputCroppedImage)="onOutputCroppedImage($event)"
  ></app-abonent-services-form>
</app-page-wrapper>
