import { NgModule } from '@angular/core';

import {
  IconBrandGithub,
  IconCamera,
  IconChecklist,
  IconHeart,
  IconHome,
  IconListLetters,
  IconHistoryToggle,
  IconChalkboard,
  IconGhost,
  IconLayout2,
  IconCheckupList,
  IconBuildingSkyscraper,
} from 'angular-tabler-icons/icons';
import { TablerIconsModule } from 'angular-tabler-icons';

const icons = {
  IconCamera,
  IconHeart,
  IconBrandGithub,
  IconHome,
  IconChecklist,
  IconListLetters,
  IconHistoryToggle,
  IconChalkboard,
  IconGhost,
  IconLayout2,
  IconCheckupList,
  IconBuildingSkyscraper,
};

@NgModule({
  imports: [TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
