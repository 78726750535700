import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_CONFIG, environment } from './environments/environment';
import { OAuthApplyTokenInterceptor } from './core/network/interceptors/oauth.token.interceptor';
import { MarkdownModule } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    [
      { provide: APP_CONFIG, useValue: environment },
      { provide: HTTP_INTERCEPTORS, useClass: OAuthApplyTokenInterceptor, multi: true },
    ],
    importProvidersFrom(MarkdownModule.forRoot()),
  ],
};
