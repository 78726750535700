import { Component, Input, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeContact } from '../../domain/orion-office-contact';
import { OrionOfficesContactsRepository } from '../../data/repositories/OrionOfficesContactsRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-contacts',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
  ],
  templateUrl: './orion-offices-contacts.component.html',
  styleUrl: './orion-offices-contacts.component.css',
})
export class OrionOfficesContactsComponent implements OnInit {
  public officeContacts: Loadable<OfficeContact[]> = new Loadable<OfficeContact[]>({
    kind: 'NotRequested',
  });
  @Input() public currentOffice: any;
  @Input() public currentOfficeContacts: OfficeContact[] | undefined = new Array();

  constructor(
    private OrionOfficesContactsRepo: OrionOfficesContactsRepository,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  async getOfficeContacts(office_id: number) {
    if (this.officeContacts.status != UIStateType.Loading) {
      this.officeContacts = new Loadable<OfficeContact[]>({ kind: 'Loading' });
      await this.OrionOfficesContactsRepo.getAllByOffice(office_id).then((data) => {
        this.officeContacts = Loadable.getFromDataStatus(data);
      });
    }
  }

  async deleteContact(contact_id: number) {
    await this.OrionOfficesContactsRepo.delete(contact_id);
    await this.getOfficeContacts(this.currentOffice.id);
    this.currentOfficeContacts = this.officeContacts.data;
  }

  protected readonly UIStateType = UIStateType;
}
