import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { NgForOf, NgIf } from '@angular/common';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { AttachmentRepository } from '../../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../../entity/attachments/domain/attachment';

@Component({
  selector: 'app-service-ext-full-desc-form-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    NgForOf,
    FormComponentComponent,
    LMarkdownEditorModule,
  ],
  templateUrl: './service-ext-full-desc-form-component.component.html',
  styleUrl: './service-ext-full-desc-form-component.component.css',
})
export class ServiceExtFullDescFormComponentComponent {
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  coverChangedEvent: Event | null = null;
  croppedCover?: Blob | null;
  addFullDesc: FormArray<any> = new FormArray<any>([]);
  coverPreview!: string;
  addFullDescTitle!: string;
  addFullDescText!: string;

  @Output() outputCroppedCover: EventEmitter<Blob> = new EventEmitter();
  @Output() outputCoverPreview: EventEmitter<string> = new EventEmitter();
  @Output() outputFullDesc: EventEmitter<string> = new EventEmitter();
  @Output() outputAddFullDesc: EventEmitter<{ [id: string]: any }> = new EventEmitter();

  @Input() form!: FormGroup;

  constructor(
    private AttachmentRepo: AttachmentRepository,
    private formBuilder: FormBuilder,
  ) {
    this.doUpload = this.doUpload.bind(this);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      title: new FormControl(undefined),
      text: new FormControl(undefined),
    });
  }

  addItem(): void {
    this.addFullDesc = this.addFullDescData;
    this.addFullDesc.push(this.createItem());
  }

  get addFullDescData() {
    return <FormArray>this.form.get('addFullDesc');
  }

  coverFileChangeEvent($event: any): void {
    this.coverChangedEvent = $event;
  }

  coverCropped(event: ImageCroppedEvent): void {
    this.croppedCover = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.coverPreview = e.target.result;
      this.outputCoverPreview.emit(this.coverPreview);
    };

    if (this.croppedCover) {
      reader.readAsDataURL(this.croppedCover);
      this.outputCroppedCover.emit(this.croppedCover);
    }
  }

  removeCover(): void {
    this.coverChangedEvent = null;
  }

  async doUpload(files: Array<File>) {
    this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });
    await this.AttachmentRepo.create(files[0]).then((data) => {
      this.attachment = Loadable.getFromDataStatus(data);
    });

    return Promise.resolve([
      {
        name: this.attachment.data[0].name,
        url: this.attachment.data[0].content.attributes.content_path,
        isImg: true,
      },
    ]);
  }

  changeFullDesc() {
    this.outputFullDesc.emit(this.form.value['fullDesc'] as string);
  }

  changeAddFullDesc() {
    this.outputAddFullDesc.emit(this.addFullDescData.value);
  }
}
