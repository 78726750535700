import { Type } from '@angular/core';
import { IServiceExtFormComponentFactory } from './iservice-ext-form-component-factory';
import { ServiceExtSmotreshkaPacketFormComponentComponent } from '../../../service-ext-smotreshka-packet-form-component/service-ext-smotreshka-packet-form-component.component';
import { IFormComponentInputs, IFormComponentOutputs } from '../../interfaces/iform-component';
import {
  ISmotreshkaTVPacketFormComponentInputs,
  ISmotreshkaTVPacketFormComponentOutputs,
} from '../../interfaces/ismotreshka-tv-packet-component';
import { FormControl, Validators } from '@angular/forms';

export class SmotreshkaTVPacketFormComponentFactory implements IServiceExtFormComponentFactory {
  getComponent(): Type<ServiceExtSmotreshkaPacketFormComponentComponent> {
    return ServiceExtSmotreshkaPacketFormComponentComponent;
  }

  getInputs(allInputs: IFormComponentInputs): ISmotreshkaTVPacketFormComponentInputs {
    allInputs.form.addControl('icon', new FormControl(undefined, Validators.required));
    allInputs.form.addControl('packId', new FormControl(undefined));
    allInputs.form.addControl('numberOfChannels', new FormControl(undefined));

    return {
      form: allInputs.form,
    };
  }

  getOutputs(allOutputs: IFormComponentOutputs): ISmotreshkaTVPacketFormComponentOutputs {
    return {
      outputCroppedIcon: allOutputs.outputCroppedIcon,
      outputIconPreview: allOutputs.outputIconPreview,
    };
  }
}
