import { Attachment } from '../../domain/attachment';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { AttachmentMapper } from '../mappers/attachment-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../core/network/data.status';
import { AttachmentApiDto } from '../attachment-api-dto';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AttachmentRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private attachmentMapper: AttachmentMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(attachment: Blob): Promise<DataStatus<Attachment[]>> {
    let headers: HttpHeaders = new HttpHeaders();
    let formData = new FormData();

    formData.append('files', attachment);
    headers = headers.append('enctype', 'multipart/form-data');

    let response: DataStatus<AttachmentApiDto[]> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/attachments`,
      formData,
      headers,
    );

    let successResponse = response as DataStatusSuccess<AttachmentApiDto[]>;
    return successResponse.map((data) => {
      return data.map((item) => this.attachmentMapper.toDomain(item));
    });
  }
}
