import { Component } from '@angular/core';
import { StoryRepository } from '../../data/repositories/StoryRepository';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { Story } from '../../domain/story';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { DateOutputPipe } from '../../../../components/common/pipes/date-output-pipe';
import { OrionOfficesBusinessHoursUpdateComponent } from '../../../orion-offices/orion-offices-business-hours/components/orion-offices-business-hours-update/orion-offices-business-hours-update.component';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';

@Component({
  selector: 'app-stories-list',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
    PaginationViewComponent,
    DateOutputPipe,
    OrionOfficesBusinessHoursUpdateComponent,
  ],
  templateUrl: './stories-list.component.html',
  styleUrl: './stories-list.component.css',
})
export class StoriesListComponent {
  public pageActiveStories: Loadable<PaginationResponse<Story>> = new Loadable<
    PaginationResponse<Story>
  >();
  public pageHiddenStories: Loadable<PaginationResponse<Story>> = new Loadable<
    PaginationResponse<Story>
  >();
  public pageTitle: string = 'Список историй';
  public pageSubtitle: string = 'Истории';
  public activeStoriesPerPage: number = 5;
  public activeStoriesPageNumber: number = 1;
  public activeStoriesCurrentPageNumber: number = 1;
  public hiddenStoriesPerPage: number = 5;
  public hiddenStoriesPageNumber: number = 1;
  public hiddenStoriesCurrentPageNumber: number = 1;

  constructor(private StoryRepo: StoryRepository) {}

  ngOnInit(): void {
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async getAllActiveStories(
    per_page: number,
    page_number: number,
    search_name: string | null = null,
  ): Promise<void> {
    if (this.pageActiveStories.status != UIStateType.Loading) {
      this.pageActiveStories = new Loadable<PaginationResponse<Story>>({
        kind: 'Loading',
      });
      await this.StoryRepo.findAllPost(per_page, page_number, true, search_name).then((data) => {
        this.pageActiveStories = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeActiveStoriesPerPage($event: any) {
    this.activeStoriesPerPage = $event.target.value;
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
  }

  changeActiveStoriesPage(pageNumber: number) {
    this.activeStoriesPageNumber = pageNumber;
    this.activeStoriesCurrentPageNumber = pageNumber;
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
  }

  async getAllHiddenStories(
    per_page: number,
    page_number: number,
    search_name: string | null = null,
  ): Promise<void> {
    if (this.pageHiddenStories.status != UIStateType.Loading) {
      this.pageHiddenStories = new Loadable<PaginationResponse<Story>>({
        kind: 'Loading',
      });
      await this.StoryRepo.findAllPost(per_page, page_number, false, search_name).then((data) => {
        this.pageHiddenStories = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeHiddenStoriesPerPage($event: any): void {
    this.hiddenStoriesPerPage = $event.target.value;
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  changeHiddenStoriesPage(pageNumber: number): void {
    this.hiddenStoriesPageNumber = pageNumber;
    this.hiddenStoriesCurrentPageNumber = pageNumber;
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  searchStory($event: any): void {
    let searchName = $event.target.value;

    if (searchName !== '') {
      this.getAllActiveStories(this.activeStoriesPerPage, 1, searchName).finally();
      this.getAllHiddenStories(this.hiddenStoriesPerPage, 1, searchName).finally();
    } else {
      this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
      this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
    }
  }

  async deactivateStory(storyId: number): Promise<void> {
    let body = {
      'is_active': false,
    };
    await this.StoryRepo.update(body, storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async activateStory(storyId: number): Promise<void> {
    let body = {
      'is_active': true,
    };
    await this.StoryRepo.update(body, storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  async deleteStory(storyId: number): Promise<void> {
    await this.StoryRepo.delete(storyId);
    this.getAllActiveStories(this.activeStoriesPerPage, this.activeStoriesPageNumber).finally();
    this.getAllHiddenStories(this.hiddenStoriesPerPage, this.hiddenStoriesPageNumber).finally();
  }

  protected readonly UIStateType = UIStateType;
}
