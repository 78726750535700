import { ServiceExtension } from '../../domain/entities/service-extension';
import { ServiceExtensionApiDto } from '../dto/service-ext-api-dto';

import { Injectable } from '@angular/core';
import { DeeplinkMapper } from '../../../../../entity/deeplinks/data/mappers/deeplink-mapper';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';

@Injectable({ providedIn: 'root' })
export class ServiceExtensionMapper {
  constructor(
    private attachmentMapper: AttachmentMapper,
    private deeplinkMapper: DeeplinkMapper,
  ) {}

  public toDomain(dto: ServiceExtensionApiDto): ServiceExtension {
    return new ServiceExtension(
      dto.id,
      dto.title,
      dto.description,
      dto.service_id,
      dto.service_type,
      dto.icon !== null ? this.attachmentMapper.toDomain(dto.icon) : null,
      dto.banner !== null ? this.attachmentMapper.toDomain(dto.banner) : null,
      dto.cover !== null ? this.attachmentMapper.toDomain(dto.cover) : null,
      dto.attributes,
    );
  }
}
