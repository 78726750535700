import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { NgFor, NgIf } from '@angular/common';
import { MobileSmotreshkaPacketPreviewComponent } from '../mobile-smotreshka-packet-preview/mobile-smotreshka-packet-preview.component';
import { MarkdownComponent } from 'ngx-markdown';

@Component({
  selector: 'app-mobile-full-desc-preview',
  standalone: true,
  imports: [
    MobilePreviewComponent,
    PreviewBoxComponent,
    NgIf,
    MobileSmotreshkaPacketPreviewComponent,
    NgFor,
    MarkdownComponent,
  ],
  templateUrl: './mobile-full-desc-preview.component.html',
  styleUrl: './mobile-full-desc-preview.component.css',
})
export class MobileFullDescPreviewComponent implements OnChanges {
  @Input() title!: string;
  @Input() fullDesc!: string;
  @Input() cost!: string;
  @Input() coverPreview!: string;
  @Input() addFullDesc: { [id: string]: any }[] = [];
  fracPart: string = '00';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cost']) {
      if (changes['cost'].currentValue != undefined) {
        if (this.cost.split('.').length > 1) {
          this.fracPart = this.cost.split('.')[this.cost.split('.').length - 1].slice(0, 2);
          this.cost = this.cost.split('.')[0];
        } else {
          this.fracPart = '00';
        }
      }
    }
  }
}
