<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper [loadable]="orionOffice" loadingTitle="Загрузка данных...">
    <ng-template>
      <div class="row row-cards">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Офис</h3>
            <div class="ms-auto text-secondary">
              <div class="btn-list flex-nowrap">
                <a
                  [routerLink]="['update-office']"
                  [state]="orionOffice.data"
                  class="list-group-item-actions"
                >
                  <i class="ti ti-pencil"></i>
                </a>
                <a class="list-group-item-actions" (click)="deleteOffice(orionOffice.data.id)">
                  <i class="ti ti-trash"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="form datagrid">
              <div class="datagrid-item">
                <div class="datagrid-title">ID</div>
                <div class="datagrid-content">{{ orionOffice.data.id }}</div>
              </div>
              <div class="datagrid-item">
                <div class="datagrid-title">Адрес</div>
                <div class="datagrid-content">{{ orionOffice.data.address }}</div>
              </div>
              <div class="datagrid-item">
                <div class="datagrid-title">Широта</div>
                <div class="datagrid-content">{{ orionOffice.data.latitude }}</div>
              </div>
              <div class="datagrid-item">
                <div class="datagrid-title">Долгота</div>
                <div class="datagrid-content">{{ orionOffice.data.longitude }}</div>
              </div>
              <div class="datagrid-item">
                <div class="datagrid-title">Город</div>
                <div class="datagrid-content">{{ getOfficeCityType(orionOffice.data.city) }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <app-orion-offices-contacts
            [currentOffice]="orionOffice.data"
            [currentOfficeContacts]="orionOffice.data.contacts"
          ></app-orion-offices-contacts>
        </div>
        <div class="col-md-6">
          <app-orion-offices-business-hours
            [currentOffice]="orionOffice.data"
            [officeHours]="orionOffice.data.businessDays"
          ></app-orion-offices-business-hours>
        </div>
      </div>
    </ng-template>
  </app-loadable-wrapper>
</app-page-wrapper>
