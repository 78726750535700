import { Injectable, Type } from '@angular/core';
import { ServiceExtensionType } from '../../../../domain/entities/service-type';
import { IServiceExtFormComponentFactory } from './service-ext-form-component-factories/iservice-ext-form-component-factory';
import { GeneralFormComponentFactory } from './service-ext-form-component-factories/general-form-component-factory';
import { SmotreshkaTVPacketFormComponentFactory } from './service-ext-form-component-factories/smotreshka-tv-packet-form-component-factory';
import { SmotreshkaOnlineCinemaFormComponentFactory } from './service-ext-form-component-factories/smotreshka-online-cinema-form-component-factory';
import { CATVPacketFormComponentFactory } from './service-ext-form-component-factories/catv-form-component-factory';

const ServiceExtFormComponent = {
  [ServiceExtensionType.GENERAL]: GeneralFormComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_TV_PACKET]: SmotreshkaTVPacketFormComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA]: SmotreshkaOnlineCinemaFormComponentFactory,
  [ServiceExtensionType.CATV_PACKET]: CATVPacketFormComponentFactory,
};

@Injectable({ providedIn: 'root' })
export class ServiceExtFormComponentFactoryAggregate {
  getFactory(serviceType: ServiceExtensionType): IServiceExtFormComponentFactory | null {
    try {
      return new ServiceExtFormComponent[serviceType]();
    } catch {
      return null;
    }
  }
}
