import { Component, OnInit } from '@angular/core';
import { ConnectionRequest, Performer } from '../../domain/connection-request';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { ConnectionRequestRepository } from '../../data/repositories/ConnectionRequestRepository';
import { NgForOf, NgIf, NgFor } from '@angular/common';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { PaginationTableViewComponent } from '../../../../components/pagination-table-view/pagination-table-view.component';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';

@Component({
  selector: 'app-connection-requests',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    PaginationTableViewComponent,
    LoadableWrapperComponent,
  ],
  templateUrl: './connection-requests.component.html',
  styleUrl: './connection-requests.component.css',
})
export class ConnectionRequestsComponent implements OnInit {
  public pageConnectionRequests: Loadable<PaginationResponse<ConnectionRequest>> = new Loadable<
    PaginationResponse<ConnectionRequest>
  >();
  pageTitle: string = 'Заявки на подключение';
  pageSubtitle: string = 'Заявки на подключение';
  public tableHeaders: Array<string> = [
    'ID',
    'ID здания',
    'Квартира',
    'ID пользователя',
    'Вид подключения',
    'Номер телефона',
    'Желаемая дата',
    'ID тарифа',
    'ID заявки в Daily',
    'Тип подключения',
    'Город',
    'Ссылка на Daily',
  ];
  public values = Object.values;
  public perPage: number = 10;
  public pageNumber: number = 1;
  public currentPageNumber: number = 1;

  constructor(private ConnectionRequestRepo: ConnectionRequestRepository) {}

  ngOnInit(): void {
    this.getAllConnectionRequestsPost(this.perPage, this.pageNumber).finally();
  }

  async getAllConnectionRequestsPost(per_page: number, page_number: number): Promise<void> {
    if (this.pageConnectionRequests.status != UIStateType.Loading) {
      this.pageConnectionRequests = new Loadable<PaginationResponse<ConnectionRequest>>({
        kind: 'Loading',
      });
      await this.ConnectionRequestRepo.findAllPost(per_page, page_number).then((data) => {
        this.pageConnectionRequests = Loadable.getFromDataStatus(data);
      });
    }
  }

  changePerPage($event: any) {
    this.perPage = $event.target.value;
    this.getAllConnectionRequestsPost(this.perPage, this.pageNumber).finally();
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.currentPageNumber = pageNumber;
    this.getAllConnectionRequestsPost(this.perPage, this.pageNumber).finally();
  }

  protected readonly UIStateType = UIStateType;
}
