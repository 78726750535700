import { Injectable, Type } from '@angular/core';
import { ServiceExtensionType } from '../../../../domain/entities/service-type';
import { GeneralAttributesFactory } from './service-ext-attributes-factories/general-attributes-factory';

import { IServiceExtAttributesFactory } from './service-ext-attributes-factories/iservice-ext-attributes-factory';
import { SmotreshkaOnlineCinemaAttributes } from './service-ext-attributes-factories/smotreshka-online-cinema-attributes-factory';
import { SmotreshkaTVPacketAttributesFactory } from './service-ext-attributes-factories/smotreshka-tv-packet-attributes-factory';
import { CATVPacketAttributesFactory } from './service-ext-attributes-factories/catv-packet-attributes-factory';

const ServiceExtFormComponent = {
  [ServiceExtensionType.GENERAL]: GeneralAttributesFactory,
  [ServiceExtensionType.SMOTRESHKA_TV_PACKET]: SmotreshkaTVPacketAttributesFactory,
  [ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA]: SmotreshkaOnlineCinemaAttributes,
  [ServiceExtensionType.CATV_PACKET]: CATVPacketAttributesFactory,
};

@Injectable({ providedIn: 'root' })
export class ServiceExtAttributesFactoryAggregate {
  getFactory(serviceType: ServiceExtensionType): IServiceExtAttributesFactory | null {
    try {
      return new ServiceExtFormComponent[serviceType]();
    } catch {
      return null;
    }
  }
}
