<form
  class="card"
  [formGroup]="bannerForm"
  (ngSubmit)="onSubmit()"
  onsubmit="this.submit_button.disabled = true;"
>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-3">
          <app-file-input
            formControlName="image"
            (onFileSelect)="fileChangeEvent($event)"
            (onPreviewCreate)="preview = $event"
          ></app-file-input>
        </div>
        <div class="form-floating mb-3">
          <input
            class="form-control"
            id="floatingInput"
            placeholder="Заголовок"
            formControlName="title"
            (change)="changeFakeBtnText($event)"
          />
          <label for="floatingInput">Заголовок</label>
        </div>
        <div class="form-floating mb-3">
          <input
            class="form-control"
            id="floatingInput"
            placeholder="Ссылка"
            formControlName="link"
          />
          <label for="floatingInput">Ссылка</label>
        </div>
        <div class="form-floating mb-3">
          <textarea
            class="form-control"
            placeholder="Описание"
            id="floatingTextarea2"
            style="height: 100px"
            formControlName="description"
          ></textarea>
          <label for="floatingTextarea2">Описание</label>
        </div>
        <app-image-preview-with-button
          [preview]="preview"
          [fakeBtnText]="fakeBtnText"
        ></app-image-preview-with-button>
      </div>
      <div
        class="col-lg-7 d-flex align-items-center justify-content-center px-0"
        style="background-color: #ebebeb"
      >
        <image-cropper
          [aspectRatio]="328 / 123"
          [imageChangedEvent]="imageChangedEvent"
          (imageCropped)="imageCropped($event)"
          format="jpeg"
          [maintainAspectRatio]="true"
        ></image-cropper>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex">
      <button type="button" class="btn me-auto" (click)="router.navigate(['/banners'])">
        Назад
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        name="submit_button"
        [disabled]="!bannerForm.valid"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</form>
