import { Inject, Injectable } from '@angular/core';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { BillingTariffMapper } from '../mappers/billing-tariff-mapper';
import { IEnvironment } from '../../../../environments/ienvironment';
import { APP_CONFIG } from '../../../../environments/environment';
import { DataStatus } from '../../../../core/network/data.status';
import { BillingTariff } from '../../domain/billing-tariff';
import { PaginationResponseDto } from '../../../../core/dashboard/data/dto/pagination-response.dto';
import { BillingTariffApiDto } from '../billing-tariff-api-dto';
import { PaginationResponse } from '../../../../core/dashboard/domain/pagination-response';

@Injectable({ providedIn: 'root' })
export class BillingTariffRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private billingTariffMapper: BillingTariffMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(
    propertyName: string,
    propertyValue: string,
    page: number,
    perPage: number,
  ): Promise<DataStatus<PaginationResponse<BillingTariff>>> {
    let body = {
      pagination: {
        page_number: page,
        per_page: perPage,
      },
      filters: [
        { column: 'is_deleted', operator: 'eq', value: false },
        { column: 'is_old', operator: 'eq', value: false },
        { column: propertyName, operator: 'ilike', value: `%${propertyValue}%` },
      ],
    };

    let response: DataStatus<PaginationResponseDto<BillingTariffApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/tariffs`,
        body,
      );
    return response.map((data) => {
      return new PaginationResponse(
        data.pages,
        data.count,
        data.per_page,
        data.data.map((item) => this.billingTariffMapper.toDomain(item)),
      );
    });
  }
}
