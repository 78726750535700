<app-mobile-preview>
  <div class="story-preview-container">
    <div class="preview-header">
      <app-preview-box width="100%" height="24px" [cornerRadius]="8"></app-preview-box>
      <app-preview-box
        width="100%"
        height="32px"
        [cornerRadius]="8"
        style="margin-top: 18px"
      ></app-preview-box>
      <app-preview-box
        width="30%"
        height="24px"
        [cornerRadius]="8"
        style="margin-top: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="64px"
        [cornerRadius]="12"
        style="margin-top: 12px"
      ></app-preview-box>

      <div class="app-items">
        <app-preview-box width="100%" height="60px" [cornerRadius]="12"></app-preview-box>
        <app-preview-box width="100%" height="60px" [cornerRadius]="12"></app-preview-box>
        <app-preview-box width="100%" height="60px" [cornerRadius]="12"></app-preview-box>
        <app-preview-box width="100%" height="60px" [cornerRadius]="12"></app-preview-box>
      </div>
    </div>
    <div class="story-block">
      <div class="story-item">
        <div class="story-item-content" style="background-image: url('{{ preview }}')">
          <div class="annotation">
            <span id="text">{{ annotation }}</span>
          </div>
        </div>
      </div>
      <app-preview-box width="80px" height="80px" [cornerRadius]="16"></app-preview-box>
      <app-preview-box width="80px" height="80px" [cornerRadius]="16"></app-preview-box>
      <app-preview-box width="80px" height="80px" [cornerRadius]="16"></app-preview-box>
    </div>

    <div class="other-background-block">
      <app-preview-box width="100%" height="24px" [cornerRadius]="8"></app-preview-box>
      <app-preview-box
        width="100%"
        height="200px"
        [cornerRadius]="16"
        style="margin-top: 16px"
      ></app-preview-box>
    </div>
  </div>
</app-mobile-preview>

<!--<img class="slide" [src]="preview" />-->
<!--<div class="annotation">-->
<!--  <span id="text">{{ annotation }}</span>-->
<!--</div>-->
