import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../components/common/mobile-preview/preview-box/preview-box.component';

@Component({
  selector: 'app-mobile-story-preview-preview',
  standalone: true,
  imports: [NgFor, MarkdownModule, MobilePreviewComponent, PreviewBoxComponent],
  templateUrl: './mobile-story-preview-preview.component.html',
  styleUrl: './mobile-story-preview-preview.component.css',
})
export class MobileStoryPreviewPreviewComponent {
  @Input() public annotation: string = '';
  @Input() public preview: string = '';
}
