import { Component, Input, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeBusinessHours, WeekDaysEnum } from '../../domain/orion-office-business-hours';
import { OrionOfficeBusinessHoursRepository } from '../../data/repositories/OrionOfficeBusinessHoursRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-business-hours',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
  ],
  templateUrl: './orion-offices-business-hours.component.html',
  styleUrl: './orion-offices-business-hours.component.css',
})
export class OrionOfficesBusinessHoursComponent implements OnInit {
  @Input() public officeHours: OfficeBusinessHours[] | undefined = new Array();
  @Input() public currentOffice: any;

  constructor(
    private OrionOfficeBusinessHoursRepo: OrionOfficeBusinessHoursRepository,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    this.sortOfficeHours();
  }

  sortOfficeHours() {
    this.officeHours?.sort(function sortByDay(a, b) {
      let sortedWeekdays: { [index: string]: any } = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7,
      };
      let day1 = a.weekday.toLowerCase();
      let day2 = b.weekday.toLowerCase();
      return sortedWeekdays[day1] - sortedWeekdays[day2];
    });
  }

  getWeekDay(weekday: string): string {
    return WeekDaysEnum[weekday as keyof typeof WeekDaysEnum];
  }

  protected readonly UIStateType = UIStateType;
}
