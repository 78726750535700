import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { AbonentServiceType } from '../../domain/abonent-service';

import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';

@Component({
  selector: 'app-abonent-services-form-full-card',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    ImageCropperComponent,
    DragAndDropFileUploadComponent,
    LMarkdownEditorModule,
    FormComponentComponent,
  ],
  templateUrl: './abonent-services-form-full-card.html',
  styleUrl: './abonent-services-form-full-card.css',
})
export class AbonentServicesFormFullCardComponent implements OnInit {
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  public abonentServiceType = AbonentServiceType;
  public keys = Object.keys;
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  @Input() chosenType: string = 'web_app';
  @Input() imagePreview: string = '';
  @Input() full_description: string = '';

  @Output() outputCroppedImage: EventEmitter<Blob> = new EventEmitter();
  @Output() outputImagePreview: EventEmitter<string> = new EventEmitter();
  @Output() abonentServiceFormData: EventEmitter<FormGroup> = new EventEmitter();
  @Output() fullDescription: EventEmitter<string> = new EventEmitter();

  @Input() abonentServiceForm!: FormGroup;

  constructor(private AttachmentRepo: AttachmentRepository) {
    this.doUpload = this.doUpload.bind(this);
  }

  ngOnInit(): void {}

  getAbonentServiceType(serviceType: string): string {
    return AbonentServiceType[serviceType as keyof typeof AbonentServiceType];
  }

  imageFileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
      this.outputImagePreview.emit(this.imagePreview);
    };

    if (this.croppedImage) {
      reader.readAsDataURL(this.croppedImage);
      this.outputCroppedImage.emit(this.croppedImage);
    }
  }

  checkChosenType($event: any): void {
    this.chosenType = $event.target.value;
  }

  async doUpload(files: Array<File>) {
    this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });
    await this.AttachmentRepo.create(files[0]).then((data) => {
      this.attachment = Loadable.getFromDataStatus(data);
    });

    return Promise.resolve([
      {
        name: this.attachment.data[0].name,
        url: this.attachment.data[0].content.attributes.content_path,
        isImg: true,
      },
    ]);
  }

  removeImage(): void {
    this.imageChangedEvent = null;
  }

  changeFullDescription(): void {
    this.fullDescription.emit(this.abonentServiceForm.value['full_description'] as string);
  }

  protected readonly UIStateType = UIStateType;
}
