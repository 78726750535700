<div
  *ngFor="let entity of foundEntities; let i = index"
  class="array-result-item"
  [ngClass]="{ active: i === selectedIndex, '': i !== selectedIndex }"
  (mouseover)="selectedIndex = i"
  (click)="handleClick(entity)"
>
  <label
    ><div class="item-id">ID:{{ entity.serviceId }}</div>
    <div class="item-name">{{ entity.name }}</div></label
  >
</div>
