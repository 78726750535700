<div class="container-fluid" appDnd (fileDropped)="onFileDropped($event)">
  <input
    class="form-control"
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="fileBrowseHandler($event)"
    [accept]="acceptFormats"
    aria-describedby="validationImageFeedback"
  />
  <span class="dnd-text">Выберите фото или просто перетащите его в это окно</span>
  <label for="fileDropRef">Выбрать файл</label>
  <div id="validationImageFeedback" class="invalid-feedback">
    Размер файла не может превышать 15 Мб.
  </div>
</div>
<div *ngIf="file" class="files-list">
  <div class="single-file">
    <div class="info">
      <h4 class="name"><i class="ti ti-file"></i> {{ file.name }}</h4>
    </div>
    <div class="delete">
      <button class="btn btn-danger remove-icon-btn" (click)="removeFile()">
        <i class="ti ti-trash"></i>
      </button>
    </div>
  </div>
</div>
