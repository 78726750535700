import {
  BillingTariff,
  MacroService,
  MacroServiceBase,
  TariffInfo,
} from '../../domain/billing-tariff';
import {
  BillingTariffApiDto,
  MacroServiceApiDto,
  MacroServiceBaseApiDto,
  TariffInfoApiDto,
} from '../billing-tariff-api-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MacroServiceBaseMapper {
  public toDomain(dto: MacroServiceBaseApiDto): MacroServiceBase {
    return new MacroServiceBase(dto.id, dto.type, dto.group);
  }
}

@Injectable({ providedIn: 'root' })
export class MacroServiceMapper {
  constructor(private macroServiceBaseMapper: MacroServiceBaseMapper) {}

  public toDomain(dto: MacroServiceApiDto): MacroService {
    return new MacroService(this.macroServiceBaseMapper.toDomain(dto.macro_service_info));
  }
}

@Injectable({ providedIn: 'root' })
export class TariffInfoMapper {
  public toDomain(dto: TariffInfoApiDto): TariffInfo {
    return new TariffInfo(
      dto.id,
      dto.tariff_id,
      dto.name,
      dto.description,
      dto.cost,
      dto.speed_day_in,
      dto.speed_night_in,
    );
  }
}

@Injectable({ providedIn: 'root' })
export class BillingTariffMapper {
  constructor(
    private tariffInfoMapper: TariffInfoMapper,
    private macroServiceMapper: MacroServiceMapper,
  ) {}

  public toDomain(dto: BillingTariffApiDto): BillingTariff {
    return new BillingTariff(
      this.tariffInfoMapper.toDomain(dto.tariff_info),
      dto.macro_services.map((data) => this.macroServiceMapper.toDomain(data)),
    );
  }
}
