import { Story } from '../../domain/story';
import { StoryApiDto } from '../stories-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StoryMapper {
  constructor() {}

  public toDomain(dto: StoryApiDto): Story {
    return new Story(
      dto.id,
      dto.name,
      dto.created_at,
      dto.updated_at,
      dto.created_by,
      dto.updated_by,
      dto.preview,
      dto.analytics_target_name,
      dto.story_views,
      dto.story_likes,
      dto.annotation,
      dto.slides,
      dto.is_active,
      dto.deactivated_at,
      dto.admin_creator,
      dto.admin_editor,
    );
  }
}
