import { HttpClientWrapper } from '../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { DeeplinkMapper } from '../mappers/deeplink-mapper';
import {
  DataStatus,
  DataStatusError,
  DataStatusSuccess,
} from '../../../../core/network/data.status';
import { DeeplinkApiDto } from '../deeplink-api-dto';
import { APP_CONFIG } from '../../../../environments/environment';
import { IEnvironment } from '../../../../environments/ienvironment';
import { Deeplink } from '../../domain/entities/deeplink';

@Injectable({ providedIn: 'root' })
export class DeeplinkRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private deeplinkMapper: DeeplinkMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(body: any): Promise<DataStatus<Deeplink>> {
    let response: DataStatus<DeeplinkApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks`,
      body,
    );

    let successResponse = response as DataStatusSuccess<DeeplinkApiDto>;
    return successResponse.map((data) => {
      return this.deeplinkMapper.toDomain(successResponse.data);
    });
  }

  async update(body: any, deeplink_id: number): Promise<DataStatus<Deeplink>> {
    let response: DataStatus<DeeplinkApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/deeplinks/${deeplink_id}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<DeeplinkApiDto>;
    return successResponse.map((data) => {
      return this.deeplinkMapper.toDomain(successResponse.data);
    });
  }
}
