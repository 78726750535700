import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BillingTariff } from '../../../../../../entity/billing-tariff/domain/billing-tariff';

@Component({
  selector: 'app-autocomplete-search-service-def-input-result',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './autocomplete-search-input-result.component.html',
  styleUrl: './autocomplete-search-input-result.component.css',
})
export class AutocompleteSearchBillingTariffInputResultComponent {
  @Input() public foundEntities: BillingTariff[] = [];
  @Input() public selectedIndex: number = 0;
  @Output() selectValue = new EventEmitter();

  public handleClick(entity: any) {
    console.log(entity);
    this.selectValue.emit(entity);
  }

  @HostListener('window:keyup', ['$event']) private keyboardEvents(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        if (this.selectedIndex === 0) {
          this.selectedIndex = this.foundEntities.length;
        } else if (this.selectedIndex <= this.foundEntities.length) {
          this.selectedIndex -= 1;
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex === this.foundEntities.length) {
          this.selectedIndex = 0;
        } else if (this.selectedIndex <= this.foundEntities.length) {
          this.selectedIndex += 1;
        }
        break;
      case 'Enter':
        if (this.foundEntities?.length !== 1) {
          this.handleClick(this.foundEntities[this.selectedIndex]);
        }
        break;
      default:
        break;
    }
  }
}
