<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить сервис'"
    [routerLink]="['create-abonent-service']"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper [loadable]="abonentServices" loadingTitle="Загрузка данных...">
    <ng-template>
      <div class="row row-cards">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Порядок отображения у пользователя</h3>
              <div class="ms-auto text-secondary">
                <div class="btn-list flex-nowrap">
                  <button
                    *ngIf="isEditButtonsActive == false"
                    class="btn btn-link"
                    (click)="activateEditButtons()"
                  >
                    Редактировать
                  </button>
                  <button
                    *ngIf="isEditButtonsActive == true"
                    class="btn btn-primary"
                    (click)="activateEditButtons(); saveOrdering()"
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div
                *ngIf="isEditButtonsActive == true"
                cdkDropList
                class="list-group list-group-flush overflow-scroll"
                (cdkDropListDropped)="drop($event)"
              >
                <div
                  *ngFor="let item of abonentServices.data"
                  [cdkDragData]="item"
                  class="list-group-item"
                  cdkDrag
                >
                  <div class="row">
                    <div class="col-auto">
                      <span class="service-image" [style]="item.getBackgroundImage()"></span>
                    </div>
                    <div class="col text-truncate">
                      <div class="text-body d-block">{{ item.title }}</div>
                      <div class="text-secondary text-truncate mt-n1">
                        {{ item.short_description }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="btn-list flex-nowrap">
                        <button href="#" class="btn btn-link" (click)="onDelete(item.id)">
                          <i class="ti ti-trash" style="color: red"></i>
                        </button>
                        <button class="btn btn-link">
                          <i class="ti ti-selector"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="isEditButtonsActive == false"
                class="list-group list-group-flush overflow-scroll"
              >
                <div *ngFor="let item of abonentServices.data" class="list-group-item">
                  <div class="row">
                    <div class="col-auto">
                      <span class="service-image" [style]="item.getBackgroundImage()"></span>
                    </div>
                    <div class="col text-truncate">
                      <div class="text-body d-block">{{ item.title }}</div>
                      <div class="text-secondary text-truncate mt-n1">
                        {{ item.short_description }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="btn-list flex-nowrap">
                        <a
                          [routerLink]="['update-abonent-service']"
                          [state]="item"
                          class="btn btn-link"
                        >
                          <i class="ti ti-edit"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Предпросмотр списка в приложении</h3>
            </div>
            <div class="card-body">
              <app-mobile-abonent-services-list
                [title]="'Сервисы'"
                [iterable]="abonentServices.data"
              ></app-mobile-abonent-services-list>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-loadable-wrapper>
</app-page-wrapper>
