import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { StoryMapper } from '../mappers/story';
import { APP_CONFIG } from '../../../../../environments/environment';
import { StoryApiDto } from '../stories-dto';
import { Story } from '../../domain/story';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';

@Injectable({ providedIn: 'root' })
export class StoryRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private storyMapper: StoryMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<StoryApiDto, Story> =
    new PaginationResponseMapper(this.storyMapper);

  async findAllPost(
    per_page: number,
    page_number: number,
    is_active: boolean,
    search_name: string | null = null,
  ): Promise<DataStatus<PaginationResponse<Story>>> {
    var payload: { [id: string]: any } = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [
        {
          'column': 'is_active',
          'operator': 'eq',
          'value': is_active,
        },
        {
          'column': 'deleted_at',
          'operator': 'eq',
          'value': null,
        },
      ],
      'included': [
        {
          'entity_type': 'admin_creator',
        },
        {
          'entity_type': 'admin_editor',
        },
      ],
    };

    if (search_name) {
      payload['filters'].push({
        'column': 'name',
        'operator': 'ilike',
        'value': `${search_name}%`,
      });
    }

    let response: DataStatus<PaginationResponseApiDto<Story>> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories`,
      payload,
    );

    let successResponse = response as DataStatusSuccess<PaginationResponseApiDto<StoryApiDto>>;
    return successResponse.map((data) => {
      return this.paginationResponseMapper.toDomain(successResponse.data);
    });
  }

  async create(body: any): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories`,
      body,
    );

    let successResponse = response as DataStatusSuccess<StoryApiDto>;
    return successResponse.map((data) => {
      return this.storyMapper.toDomain(successResponse.data);
    });
  }

  async update(body: any, storyId: number): Promise<DataStatus<Story>> {
    let response: DataStatus<StoryApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${storyId}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<StoryApiDto>;
    return successResponse.map((data) => {
      return this.storyMapper.toDomain(successResponse.data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/stories/${id}`,
    );
  }
}
