import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Loadable, UIStateType } from '../../../core/utils/wrappers/loadable';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { PageHeaderComponent } from '../common/page-header/page-header.component';
import { PageWrapperComponent } from '../common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../common/breadcrumbs/breadcrumbs.component';
import { PaginationResponse } from '../../../entity/pagination-response/domain/pagination-response';

@Component({
  selector: 'app-pagination-view',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
  ],
  templateUrl: './pagination-view.component.html',
  styleUrl: './pagination-view.component.css',
})
export class PaginationViewComponent<T> {
  @ContentChild(TemplateRef) public dataTemplate: TemplateRef<any> | null = null;
  @Input() public data: Loadable<PaginationResponse<T>> = new Loadable<PaginationResponse<T>>({
    'kind': 'NotRequested',
  });
  @Input() public perPage: number = 10;
  @Input() public pageNumber: number = 1;
  @Input() public currentPageNumber: number = 1;

  @Output() changePageEvent: EventEmitter<number> = new EventEmitter();

  createPageArray(pages: number): Array<number> {
    var pages_array = new Array(pages).fill(null).map((_, i) => i + 1);
    return pages_array;
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.currentPageNumber = pageNumber;
    this.changePageEvent.emit(pageNumber);
  }

  protected readonly UIStateType = UIStateType;
}
