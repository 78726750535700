import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { FormComponentComponent } from '../../../../components/form-component/form-component.component';

@Component({
  selector: 'app-abonent-services-form-short-card',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    ImageCropperComponent,
    DragAndDropFileUploadComponent,
    FormComponentComponent,
  ],
  templateUrl: './abonent-services-form-short-card.html',
  styleUrl: './abonent-services-form-short-card.css',
})
export class AbonentServicesFormShortCardComponent implements OnInit {
  iconChangedEvent: Event | null = null;
  croppedIcon?: Blob | null;

  @Output() outputCroppedIcon: EventEmitter<Blob> = new EventEmitter();
  @Output() newTitle: EventEmitter<string> = new EventEmitter();

  @Input() abonentServiceForm!: FormGroup;
  @Input() title: string = '';

  constructor() {}

  ngOnInit(): void {}

  iconFileChangeEvent($event: any): void {
    this.iconChangedEvent = $event;
  }

  iconCropped(event: ImageCroppedEvent): void {
    this.croppedIcon = event.blob;
    if (this.croppedIcon) {
      this.outputCroppedIcon.emit(this.croppedIcon);
    }
  }

  removeIcon(): void {
    this.iconChangedEvent = null;
  }

  changeTitle($event: any): void {
    this.newTitle.emit($event.target.value);
  }

  protected readonly UIStateType = UIStateType;
}
