<ng-template [ngIf]="loadable.status == UIStateType.Loading">
  <ng-container
    [ngTemplateOutlet]="loadingTemplate || defaultLoadingTemplate"
    [ngTemplateOutletContext]="{ loadingTitle: loadingTitle }"
  ></ng-container>
</ng-template>

<ng-template [ngIf]="loadable.status == UIStateType.Error">
  <ng-container
    [ngTemplateOutlet]="errorTemplate || defaultErrorTemplate"
    [ngTemplateOutletContext]="{
      message: loadable.message,
      repeatAction: errorRepeatAction,
    }"
  ></ng-container>
</ng-template>

<ng-template [ngIf]="loadable.status == UIStateType.Success && !checkIfDataExists()">
  <ng-container
    [ngTemplateOutlet]="emptyDataTemplate || defaultEmptyDataTemplate"
    [ngTemplateOutletContext]="{ emptyDataTitle: emptyDataTitle }"
  ></ng-container>
</ng-template>

<ng-template [ngIf]="loadable.status == UIStateType.Success && checkIfDataExists()">
  <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
</ng-template>

<ng-template #defaultErrorTemplate let-message="message" let-repeatAction="repeatAction">
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="empty">
        <p class="empty-title">Ошибка</p>
        <p class="empty-subtitle text-secondary">
          {{ message }}
        </p>
        <div class="empty-action" *ngIf="repeatAction != null">
          <button class="btn btn-primary" (click)="repeatAction()">Обновить</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultLoadingTemplate let-loadingTitle="loadingTitle">
  <div class="d-flex flex-column">
    <div class="page page-center">
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="text-secondary mb-3">{{ loadingTitle ?? 'Загрузка данных...' }}</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultEmptyDataTemplate let-emptyDataTitle="emptyDataTitle">
  <div class="page page-center">
    <div class="container-tight py-4">
      <div class="empty">
        <p class="empty-title">{{ emptyDataTitle ?? 'Нет данных' }}</p>
      </div>
    </div>
  </div>
</ng-template>
