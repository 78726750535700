import { Injectable } from '@angular/core';
import { DeeplinkApiDto } from '../deeplink-api-dto';
import { Deeplink } from '../../domain/entities/deeplink';

@Injectable({ providedIn: 'root' })
export class DeeplinkMapper {
  public toDomain(dto: DeeplinkApiDto): Deeplink {
    return new Deeplink(dto.id, dto.link, dto.title, dto.description);
  }
}
