import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { AbonentServiceRepository } from '../../data/repositories/AbonentServiceRepository';
import { AbonentService } from '../../domain/abonent-service';

import { AbonentServiceType } from '../../domain/abonent-service';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';
import { AbonentServicesFormComponent } from '../../components/abonent-services-form/abonent-services-form';

@Component({
  selector: 'app-abonent-services-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    AbonentServicesFormComponent,
  ],
  templateUrl: './abonent-services-update.component.html',
  styleUrl: './abonent-services-update.component.css',
})
export class AbonentServicesUpdateComponent implements OnInit {
  public abonentServices: Loadable<AbonentService[]> = new Loadable<AbonentService[]>();
  pageTitle: string = 'Редактирование сервиса';
  pageSubtitle: string = 'Сервисы';
  private icon: Loadable<Attachment[]> | null = null;
  private image: Loadable<Attachment[]> | null = null;
  public abonentServiceType = AbonentServiceType;
  croppedImage?: Blob | null;
  croppedIcon?: Blob | null;
  chosenType: string = '';
  public title: string = '';
  public imagePreview: string = '';
  public full_description: string[] = [];
  currentAbonentService: any;
  updateAbonentServiceForm!: FormGroup;

  constructor(
    private AbonentsServiceRepo: AbonentServiceRepository,
    private AttachmentRepo: AttachmentRepository,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.updateAbonentServiceForm = this.formBuilder.group({
      type: undefined,
      title: undefined,
      short_description: undefined,
      full_description: undefined,
      service_link_web: undefined,
      service_link_android: undefined,
      service_link_ios: undefined,
      icon: undefined,
      image: undefined,
    });

    this.currentAbonentService = history.state;
    if (this.currentAbonentService.image) {
      this.imagePreview = this.currentAbonentService.image.content.attributes.content_path;
    }

    this.title = this.currentAbonentService.title;
    this.chosenType = this.currentAbonentService.type;
    delete this.currentAbonentService.image;
    delete this.currentAbonentService.icon;
    this.updateAbonentServiceForm.patchValue(this.currentAbonentService);
  }

  async onUpdateSubmit(): Promise<void> {
    for (var i in this.updateAbonentServiceForm.controls) {
      if (this.updateAbonentServiceForm.controls[i].value == undefined) {
        delete this.updateAbonentServiceForm.value[i];
      }
    }

    if (this.updateAbonentServiceForm.value['icon']) {
      this.icon = new Loadable<Attachment[]>({ kind: 'Loading' });
      await this.AttachmentRepo.create(this.croppedIcon as Blob).then((data) => {
        this.icon = Loadable.getFromDataStatus(data);
      });
      delete this.updateAbonentServiceForm.value['icon'];
    }

    if (this.updateAbonentServiceForm.value['image']) {
      this.image = new Loadable<Attachment[]>({ kind: 'Loading' });
      await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
        this.image = Loadable.getFromDataStatus(data);
      });
      delete this.updateAbonentServiceForm.value['image'];
    }

    await this.AbonentsServiceRepo.update(
      this.updateAbonentServiceForm.value,
      this.currentAbonentService.id,
      this.icon?.data[0].id,
      this.image?.data[0].id,
    );

    this.router.navigate(['/abonent-services']);
  }

  onOutputCroppedImage($event: Blob) {
    this.croppedImage = $event;
  }

  onOutputCroppedIcon($event: Blob) {
    this.croppedIcon = $event;
  }

  onAbonentServiceFormData($event: FormGroup) {
    this.updateAbonentServiceForm = $event;
    this.onUpdateSubmit().finally();
  }

  protected readonly UIStateType = UIStateType;
}
