import { ActionButton } from '../../domain/story';
import { ActionButtonApiDto } from '../stories-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ActionButtonMapper {
  constructor() {}

  public toDomain(dto: ActionButtonApiDto): ActionButton {
    return new ActionButton(dto.id, dto.body, dto.deeplink_id, dto.deeplink);
  }
}
