<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <form
    id="update-banner-form"
    class="card"
    [formGroup]="updateOfficeHoursForm"
    (ngSubmit)="onUpdateSubmit().finally()"
  >
    <div class="card-body">
      <div class="form-floating mb-3">
        <input
          id="update-open_time"
          class="form-control"
          type="time"
          placeholder="Время открытия"
          formControlName="open_time"
          (change)="checkIfOfficeChanged($event)"
        />
        <label for="floatingInput">Время открытия</label>
      </div>
      <div class="form-floating mb-3">
        <input
          id="update-close_time"
          class="form-control"
          type="time"
          placeholder="Время закрытия"
          formControlName="close_time"
          (change)="checkIfOfficeChanged($event)"
        />
        <label for="floatingInput">Время закрытия</label>
      </div>
      <div class="form-check">
        <input
          type="checkbox"
          id="update-is_day_off"
          class="form-check-input"
          placeholder="Выходной"
          formControlName="is_day_off"
          (change)="checkIfOfficeChanged($event)"
        />
        <label class="form-check-label" for="update-is_day_off">Выходной</label>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button
          type="button"
          class="btn me-auto"
          (click)="router.navigate(['/orion-offices/detail'], { state: currentOffice })"
        >
          Назад
        </button>
        <button
          id="submit-update-form-button"
          type="submit"
          class="btn btn-primary"
          [disabled]="isSubmitDisabled"
        >
          Обновить
        </button>
      </div>
    </div>
  </form>
</app-page-wrapper>
