export class Attributes {
  constructor(
    public content_path: string,
    public content_path_thumbnail: string,
  ) {}
}

export class Content {
  constructor(
    public attributes: Attributes,
    public type: string,
  ) {}
}

export class Attachment {
  constructor(
    public id: number,
    public name: string,
    public mediaType: string,
    public format: string,
    public content: Content,
  ) {}

  public getBackgroundImage(): string {
    return 'background-image: url(' + this.content.attributes.content_path + ')';
  }
}
