import { PaginationResponse } from '../../domain/pagination-response';
import { PaginationResponseApiDto } from '../pagination-response-api-dto';

export class PaginationResponseMapper<dto, class_> {
  private dataMapper: any;

  constructor(dataMapper_: any) {
    this.dataMapper = dataMapper_;
  }

  public toDomain(dto: PaginationResponseApiDto<dto>): PaginationResponse<class_> {
    var data = [];
    for (let item of dto.data) {
      data.push(this.dataMapper.toDomain(item));
    }

    return new PaginationResponse(dto.pages, dto.count, dto.per_page, data);
  }
}
