import { Injectable } from '@angular/core';
import { OrionOfficeApiDto } from '../orion-office-dto';
import { OrionOffice } from '../../domain/orion-office';
import { OfficeBusinessHoursMapper } from '../../../orion-offices-business-hours/data/mappers/orion-office-business-hours';
import { OfficeContactMapper } from '../../../orion-offices-contacts/data/mappers/orion-office-contact';

@Injectable({ providedIn: 'root' })
export class OrionOfficeMapper {
  constructor(
    private officeBusinessHoursMapper: OfficeBusinessHoursMapper,
    private officeContactMapper: OfficeContactMapper,
  ) {}

  public toDomain(dto: OrionOfficeApiDto): OrionOffice {
    return new OrionOffice(
      dto.id,
      dto.address,
      dto.latitude,
      dto.longitude,
      dto.city,
      dto.business_days !== undefined
        ? dto.business_days.map((day) => {
            return this.officeBusinessHoursMapper.toDomain(day);
          })
        : undefined,
      dto.contacts !== undefined
        ? dto.contacts.map((contact) => {
            return this.officeContactMapper.toDomain(contact);
          })
        : undefined,
    );
  }
}
