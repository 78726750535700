<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-service-extension-form
    [form]="form"
    (onFormSubmit)="onFormSubmit($event)"
    (onBannerCropped)="onBannerCropped($event)"
    (onCoverCropped)="onCoverCropped($event)"
    (onIconCropped)="onIconCropped($event)"
  >
  </app-service-extension-form>
</app-page-wrapper>
