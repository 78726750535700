import { Component } from '@angular/core';
import { NavItemComponent } from '../components/common/nav-item/nav-item.component';
import { DropdownItemComponent } from '../components/common/dropdown-item/dropdown-item.component';
import { DropdownMenuComponent } from '../components/common/dropdown-menu/dropdown-menu.component';
import { FooterComponent } from '../components/common/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { PageWrapperComponent } from '../components/common/page-wrapper/page-wrapper.component';
import { PageHeaderComponent } from '../components/common/page-header/page-header.component';
import { HomePageComponent } from './home/home-page.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    NavItemComponent,
    DropdownItemComponent,
    DropdownMenuComponent,
    FooterComponent,
    RouterOutlet,
    PageWrapperComponent,
    PageHeaderComponent,
    HomePageComponent,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css',
})
export class MainComponent {}
