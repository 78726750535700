import { Attachment } from '../../domain/attachment';
import { AttachmentApiDto } from '../attachment-api-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AttachmentMapper {
  public toDomain(value: AttachmentApiDto): Attachment {
    return new Attachment(value.id, value.name, value.media_type, value.format, value.content);
  }
}
