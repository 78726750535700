import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityType, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-create.component.html',
  styleUrl: './orion-offices-create.component.css',
})
export class OrionOfficesCreateComponent implements OnInit {
  pageTitle: string = 'Создать офис';
  pageSubtitle: string = 'Офисы';
  public officeCityType = OfficeCityType;
  public keys = Object.keys;
  public currentOffice: any = undefined;
  public isSubmitDisabled: boolean = true;
  public orionOffice: Loadable<OrionOffice> = new Loadable<OrionOffice>({ kind: 'NotRequested' });

  public createOfficeForm = this.formBuilder.record({
    address: undefined,
    longitude: undefined,
    latitude: undefined,
    city: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficeRepo: OrionOfficeRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {}

  async onUpdateSubmit(): Promise<void> {
    this.orionOffice = new Loadable<OrionOffice>({ kind: 'Loading' });
    await this.OrionOfficeRepo.create(this.createOfficeForm.value).then((data) => {
      this.orionOffice = Loadable.getFromDataStatus(data);
    });

    this.router.navigate(['/orion-offices/detail'], { state: this.orionOffice.data });
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  protected readonly UIStateType = UIStateType;
}
