import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityCoordinatesType, OfficeCityType, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AngularYandexMapsModule, YaEvent } from 'angular8-yandex-maps';
import { OrionOfficeBusinessHoursRepository } from '../../../orion-offices-business-hours/data/repositories/OrionOfficeBusinessHoursRepository';
import { WeekDaysEnum } from '../../../orion-offices-business-hours/domain/orion-office-business-hours';
import { OrionOfficesContactsRepository } from '../../../orion-offices-contacts/data/repositories/OrionOfficesContactsRepository';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { MobileOrionOfficePreviewComponent } from '../mobile-orion-office-preview/mobile-orion-office-preview.component';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-orion-offices-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
    AngularYandexMapsModule,
    NgxMaskDirective,
    MobileOrionOfficePreviewComponent,
    FormComponentComponent,
  ],
  templateUrl: './orion-offices-update.component.html',
  styleUrl: './orion-offices-update.component.css',
})
export class OrionOfficesUpdateComponent implements OnInit {
  pageTitle: string = 'Редактирование офиса';
  pageSubtitle: string = 'Офисы';
  public officeCityType = OfficeCityType;
  public keys = Object.keys;
  public currentOffice!: OrionOffice;
  public isSubmitDisabled: boolean = true;
  public orionOffice: Loadable<OrionOffice> = new Loadable<OrionOffice>({ kind: 'NotRequested' });
  public cityCoords: number[] = [0, 0];
  public placemarkCoords: number[] = [0, 0];
  weekDayEnum = WeekDaysEnum;
  public contacts: FormArray<any> = new FormArray(new Array<any>());

  parameters: ymaps.control.ISearchControlParameters = {
    options: {
      provider: 'yandex#search',
      noSuggestPanel: false,
      noPlacemark: true,
    },
  };

  mapState: ymaps.IMapState = {
    type: 'yandex#map',
    controls: [],
    zoom: 5,
  };

  public createOfficeForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficeRepo: OrionOfficeRepository,
    private OfficeContactsRepo: OrionOfficesContactsRepository,
    private OfficeBusinessHoursRepo: OrionOfficeBusinessHoursRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentOffice = history.state;

    this.sortOfficeHours();
    let weekDaysFormArray = [];
    for (let i: number = 0; i < Object.values(this.weekDayEnum).length; i++) {
      let weekDayFormElement = this.formBuilder.group({
        open_time: new FormControl(
          this.currentOffice.businessDays ? this.currentOffice.businessDays[i].openTime : null,
        ),
        close_time: new FormControl(
          this.currentOffice.businessDays ? this.currentOffice.businessDays[i].closeTime : null,
        ),
        is_day_off: new FormControl(
          this.currentOffice.businessDays ? this.currentOffice.businessDays[i].isDayOff : false,
        ),
      });

      if (weekDayFormElement.get('is_day_off')?.value) {
        weekDayFormElement.get('open_time')?.disable();
        weekDayFormElement.get('close_time')?.disable();
      }

      weekDaysFormArray.push(weekDayFormElement);
    }

    let contactsArray = [];
    if (this.currentOffice.contacts) {
      for (let contact of this.currentOffice.contacts) {
        contactsArray.push(this.formBuilder.group({ phone: new FormControl(contact.phone) }));
      }
    }

    this.createOfficeForm = this.formBuilder.group({
      address: new FormControl(undefined),
      longitude: new FormControl(undefined),
      latitude: new FormControl(undefined),
      city: new FormControl(undefined),
      title: new FormControl(undefined),
      businessHours: new FormArray(weekDaysFormArray),
      contacts: new FormArray(contactsArray),
    });

    this.placemarkCoords = [this.currentOffice.latitude, this.currentOffice.longitude];

    this.createOfficeForm.patchValue(this.currentOffice);
    this.contacts = this.OfficeContactsData;
  }

  sortOfficeHours() {
    this.currentOffice.businessDays?.sort(function sortByDay(a, b) {
      let sortedWeekdays: { [index: string]: any } = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7,
      };
      let day1 = a.weekday.toLowerCase();
      let day2 = b.weekday.toLowerCase();
      return sortedWeekdays[day1] - sortedWeekdays[day2];
    });
  }

  get OfficeContactsData() {
    return <FormArray>this.createOfficeForm.get('contacts');
  }

  get BusinessHoursData() {
    return <FormArray>this.createOfficeForm.get('businessHours');
  }

  async onUpdateSubmit(): Promise<void> {
    this.orionOffice = new Loadable<OrionOffice>({ kind: 'Loading' });
    const officePayload = {
      city: this.createOfficeForm.value['city'],
      title: this.createOfficeForm.value['title'],
      address: this.createOfficeForm.value['address'],
      latitude: this.createOfficeForm.value['latitude'],
      longitude: this.createOfficeForm.value['longitude'],
    };
    await this.OrionOfficeRepo.update(officePayload, this.currentOffice.id).then((data) => {
      this.orionOffice = Loadable.getFromDataStatus(data);
    });

    for (let contact of this.currentOffice.contacts!) {
      await this.OfficeContactsRepo.delete(contact.id);
    }

    for (let i = 0; i < this.createOfficeForm.value['contacts'].length; i++) {
      const contactPayload = {
        office_id: this.orionOffice.data.id,
        phone: this.createOfficeForm.value['contacts'][i]['phone'],
      };
      await this.OfficeContactsRepo.create(contactPayload);
    }

    for (let index = 0; index < this.createOfficeForm.value['businessHours'].length; index++) {
      const businessHoursPayload = {
        office_id: this.orionOffice.data.id,
        weekday: Object.keys(WeekDaysEnum)[index],
        open_time: this.createOfficeForm.value['businessHours'][index]['open_time']
          ? this.createOfficeForm.value['businessHours'][index]['open_time']
          : null,
        close_time: this.createOfficeForm.value['businessHours'][index]['close_time']
          ? this.createOfficeForm.value['businessHours'][index]['close_time']
          : null,
        is_day_off: this.createOfficeForm.value['businessHours'][index]['is_day_off'],
      };

      let businessHoursId = this.currentOffice.businessDays
        ? this.currentOffice.businessDays[index].id
        : null;

      await this.OfficeBusinessHoursRepo.update(businessHoursPayload, businessHoursId);
    }

    this.router.navigate(['/orion-offices'], { state: this.orionOffice.data });
  }

  getOfficeCityCoordinates($event: any): void {
    var officeCity = $event.target.value;
    this.placemarkCoords =
      OfficeCityCoordinatesType[officeCity as keyof typeof OfficeCityCoordinatesType];
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  getWeekDayEnum(index: number): string {
    return Object.values(WeekDaysEnum)[index];
  }

  onMapClick($event: YaEvent<ymaps.Map>): void {
    const { target, event } = $event;

    const coords = event.get('coords');
    this.placemarkCoords = coords;
    this.createOfficeForm.patchValue({
      latitude: coords[0].toFixed(6),
      longitude: coords[1].toFixed(6),
    });
  }

  changeDayOff(i: number) {
    let weekDayFormElement = this.BusinessHoursData.controls[i];
    if (weekDayFormElement.get('is_day_off')?.value) {
      weekDayFormElement.get('open_time')?.disable();
      weekDayFormElement.get('close_time')?.disable();
    } else {
      weekDayFormElement.get('open_time')?.enable();
      weekDayFormElement.get('close_time')?.enable();
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      phone: undefined,
    });
  }

  addItem(): void {
    this.contacts = this.OfficeContactsData;
    this.contacts.push(this.createItem());
  }

  removeItem(index: number) {
    this.contacts.removeAt(index);
  }

  protected readonly UIStateType = UIStateType;
}
