<div *ngIf="loading" class="d-flex flex-column">
  <div class="page page-center">
    <div class="container container-slim py-4">
      <div class="text-center">
        <div class="text-secondary mb-3">Загрузка изображения</div>
        <div class="progress progress-sm">
          <div class="progress-bar progress-bar-indeterminate"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<img
  [hidden]="loading"
  class="card-img-top"
  (load)="onLoad()"
  (error)="onError($event)"
  [src]="imgSrc"
/>
