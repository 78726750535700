import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityType, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { LoadableWrapperComponent } from '../../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { AddButtonComponent } from '../../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../../components/common/buttons/btn-list/btn-list.component';
import { PhoneOutputPipe } from '../../../../../components/common/pipes/phone-output-pipe';
import { WeekDayAbbrEnum } from '../../../orion-offices-business-hours/domain/orion-office-business-hours';

@Component({
  selector: 'app-orion-offices',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    AddButtonComponent,
    BtnListComponent,
    PhoneOutputPipe,
  ],
  templateUrl: './orion-offices.component.html',
  styleUrl: './orion-offices.component.css',
})
export class OrionOfficesComponent implements OnInit {
  pageTitle: string = 'Офисы';
  pageSubtitle: string = 'Офисы';
  public orionOffices: Loadable<OrionOffice[]> = new Loadable<OrionOffice[]>({
    kind: 'NotRequested',
  });
  public tableHeaders: Array<string> = ['ID', 'Адрес', 'Широта', 'Долгота', 'Город'];
  public officeCityType = OfficeCityType;
  public weekDaysAbbrEnum = WeekDayAbbrEnum;
  public keys = Object.keys;
  private officeCity: string = 'krasnoyarsk';

  constructor(
    private OrionOfficeRepo: OrionOfficeRepository,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getOrionOffices(this.officeCity).finally();
  }

  async getOrionOffices(city: string | null) {
    if (this.orionOffices.status != UIStateType.Loading && this.officeCity != '') {
      this.orionOffices = new Loadable<OrionOffice[]>({ kind: 'Loading' });
      await this.OrionOfficeRepo.findAllByCity(city).then((data) => {
        this.orionOffices = Loadable.getFromDataStatus(data);
      });
    }
    this.orionOffices.data.map((office) => this.sortOfficeHours(office));
  }

  sortOfficeHours(office: OrionOffice) {
    office.businessDays?.sort(function sortByDay(a, b) {
      let sortedWeekdays: { [index: string]: any } = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7,
      };
      let day1 = a.weekday.toLowerCase();
      let day2 = b.weekday.toLowerCase();
      return sortedWeekdays[day1] - sortedWeekdays[day2];
    });
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  getWeekDayAbbrEnum(weekday: string): string {
    return WeekDayAbbrEnum[weekday as keyof typeof WeekDayAbbrEnum];
  }

  setOfficeCity($event: any) {
    this.officeCity = $event.target.value;
    this.getOrionOffices(this.officeCity).finally();
  }

  async delete(id: number) {
    await this.OrionOfficeRepo.delete(id);
    this.getOrionOffices(this.officeCity).finally();
  }

  protected readonly UIStateType = UIStateType;
}
