import { Injectable } from '@angular/core';
import { SmotrehskaChannels } from '../../domain/smotreshka-channels';
import { SmotrehskaChannelsApiDto } from '../smotreshka-channels-api-dto';

@Injectable({ providedIn: 'root' })
export class SmotreshkaChannelsMapper {
  toDomain(dto: SmotrehskaChannelsApiDto): SmotrehskaChannels {
    return new SmotrehskaChannels(dto.id, dto.name, dto.image);
  }
}
