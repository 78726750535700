<app-mobile-preview>
  <div class="service-container">
    <div class="title">{{ title }}</div>
    <div class="row-cards">
      <div class="card" *ngFor="let item of iterable">
        <div class="row">
          <div class="col card-content">
            <div class="text-body">{{ item.title }}</div>
            <div class="text-secondary">{{ item.short_description }}</div>
          </div>
          <div class="col">
            <span class="service-image" [style]="item.getBackgroundImage()"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>
