<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button
    [title]="'Добавить расширение'"
    [routerLink]="['/service-ext-list/add']"
  ></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="row mb-3">
    <div class="col-12">
      <div class="input-icon">
        <input
          type="text"
          value=""
          class="form-control"
          placeholder="Поиск услуг..."
          (input)="searchServiceExt($event).finally()"
        />
        <span class="input-icon-addon">
          <i class="ti ti-search"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="subheader mb-2">Тип услуги</div>
      <div class="list-group list-group-transparent">
        <button
          class="list-group-item list-group-item-action d-flex align-items-center"
          [class.active]="activeType === 'all'"
          (click)="showServiceExtByType()"
        >
          Все услуги
          <small class="text-secondary ms-auto">{{ countDict['all'] }}</small>
        </button>
        <button
          *ngFor="let enum of keys(serviceExtType)"
          class="list-group-item list-group-item-action d-flex align-items-center"
          [class.active]="activeType === enum"
          (click)="showServiceExtByType(enum)"
        >
          {{ getServiceExtType(enum) }}
          <small class="text-secondary ms-auto">{{ countDict[enum] }}</small>
        </button>
      </div>
    </div>

    <div class="col-9">
      <app-loadable-wrapper [loadable]="pageServiceExtensions" loadingTitle="Загрузка данных">
        <ng-template>
          <app-pagination-view
            [data]="pageServiceExtensions"
            [perPage]="perPage"
            [pageNumber]="pageNumber"
            [currentPageNumber]="currentPage"
            (changePageEvent)="changePage($event)"
          >
            <table class="table table-vcenter card-table">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-center">Тип услуги</th>
                  <th class="text-center">Название</th>
                  <th class="text-center">Описание</th>
                  <th class="w-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pageServiceExtensions.data.data">
                  <td class="text-center" style="color: #6c7a91">{{ item.serviceId }}</td>
                  <td class="text-center">{{ getServiceExtType(item.service_type) }}</td>
                  <td class="text-center">{{ item.title }}</td>
                  <td class="block">{{ item.description }}</td>
                  <td>
                    <div class="dropdown">
                      <button
                        class="btn btn-link"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="ti ti-dots-vertical"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-demo"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" [routerLink]="['update']" [state]="item"
                          >Редактировать</a
                        >
                        <button
                          type="button"
                          class="dropdown-item"
                          (click)="deleteServiceExt(item.id).finally()"
                        >
                          Удалить
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </app-pagination-view>
        </ng-template>
      </app-loadable-wrapper>
    </div>
  </div>
</app-page-wrapper>
