import { Component } from '@angular/core';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OrionOfficesContactsRepository } from '../../data/repositories/OrionOfficesContactsRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-business-hours-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-contacts-create.component.html',
  styleUrl: './orion-offices-contacts-create.component.css',
})
export class OrionOfficesContactsCreateComponent {
  pageTitle: string = 'Создать контакты офиса';
  pageSubtitle: string = 'Офисы';
  public currentOffice: any = undefined;

  public createOfficeContactForm = this.formBuilder.record({
    office_id: undefined,
    phone: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficesContactsRepo: OrionOfficesContactsRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentOffice = history.state['office'];
    this.createOfficeContactForm.patchValue({
      'office_id': this.currentOffice.id,
    });
  }

  async onCreateSubmit(): Promise<void> {
    await this.OrionOfficesContactsRepo.create(this.createOfficeContactForm.value);

    this.router.navigate(['/orion-offices/detail'], { state: this.currentOffice });
  }

  protected readonly UIStateType = UIStateType;
}
