import { FormGroup } from '@angular/forms';
import { IServiceExtAttributesFactory } from './iservice-ext-attributes-factory';
import { ISmotreshkaPacketAttributes } from '../../../../../data/dto/ismotreshka-packet-attributes';

export class SmotreshkaTVPacketAttributesFactory implements IServiceExtAttributesFactory {
  createAttributes(form: FormGroup): ISmotreshkaPacketAttributes {
    return {
      pack_id: form.value['packId'],
      number_of_channels: form.value['numberOfChannels'],
    };
  }
}
