import { ConnectionRequest } from '../../domain/connection-request';
import { ConnectionRequestApiDto } from '../connection-request-api-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConnectionRequestMapper {
  constructor() {}

  public toDomain(dto: ConnectionRequestApiDto): ConnectionRequest {
    return new ConnectionRequest(
      dto.id,
      dto.building_id,
      dto.flat,
      dto.user_id,
      dto.requested_type,
      dto.phone_number,
      dto.wanted_time,
      dto.tariff_id,
      dto.event_id,
      dto.connection_type,
      dto.city,
      dto.daily_link,
    );
  }
}
