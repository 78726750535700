import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';

import { BannerRepository } from '../../data/repositories/BannerRepository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
  ],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css',
})
export class BannerComponent implements OnInit {
  public banners: Loadable<Banner[]> = new Loadable<Banner[]>();
  pageTitle: string = 'Баннеры';
  pageSubtitle: string = 'Баннеры';
  private currentBanner: any = undefined;

  constructor(private BannersRepo: BannerRepository) {}

  ngOnInit(): void {
    this.getAllServiceExtensions().finally();
  }

  async getAllServiceExtensions(): Promise<void> {
    if (this.banners.status != UIStateType.Loading) {
      this.banners = new Loadable<Banner[]>({ kind: 'Loading' });
      await this.BannersRepo.findAll().then((data) => {
        this.banners = Loadable.getFromDataStatus(data);
      });

      console.log(this.banners);
    }
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.BannersRepo.delete(id);
      this.getAllServiceExtensions().finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  setBanner(banner: Banner): void {
    this.currentBanner = banner;
  }

  getBanner(): Banner {
    return this.currentBanner;
  }

  protected readonly UIStateType = UIStateType;
}
