import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-box',
  standalone: true,
  imports: [],
  templateUrl: './preview-box.component.html',
  styleUrl: './preview-box.component.css',
})
export class PreviewBoxComponent {
  @Input() height: string = '100px';
  @Input() width: string = '100px';
  @Input() cornerRadius: Number = 0;
  @Input() color: string = '#F0F0F0';
}
