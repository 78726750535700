<div class="card">
  <div class="card-header">
    <h3 class="card-title">Расписание</h3>
    <div class="ms-auto text-secondary">
      <a [routerLink]="['create-hours']" [state]="{ 'office': currentOffice }">
        <i class="ti ti-plus"></i>
      </a>
    </div>
  </div>

  <div class="list-group list-group-flush list-group-hoverable">
    <div class="list-group-item" *ngFor="let item of officeHours">
      <div class="row align-items-center">
        <div class="col text-truncate">
          {{ getWeekDay(item.weekday) }}
        </div>
        <div class="col text-truncate">
          {{ item.isDayOff ? '-' : item.openTime }}
        </div>
        <div class="col text-truncate">
          {{ item.isDayOff ? '-' : item.closeTime }}
        </div>
        <div class="col-auto">
          <div class="btn-list flex-nowrap">
            <a
              [routerLink]="['update-hours']"
              [state]="{ 'office': currentOffice, 'businessDay': item }"
              class="list-group-item-actions"
            >
              <i class="ti ti-pencil"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
