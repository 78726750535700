import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';

@Component({
  selector: 'app-mobile-abonent-services-detail-preview',
  standalone: true,
  imports: [NgFor, MarkdownModule, MobilePreviewComponent, NgIf],
  templateUrl: './mobile-abonent-services-detail-preview.component.html',
  styleUrl: './mobile-abonent-services-detail-preview.component.css',
})
export class MobileAbonentServicesDetailPreviewComponent {
  @Input() public title: string = '             ';
  @Input() public image: string = '';
  @Input() public description: string = '';
}
