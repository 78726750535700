import { Type } from '@angular/core';
import { IServiceExtFullDescFormComponentFactory } from './iservice-ext-full-desc-component-factory';
import {
  IFullDescFormComponentInputs,
  IFullDescFormComponentOutputs,
} from '../../interfaces/ifull-desc-form-component';
import { ServiceExtFullDescFormComponentComponent } from '../../../service-ext-full-desc-form-component/service-ext-full-desc-form-component.component';
import {
  IGeneralFullDescFormComponentInputs,
  IGeneralFullDescFormComponentOutputs,
} from '../../interfaces/igeneral-full-desc-form-component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ServiceExtension } from '../../../../../domain/entities/service-extension';

export class GeneralFullDescFormComponentFactory
  implements IServiceExtFullDescFormComponentFactory
{
  getComponent(): Type<ServiceExtFullDescFormComponentComponent> {
    return ServiceExtFullDescFormComponentComponent;
  }

  getInputs(
    allInputs: IFullDescFormComponentInputs,
    serviceExt: ServiceExtension | null,
  ): IGeneralFullDescFormComponentInputs {
    let fullDesc = undefined;
    let addFullDesc: any[] = [];

    if (serviceExt?.attributes) {
      let additionalFullDesc: any[] = [];
      let formBuilder = new FormBuilder();

      fullDesc = serviceExt?.attributes ? serviceExt.attributes['text'] : undefined;
      for (let addDesc of serviceExt.attributes['additional_desc']) {
        additionalFullDesc.push(
          formBuilder.group({
            title: addDesc.title,
            text: addDesc.text,
          }),
        );

        addFullDesc = additionalFullDesc;
      }
    }

    allInputs.form.addControl('cover', new FormControl(undefined));
    allInputs.form.addControl('fullDesc', new FormControl(fullDesc));
    allInputs.form.addControl('addFullDesc', new FormArray(addFullDesc));

    return {
      form: allInputs.form,
    };
  }

  getOutputs(allOutputs: IFullDescFormComponentOutputs): IGeneralFullDescFormComponentOutputs {
    return {
      outputAddFullDesc: allOutputs.outputAddFullDesc,
      outputCoverPreview: allOutputs.outputCoverPreview,
      outputCroppedCover: allOutputs.outputCroppedCover,
      outputFullDesc: allOutputs.outputFullDesc,
    };
  }
}
