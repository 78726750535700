import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';

import { BannerRepository } from '../../data/repositories/BannerRepository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
    PaginationViewComponent,
  ],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.css',
})
export class BannerComponent implements OnInit {
  public pageActiveBanners: Loadable<PaginationResponse<Banner>> = new Loadable<
    PaginationResponse<Banner>
  >();
  public pageHiddenBanners: Loadable<PaginationResponse<Banner>> = new Loadable<
    PaginationResponse<Banner>
  >();
  pageTitle: string = 'Баннеры';
  pageSubtitle: string = 'Список баннеров';
  private currentBanner: any = undefined;
  public activeBannersPerPage: number = 5;
  public activeBannersPageNumber: number = 1;
  public activeBannersCurrentPageNumber: number = 1;
  public hiddenBannersPerPage: number = 5;
  public hiddenBannersPageNumber: number = 1;
  public hiddenBannersCurrentPageNumber: number = 1;

  constructor(private BannersRepo: BannerRepository) {}

  ngOnInit(): void {
    this.getAllActiveBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
    this.getAllHiddenBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
  }

  async getAllActiveBanners(per_page: number, page_number: number): Promise<void> {
    if (this.pageActiveBanners.status != UIStateType.Loading) {
      this.pageActiveBanners = new Loadable<PaginationResponse<Banner>>({
        kind: 'Loading',
      });
      await this.BannersRepo.findAll(per_page, page_number, true).then((data) => {
        this.pageActiveBanners = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeActiveBannersPage(pageNumber: number) {
    this.activeBannersPageNumber = pageNumber;
    this.activeBannersCurrentPageNumber = pageNumber;
    this.getAllActiveBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
  }

  async getAllHiddenBanners(per_page: number, page_number: number): Promise<void> {
    if (this.pageHiddenBanners.status != UIStateType.Loading) {
      this.pageHiddenBanners = new Loadable<PaginationResponse<Banner>>({
        kind: 'Loading',
      });
      await this.BannersRepo.findAll(per_page, page_number, false).then((data) => {
        this.pageHiddenBanners = Loadable.getFromDataStatus(data);
      });
    }
  }

  changeHiddenBannersPage(pageNumber: number): void {
    this.hiddenBannersPageNumber = pageNumber;
    this.hiddenBannersCurrentPageNumber = pageNumber;
    this.getAllHiddenBanners(this.hiddenBannersPerPage, this.hiddenBannersPageNumber).finally();
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.BannersRepo.delete(id);
      this.getAllActiveBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
      this.getAllHiddenBanners(this.hiddenBannersPerPage, this.hiddenBannersPageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  setBanner(banner: Banner): void {
    this.currentBanner = banner;
  }

  getBanner(): Banner {
    return this.currentBanner;
  }

  async deactivateBanner(storyId: number): Promise<void> {
    let body = {
      'is_active': false,
    };
    await this.BannersRepo.update(body, storyId);
    this.getAllActiveBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
    this.getAllHiddenBanners(this.hiddenBannersPerPage, this.hiddenBannersPageNumber).finally();
  }

  async activateBanner(storyId: number): Promise<void> {
    let body = {
      'is_active': true,
    };
    await this.BannersRepo.update(body, storyId);
    this.getAllActiveBanners(this.activeBannersPerPage, this.activeBannersPageNumber).finally();
    this.getAllHiddenBanners(this.hiddenBannersPerPage, this.hiddenBannersPageNumber).finally();
  }

  protected readonly UIStateType = UIStateType;
}
