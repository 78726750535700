import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { PreviewBoxComponent } from '../../../../../components/common/mobile-preview/preview-box/preview-box.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-mobile-smotreshka-online-cinema-preview',
  standalone: true,
  imports: [MobilePreviewComponent, PreviewBoxComponent, NgIf],
  templateUrl: './mobile-smotreshka-online-cinema-preview.component.html',
  styleUrl: './mobile-smotreshka-online-cinema-preview.component.css',
})
export class MobileSmotreshkaOnlineCinemaPreviewComponent implements OnChanges {
  @Input() title!: string;
  @Input() desc!: string;
  @Input() cost!: string;
  @Input() iconPreview!: string;
  @Input() bannerPreview!: string;
  fracPart: string = '00';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cost']) {
      if (changes['cost'].currentValue != undefined) {
        if (this.cost.split('.').length > 1) {
          this.fracPart = this.cost.split('.')[this.cost.split('.').length - 1].slice(0, 2);
          this.cost = this.cost.split('.')[0];
        } else {
          this.fracPart = '00';
        }
      }
    }
  }
}
