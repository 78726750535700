import { Type } from '@angular/core';
import { IServiceExtMobilePreviewComponentFactory } from './iservice-ext-mobile-preview-component-factory';
import {
  IMobilePreviewComponentInputs,
  IMobilePreviewComponentOutputs,
} from '../../interfaces/imoblie-preview-component';
import { MobileCatvPacketPreviewComponent } from '../../../mobile-catv-packet-preview/mobile-catv-packet-preview.component';
import {
  ICATVMobilePreviewComponentInputs,
  ICATVMobilePreviewComponentOutputs,
} from '../../interfaces/icatv-mobile-preview-component';

export class CATVPacketMobilePreviewComponentFactory
  implements IServiceExtMobilePreviewComponentFactory
{
  getComponent(): Type<MobileCatvPacketPreviewComponent> {
    return MobileCatvPacketPreviewComponent;
  }

  getInputs(allInputs: IMobilePreviewComponentInputs): ICATVMobilePreviewComponentInputs {
    return {
      cost: allInputs.cost,
      title: allInputs.title,
      desc: allInputs.desc,
    };
  }

  getOutputs(allOutputs: IMobilePreviewComponentOutputs): ICATVMobilePreviewComponentOutputs {
    return {};
  }
}
