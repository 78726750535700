import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-img',
  standalone: true,
  imports: [NgIf],
  templateUrl: './spinner-img.component.html',
  styleUrl: './spinner-img.component.css',
})
export class SpinnerImgComponent {
  @Input() imgSrc?: string;
  loading: boolean = true;

  onLoad() {
    this.loading = false;
    console.log('load image');
  }

  onError($event: any) {
    console.log('error load image');
    $event.target.src = './assets/static/ubuntu-errors.png';
    this.loading = false;
  }
}
