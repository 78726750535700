import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  AbstractControl,
  FormArray,
  FormControl,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { AutocompleteSearchInputComponent } from '../../../../../components/common/inputs/autocomplete-search-input/autocomplete-search-input.component';
import { CommonModule } from '@angular/common';

import { ImageCropperComponent } from 'ngx-image-cropper';
import { Router, RouterModule } from '@angular/router';
import { MobilePreviewComponent } from '../../../../../components/common/mobile-preview/mobile-preview.component';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { ServiceExtension, ServiceExtTypeEnum } from '../../../domain/entities/service-extension';
import { ServiceExtensionType } from '../../../domain/entities/service-type';
import { DynamicModule } from 'ng-dynamic-component';
import { ServiceDef } from '../../../domain/entities/service-def';
import { ServiceExtFormComponentFactoryAggregate } from './factories/service-ext-form-component-factory-aggregate';
import { ServiceExtMobilePreviewComponentFactoryAggregate } from './factories/service-ext-mobile-preview-component-factory-aggregate';
import { ServiceExtFullDescComponentFactoryAggregate } from './factories/service-ext-full-desc-form-component-factory-aggregate';
import { IServiceExtFormComponentFactory } from './factories/service-ext-form-component-factories/iservice-ext-form-component-factory';
import { IFormComponentInputs, IFormComponentOutputs } from './interfaces/iform-component';
import {
  IMobilePreviewComponentInputs,
  IMobilePreviewComponentOutputs,
} from './interfaces/imoblie-preview-component';
import { IServiceExtMobilePreviewComponentFactory } from './factories/service-ext-mobile-preview-component-factories/iservice-ext-mobile-preview-component-factory';
import { IServiceExtFullDescFormComponentFactory } from './factories/service-ext-full-desc-form-component-factories/iservice-ext-full-desc-component-factory';
import {
  IFullDescFormComponentInputs,
  IFullDescFormComponentOutputs,
} from './interfaces/ifull-desc-form-component';
import { ServiceExtFullDescMobilePreviewFactoryAggregate } from './factories/service-ext-full-desc-mobile-preview-component-factory-aggregate';
import { IServiceExtFullDescMobilePreviewComponentFactory } from './factories/service-ext-full-desc-mobile-preview-component-factories/iservice-ext-full-desc-mobile-preview-component';
import {
  IFullDescMobilePreviewComponentInputs,
  IFullDescMobilePreviewComponentOutputs,
} from './interfaces/ifull-desc-mobile-preview-component';
import { ServiceExtAttributesFactoryAggregate } from './factories/service-ext-attributes-factory-aggregate';
import { Loadable } from '../../../../../../core/utils/wrappers/loadable';
import { PaginationResponse as PR } from '../../../../../../core/dashboard/domain/pagination-response';
import { DataStatus } from '../../../../../../core/network/data.status';
import { ServiceDefRepository } from '../../../data/repositories/service-def-repository';
import { ServiceDefListItemComponent } from '../../../../../components/common/inputs/list-item-components/service-def-list-item/service-def-list-item.component';

@Component({
  selector: 'app-service-extension-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    PageHeaderComponent,
    PageWrapperComponent,
    AutocompleteSearchInputComponent,
    ImageCropperComponent,
    RouterModule,
    MobilePreviewComponent,
    FormComponentComponent,
    DynamicModule,
  ],
  templateUrl: './service-extension-form.component.html',
  styleUrl: './service-extension-form.component.css',
})
export class ServiceExtensionFormComponent implements OnInit {
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  image: File | null = null;
  public keys = Object.keys;
  serviceExtType = ServiceExtTypeEnum;
  cost!: string;
  title!: string;
  desc!: string;
  packId!: string;
  public services: Loadable<PR<ServiceDef>> | null = null;
  public listItem = ServiceDefListItemComponent;

  inputs = {};
  outputs = {};

  fullDescInputs = {};
  fullDescOutputs = {};

  previewFullDescInputs = {};
  previewFullDescOutputs = {};

  previewInputs = {};
  previewOutputs = {};

  originalForm!: FormGroup;

  @Input() form!: FormGroup;
  @Input() chosenType!: ServiceExtensionType;
  @Input() billingServiceSearchStringName: string = '';
  @Input() currentServiceDef!: ServiceDef;
  @Input() currentServiceExt!: ServiceExtension;
  @Input() iconPreview!: string;
  @Input() bannerPreview!: string;
  @Input() coverPreview!: string;
  @Input() fullDesc!: string;
  @Input() addFullDesc: { [id: string]: any }[] = [];

  @Output() onBannerCropped: EventEmitter<Blob | null> = new EventEmitter();
  @Output() onCoverCropped: EventEmitter<Blob | null> = new EventEmitter();
  @Output() onIconCropped: EventEmitter<Blob | null> = new EventEmitter();
  @Output() onFormSubmit: EventEmitter<FormGroup> = new EventEmitter();

  allFormComponentInputs!: IFormComponentInputs;
  allFormComponentOutputs!: IFormComponentOutputs;

  allPreviewInputs!: IMobilePreviewComponentInputs;
  allPreviewOutputs!: IMobilePreviewComponentOutputs;

  allFullDescInputs!: IFullDescFormComponentInputs;
  allFullDescOutputs!: IFullDescFormComponentOutputs;

  allPreviewFullDescInputs!: IFullDescMobilePreviewComponentInputs;
  allPreviewFullDescOutputs!: IFullDescMobilePreviewComponentOutputs;

  constructor(
    public router: Router,
    private serviceExtFormComponentFactoryAggregate: ServiceExtFormComponentFactoryAggregate,
    private serviceExtMobilePreviewComponentFactoryAggregate: ServiceExtMobilePreviewComponentFactoryAggregate,
    private serviceExtFullDescComponentFactoryAggregate: ServiceExtFullDescComponentFactoryAggregate,
    private serviceExtFullDescMobilePreviewComponentFactoryAggregate: ServiceExtFullDescMobilePreviewFactoryAggregate,
    private serviceExtAttributesFactoryAggregate: ServiceExtAttributesFactoryAggregate,
    private serviceDefRepository: ServiceDefRepository,
  ) {}

  ngOnInit(): void {
    this.originalForm = cloneAbstractControl(this.form);
  }

  pageTitle: string = 'Создать расширение услуги';
  pageSubtitle: string = 'Создать расширение услуги';

  fileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  public async onSubmit(): Promise<void> {
    let attributesFactory = this.serviceExtAttributesFactoryAggregate.getFactory(this.chosenType);
    this.form.addControl(
      'attributes',
      new FormControl(attributesFactory?.createAttributes(this.form)),
    );

    this.onFormSubmit.emit(this.form);
  }

  setServiceType($event: any): void {
    this.chosenType = $event.target.value;
  }

  resetForm(): void {
    let formDiff = Object.keys(this.form.controls).filter(
      (item) => Object.keys(this.originalForm.controls).indexOf(item) < 0,
    );
    if (formDiff.length > 0) {
      formDiff.forEach((control) => this.form.removeControl(control));
    }
  }

  getServiceExtType(type: string): string {
    return ServiceExtTypeEnum[type as keyof typeof ServiceExtTypeEnum];
  }

  onFoundEntityEmitter($event: ServiceDef) {
    this.billingServiceSearchStringName = $event.name;
    this.currentServiceDef = $event;
  }

  getFormComponent(): Type<any> | null {
    let factory: IServiceExtFormComponentFactory | null =
      this.serviceExtFormComponentFactoryAggregate.getFactory(this.chosenType);

    this.allFormComponentInputs = { form: this.form };
    this.allFormComponentOutputs = {
      outputCroppedBanner: (croppedBanner: Blob) => this.onBannerCropped.emit(croppedBanner),
      outputBannerPreview: (bannerPreview: string) => (this.bannerPreview = bannerPreview),
      outputCroppedIcon: (croppedIcon: Blob) => this.onIconCropped.emit(croppedIcon),
      outputIconPreview: (iconPreview: string) => (this.iconPreview = iconPreview),
      title: (title: string) => (this.title = title),
      desc: (desc: string) => (this.desc = desc),
    };

    if (factory) {
      this.inputs = factory.getInputs(this.allFormComponentInputs, this.currentServiceExt);
      this.outputs = factory.getOutputs(this.allFormComponentOutputs);
      return factory.getComponent();
    } else {
      return null;
    }
  }

  getFullDescComponent(): Type<any> | null {
    let factory: IServiceExtFullDescFormComponentFactory | null =
      this.serviceExtFullDescComponentFactoryAggregate.getFactory(this.chosenType);

    this.allFullDescInputs = { form: this.form };

    this.allFullDescOutputs = {
      outputCroppedCover: (croppedCover: Blob) => this.onCoverCropped.emit(croppedCover),
      outputCoverPreview: (coverPreview: string) => (this.coverPreview = coverPreview),
      outputFullDesc: (fullDesc: string) => (this.fullDesc = fullDesc),
      outputAddFullDesc: (addFullDesc: { [id: string]: any }[]) => (this.addFullDesc = addFullDesc),
    };

    if (factory) {
      this.fullDescInputs = factory.getInputs(this.allFullDescInputs, this.currentServiceExt);
      this.fullDescOutputs = factory.getOutputs(this.allFullDescOutputs);
      return factory.getComponent();
    } else {
      return null;
    }
  }

  getMobilePreviewComponent(): Type<any> | null {
    let factory: IServiceExtMobilePreviewComponentFactory | null =
      this.serviceExtMobilePreviewComponentFactoryAggregate.getFactory(this.chosenType);

    if (this.currentServiceDef != undefined) {
      this.cost = this.currentServiceDef.cost.toString();
      this.packId = this.currentServiceDef.params ? this.currentServiceDef.params['pack_id'] : null;
    }

    if (this.form.value['title'] != undefined) {
      this.title = this.form.value['title'];
    }

    if (this.form.value['description'] != undefined) {
      this.desc = this.form.value['description'];
    }

    this.allPreviewInputs = {
      cost: this.cost,
      title: this.title,
      desc: this.desc,
      iconPreview: this.iconPreview,
      packId: this.packId,
      bannerPreview: this.bannerPreview,
      coverPreview: this.coverPreview,
    };

    this.allPreviewOutputs = {
      outputNumberOfChannels: (outputNumberOfChannels: number) =>
        this.form.patchValue({ numberOfChannels: outputNumberOfChannels }),
    };

    if (this.form.controls['packId']) {
      this.form.patchValue({ packId: parseInt(this.packId) });
    }

    if (factory) {
      this.previewInputs = factory.getInputs(this.allPreviewInputs);
      this.previewOutputs = factory.getOutputs(this.allPreviewOutputs);
      return factory.getComponent();
    } else {
      return null;
    }
  }

  getMobileFullDescPreviewComponent(): Type<any> | null {
    let factory: IServiceExtFullDescMobilePreviewComponentFactory | null =
      this.serviceExtFullDescMobilePreviewComponentFactoryAggregate.getFactory(this.chosenType);

    this.allPreviewFullDescInputs = {
      coverPreview: this.coverPreview,
      cost: this.cost,
      fullDesc: this.fullDesc,
      addFullDesc: this.addFullDesc,
      title: this.title,
    };

    this.allPreviewFullDescOutputs = {};

    if (factory) {
      this.previewFullDescInputs = factory.getInputs(this.allPreviewFullDescInputs);
      this.previewFullDescOutputs = factory.getOutputs(this.allPreviewFullDescOutputs);
      return factory.getComponent();
    } else {
      return null;
    }
  }

  async onQueryString($event: string) {
    let query = $event;

    this.services = new Loadable({ kind: 'Loading' });
    try {
      let result: DataStatus<PR<ServiceDef>> =
        await this.serviceDefRepository.findAllByLikeSearchCriteria('name', query, 1, 15);
      this.services = Loadable.getFromDataStatus(result);
    } catch (error: any) {
      console.log(error);
    }
  }
}

export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach((key) => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });

    newControl = formGroup as any;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach((formControl) =>
      formArray.push(cloneAbstractControl(formControl) as never),
    );

    newControl = formArray as any;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
  } else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) newControl.disable({ emitEvent: false });

  return newControl;
}
