import { AbonentService } from '../../domain/abonent-service';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { AbonentServiceMapper } from '../mappers/abonent-service';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { AbonentServiceApiDto } from '../abonent-service-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';

@Injectable({ providedIn: 'root' })
export class AbonentServiceRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private abonentServiceMapper: AbonentServiceMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(): Promise<DataStatus<AbonentService[]>> {
    let response: DataStatus<AbonentServiceApiDto[]> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services`,
    );
    return response.map((data) => {
      return data.map((item) => this.abonentServiceMapper.toDomain(item));
    });
  }

  async create(
    newAbonentService: any,
    icon_id: number,
    image_id?: number,
  ): Promise<DataStatus<AbonentService>> {
    var body = newAbonentService;
    body['icon_id'] = icon_id;

    if (image_id) {
      body['image_id'] = image_id;
    }

    let response: DataStatus<AbonentServiceApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services`,
      body,
    );

    let successResponse = response as DataStatusSuccess<AbonentServiceApiDto>;
    return successResponse.map((data) => {
      return this.abonentServiceMapper.toDomain(successResponse.data);
    });
  }

  async delete(abonent_service_id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services/${abonent_service_id}`,
    );
  }

  async update(
    updatedAbonentService: any,
    abonent_service_id: number,
    icon_id?: number,
    image_id?: number,
  ): Promise<DataStatus<AbonentService>> {
    var body = updatedAbonentService;
    if (image_id) {
      body['image_id'] = image_id;
    }

    if (icon_id) {
      body['icon_id'] = icon_id;
    }

    let response: DataStatus<AbonentServiceApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/abonent_services/${abonent_service_id}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<AbonentServiceApiDto>;
    return successResponse.map((data) => {
      return this.abonentServiceMapper.toDomain(successResponse.data);
    });
  }
}
