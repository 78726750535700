<form [formGroup]="form">
  <div class="mb-3">
    <label class="form-label required">Название услуги</label>
    <input
      class="form-control"
      placeholder="Введите название услуги, которое будет отображаться в приложении"
      formControlName="title"
      (input)="onTitleChanged($event)"
    />
  </div>

  <div class="mb-3">
    <label class="form-label required">Описание услуги</label>
    <textarea
      class="form-control"
      placeholder="Введите описание услуги"
      formControlName="description"
      (input)="onDescChanged($event)"
    ></textarea>
  </div>

  <div class="mb-3">
    <label class="form-label required">Количество каналов</label>
    <input
      class="form-control"
      type="number"
      placeholder="Введите количество каналов"
      formControlName="numberOfChannels"
    />
  </div>
</form>
