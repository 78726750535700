import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AbonentServiceRepository } from '../../data/repositories/AbonentServiceRepository';
import { AbonentService } from '../../domain/abonent-service';

import { AbonentServiceType } from '../../domain/abonent-service';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { AbonentServicesFormComponent } from '../../components/abonent-services-form/abonent-services-form';
import { MobileAbonentServicesDetailPreviewComponent } from '../../components/mobile-abonent-services-detail-preview/mobile-abonent-services-detail-preview.component';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';

@Component({
  selector: 'app-abonent-services-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    ReactiveFormsModule,
    ImageCropperComponent,
    MobilePreviewComponent,
    DragAndDropFileUploadComponent,
    LMarkdownEditorModule,
    MobileAbonentServicesDetailPreviewComponent,
    AbonentServicesFormComponent,
  ],
  templateUrl: './abonent-services-create.component.html',
  styleUrl: './abonent-services-create.component.css',
})
export class AbonentServicesCreateComponent implements OnInit {
  public abonentServices: Loadable<AbonentService[]> = new Loadable<AbonentService[]>();
  pageTitle: string = 'Добавление сервиса';
  pageSubtitle: string = 'Сервисы';
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  private icon: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  private image: Loadable<Attachment[]> | null = null;
  public abonentServiceType = AbonentServiceType;
  public keys = Object.keys;
  imageChangedEvent: Event | null = null;
  iconChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  croppedIcon?: Blob | null;
  public title: string = '';
  public imagePreview: string = '';
  public full_description: string = '';
  public abonentServiceForm!: FormGroup;

  constructor(
    private AbonentsServiceRepo: AbonentServiceRepository,
    private AttachmentRepo: AttachmentRepository,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.abonentServiceForm = this.formBuilder.group({
      type: new FormControl(undefined, [Validators.required]),
      title: new FormControl(undefined, [Validators.required]),
      short_description: new FormControl(undefined, [Validators.required]),
      full_description: new FormControl(undefined, [Validators.required]),
      service_link_web: undefined,
      service_link_android: undefined,
      service_link_ios: undefined,
      icon: new FormControl(undefined, [Validators.required]),
      image: undefined,
    });
  }

  async onCreateSubmit(): Promise<void> {
    try {
      this.icon = new Loadable<Attachment[]>({ kind: 'Loading' });
      await this.AttachmentRepo.create(this.croppedIcon as Blob).then((data) => {
        this.icon = Loadable.getFromDataStatus(data);
      });
      delete this.abonentServiceForm.value['icon'];

      if (this.abonentServiceForm.value['image']) {
        this.image = new Loadable<Attachment[]>({ kind: 'Loading' });
        await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
          this.image = Loadable.getFromDataStatus(data);
        });
        delete this.abonentServiceForm.value['image'];
      }

      await this.AbonentsServiceRepo.create(
        this.abonentServiceForm.value,
        this.icon.data[0].id,
        this.image?.data[0].id,
      );

      this.router.navigate(['/abonent-services']);
    } catch (error: any) {
      console.log(error);
    }
  }

  onOutputCroppedImage($event: Blob) {
    this.croppedImage = $event;
  }

  onOutputCroppedIcon($event: Blob) {
    this.croppedIcon = $event;
  }

  onAbonentServiceFormData($event: FormGroup) {
    this.abonentServiceForm = $event;
    this.onCreateSubmit().finally();
  }

  protected readonly UIStateType = UIStateType;
}
