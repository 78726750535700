import { Type } from '@angular/core';
import { IServiceExtMobilePreviewComponentFactory } from './iservice-ext-mobile-preview-component-factory';
import {
  IMobilePreviewComponentInputs,
  IMobilePreviewComponentOutputs,
} from '../../interfaces/imoblie-preview-component';
import { MobileSmotreshkaOnlineCinemaPreviewComponent } from '../../../mobile-smotreshka-online-cinema-preview/mobile-smotreshka-online-cinema-preview.component';
import {
  ISmotreshkaOnlineCinemaMobilePreviewComponentInputs,
  ISmotreshkaOnlineCinemaMobilePreviewComponentOutputs,
} from '../../interfaces/ismotreshka-online-cinema-mobile-preview-component';

export class SmotreshkaOnlineCinemaMobilePreviewComponentFactory
  implements IServiceExtMobilePreviewComponentFactory
{
  getComponent(): Type<MobileSmotreshkaOnlineCinemaPreviewComponent> {
    return MobileSmotreshkaOnlineCinemaPreviewComponent;
  }

  getInputs(
    allInputs: IMobilePreviewComponentInputs,
  ): ISmotreshkaOnlineCinemaMobilePreviewComponentInputs {
    return {
      cost: allInputs.cost,
      title: allInputs.title,
      desc: allInputs.desc,
      iconPreview: allInputs.iconPreview,
      bannerPreview: allInputs.bannerPreview,
    };
  }

  getOutputs(
    allOutputs: IMobilePreviewComponentOutputs,
  ): ISmotreshkaOnlineCinemaMobilePreviewComponentOutputs {
    return {};
  }
}
