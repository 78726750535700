import { ActionButton } from '../../domain/story';

import { ActionButtonMapper } from '../mappers/action-button';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { APP_CONFIG } from '../../../../../environments/environment';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { ActionButtonApiDto } from '../stories-dto';

@Injectable({ providedIn: 'root' })
export class ActionButtonRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private actionButtonMapper: ActionButtonMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(body: any): Promise<DataStatus<ActionButton>> {
    let response: DataStatus<ActionButtonApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/action_buttons`,
      body,
    );

    let successResponse = response as DataStatusSuccess<ActionButtonApiDto>;
    return successResponse.map((data) => {
      return this.actionButtonMapper.toDomain(successResponse.data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/action_buttons/${id}`,
    );
  }
}
