import { DataStatus, DataStatusError, DataStatusSuccess } from '../../network/data.status';

export enum UIStateType {
  NotRequested = 'NotRequested',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface UIStateSuccessConstructor<T> {
  kind: 'Success';
  data?: T;
}

export interface UIStateErrorConstructor<T> {
  kind: 'Error';
  message: string;
}

export interface UIStateNotRequestedConstructor {
  kind: 'NotRequested';
}

export interface UIStateLoadingConstructor {
  kind: 'Loading';
}

type UIStateConstructor<T> =
  | UIStateSuccessConstructor<T>
  | UIStateErrorConstructor<T>
  | UIStateNotRequestedConstructor
  | UIStateLoadingConstructor;

export class Loadable<T> {
  private _status: UIStateType = UIStateType.NotRequested;
  get status(): UIStateType {
    return this._status;
  }

  private _data?: T;

  get data(): T | never {
    if (this._data != null && this._status == UIStateType.Success) {
      return this._data;
    }
    throw Error('UIStateType != Success, data is empty.');
  }

  private _message?: string;
  get message(): string | undefined {
    return this._message;
  }

  constructor(initial: UIStateConstructor<T> = { kind: 'NotRequested' }) {
    switch (initial.kind) {
      case 'Success':
        this.successConstructor(initial);
        break;
      case 'Error':
        this.errorConstructor(initial);
        break;
      case 'NotRequested':
        this.notRequestedConstructor(initial);
        break;
      case 'Loading':
        this.loadingConstructor(initial);
        break;
    }
  }

  static getFromDataStatus<T>(dataStatus: DataStatus<T>): Loadable<T> {
    switch (dataStatus.constructor) {
      case DataStatusSuccess:
        return new Loadable<T>({
          kind: 'Success',
          data: (dataStatus as DataStatusSuccess<T>).data,
        });
      default:
        return new Loadable<T>({
          kind: 'Error',
          message: (dataStatus as DataStatusError<T>).message,
        });
    }
  }

  getData(): T {
    return this.data;
  }

  getMessage(): string | undefined {
    return this.message;
  }
  //
  // setLoadingStatus(): void {
  //   this._status = UIStateType.Loading
  // }
  //
  // setSuccessStatus(data: T): void {
  //   this._data = data
  //   this._status = UIStateType.Success
  // }
  //
  // setErrorStatus(message: string): void {
  //   this._status = UIStateType.Error
  //   this.message = message
  // }

  private successConstructor({ data }: UIStateSuccessConstructor<T>): void {
    this._data = data;
    this._status = UIStateType.Success;
  }

  private errorConstructor({ message }: UIStateErrorConstructor<T>): void {
    this._message = message;
    this._status = UIStateType.Error;
  }

  private notRequestedConstructor({}: UIStateNotRequestedConstructor): void {
    this._status = UIStateType.NotRequested;
  }

  private loadingConstructor({}: UIStateLoadingConstructor): void {
    this._status = UIStateType.Loading;
  }
}
