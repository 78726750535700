import { Deeplink } from '../../../../entity/deeplinks/domain/entities/deeplink';
import { Content } from '../../../../entity/attachments/domain/attachment';
import { AdminUser } from '../../../../entity/admin-user/domain/admin-user';

export class ActionButton {
  constructor(
    public id: number,
    public body: string,
    public deeplink_id: number,
    public deeplink: Deeplink,
  ) {}
}

export class StorySlide {
  constructor(
    public id: number,
    public header: string,
    public body: string,
    public story_id: number,
    public attachment_id: number,
    public attachment: Content,
    public action_buttons: Array<ActionButton>,
  ) {}
}

export class Story {
  constructor(
    public id: number,
    public name: string,
    public created_at: number,
    public updated_at: number,
    public created_by: number,
    public updated_by: number | null,
    public preview: string,
    public analytics_target_name: string,
    public story_views: number,
    public story_likes: number,
    public annotation: string | null,
    public slides: Array<StorySlide>,
    public is_active: boolean,
    public deactivated_at: number,
    public admin_creator: AdminUser,
    public admin_editor: AdminUser | null,
  ) {}
}
