import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Banner } from '../../domain/banner';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BannerRepository } from '../../data/repositories/BannerRepository';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { DeeplinkRepository } from '../../../../../entity/deeplinks/data/repositories/DeeplinkRepository';
import { Router } from '@angular/router';
import { Deeplink } from '../../../../../entity/deeplinks/domain/entities/deeplink';
import { FileInputComponent } from '../../../../../entity/file-input/file-input.component';
import { ImagePreviewWithButtonComponent } from '../../../../components/image-preview-with-button/image-preview-with-button.component';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { BannerFormComponent } from '../../components/forms/banner-form/banner-form';
import { Attachment } from '../../../../../entity/attachments/domain/attachment';
import { AttachmentRepository } from '../../../../../entity/attachments/data/repositories/AttachmentRepository';

@Component({
  selector: 'app-update-banner',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    ReactiveFormsModule,
    FileInputComponent,
    ImagePreviewWithButtonComponent,
    ImageCropperComponent,
    BannerFormComponent,
  ],
  templateUrl: './banners-update.component.html',
  styleUrl: './banners-update.component.css',
})
export class BannerUpdateComponent implements OnInit {
  public banners: Loadable<Banner[]> = new Loadable<Banner[]>();
  pageTitle: string = 'Обновить баннер';
  pageSubtitle: string = 'Баннеры';
  private deeplink: Loadable<Deeplink> = new Loadable<Deeplink>();
  private attachment: Loadable<Attachment[]> = new Loadable<Attachment[]>();
  public preview: string = '';
  private deeplink_id: number = 0;
  public currentBanner: any = undefined;
  public fakeBtnText: string = '';
  public isSubmitDisabled: boolean = true;
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;
  public bannerForm!: FormGroup;

  constructor(
    private BannersRepo: BannerRepository,
    private formBuilder: FormBuilder,
    private DeeplinkRepo: DeeplinkRepository,
    private AttachmentRepo: AttachmentRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.bannerForm = this.formBuilder.group({
      title: undefined,
      description: undefined,
      link: undefined,
      image: undefined,
    });

    this.currentBanner = history.state;

    this.bannerForm.patchValue(this.currentBanner.deeplink);
    this.preview = this.currentBanner.image.content.attributes.content_path;
    this.fakeBtnText = this.currentBanner.deeplink.title;
  }

  async onUpdateSubmit(): Promise<void> {
    this.deeplink = new Loadable<Deeplink>({ kind: 'Loading' });
    this.attachment = new Loadable<Attachment[]>({ kind: 'Loading' });
    this.deeplink_id = this.currentBanner.deeplink.id;

    for (var i in this.bannerForm.controls) {
      if (this.bannerForm.controls[i].value == undefined) {
        delete this.bannerForm.value[i];
      }
    }

    if (this.bannerForm.value['image']) {
      await this.AttachmentRepo.create(this.croppedImage as Blob).then((data) => {
        this.attachment = Loadable.getFromDataStatus(data);
      });

      await this.BannersRepo.update(this.attachment.data[0].id, this.currentBanner.id);
    }

    delete this.bannerForm.value['image'];
    if (this.currentBanner.deeplink.id) {
      await this.DeeplinkRepo.update(this.bannerForm.value, this.deeplink_id).then((data) => {
        this.deeplink = Loadable.getFromDataStatus(data);
      });
    }

    this.router.navigate(['/banners']);
  }

  onOutputCroppedImage($event: Blob) {
    this.croppedImage = $event;
  }

  onBannerFormData($event: FormGroup) {
    this.bannerForm = $event;
    this.onUpdateSubmit().finally();
  }

  protected readonly UIStateType = UIStateType;
}
