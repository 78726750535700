import { OfficeBusinessHours } from '../../orion-offices-business-hours/domain/orion-office-business-hours';
import { OfficeContact } from '../../orion-offices-contacts/domain/orion-office-contact';

export enum OfficeCityType {
  krasnoyarsk = 'Красноярск',
  irkutsk = 'Иркутск',
  kansk = 'Канск',
  abakan = 'Абакан',
  bratsk = 'Братск',
  chernogorsk = 'Черногорск',
  minusinsk = 'Минусинск',
  zheleznogorsk = 'Железногорск',
  zelenogorsk = 'Зеленогорск',
  sayanogorsk = 'Саяногорск',
  kiselevsk = 'Киселевск',
  novokuznetsk = 'Новокузнетск',
  prokopyevsk = 'Прокопьевск',
}

export class OrionOffice {
  constructor(
    public id: number,
    public address: string,
    public latitude: number,
    public longitude: number,
    public city: string,
    public businessDays: Array<OfficeBusinessHours> | undefined,
    public contacts: Array<OfficeContact> | undefined,
  ) {}
}
