<form [formGroup]="form">
  <div class="mb-3">
    <div class="card">
      <div class="card-body">
        <label class="form-label"
          >Загрузите обложку сервиса на экран сервиса
          <span style="color: #929dab">(опционально)</span></label
        >
        <drag-and-drop-file-upload
          *ngIf="coverChangedEvent == null"
          formControlName="cover"
          (onFileSelect)="coverFileChangeEvent($event)"
        ></drag-and-drop-file-upload>
        <div class="image-cropper-container" *ngIf="coverChangedEvent !== null">
          <button class="btn btn-danger remove-icon-btn" (click)="removeCover()">
            <i class="ti ti-trash"></i>
          </button>
          <image-cropper
            class="image-cropper"
            [aspectRatio]="1080 / 900"
            [imageChangedEvent]="coverChangedEvent"
            (imageCropped)="coverCropped($event)"
            format="jpeg"
            [maintainAspectRatio]="true"
          ></image-cropper>
        </div>
      </div>

      <div class="mt-3 information_doc card-footer">
        <div class="row">
          <div class="col-md-12">
            <div class="file-req-header">Рекомендация к обложке</div>
            <div class="file-req-text">
              Рекомендуемый размер для обложки - 1080 х 900, где основное изображение находится в
              центральной области и помещаеся в зону - 1080 х 672
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label class="form-label">Подробное описание услуги</label>
    <md-editor
      formControlName="fullDesc"
      [upload]="doUpload"
      (onPreviewDomChanged)="changeFullDesc()"
    ></md-editor>
  </div>
  <div class="mb-3">
    <div formArrayName="addFullDesc" *ngFor="let item of addFullDescData.controls; let i = index">
      <div [formGroupName]="i">
        <label class="form-label">Подзаголовок в описании</label>
        <input formControlName="title" class="mb-3 form-control" (input)="changeAddFullDesc()" />
        <label class="form-label">Дополнительный пункт описания</label>
        <md-editor
          formControlName="text"
          [upload]="doUpload"
          (onPreviewDomChanged)="changeAddFullDesc()"
        ></md-editor>
      </div>
    </div>
    <button type="button" class="btn btn-link" (click)="addItem()">
      <i class="ti ti-plus"></i> Добавить пункт описания
    </button>
  </div>
</form>
