import { OAuthClient } from '../infrastructure/network/oauth-client';
import { Injectable } from '@angular/core';
import { OAuthAccessTokenLocal } from '../../../core/localData/oauth-access-token-local';

@Injectable({ providedIn: 'root' })
export class AccessGuardService {
  constructor(
    private oauthClient: OAuthClient,
    private oauthAccessTokenLocal: OAuthAccessTokenLocal,
  ) {}

  public async canActivate(): Promise<boolean> {
    let accessToken: string | null = this.oauthAccessTokenLocal.accessToken;
    if (accessToken != null && !this.oauthAccessTokenLocal.isExpired()) {
      return true;
    } else {
      try {
        await this.oauthClient.refreshToken();
        return true;
      } catch (error) {
        this.oauthClient.redirectToAuthPage();
      }
    }
    return false;
  }
}
