import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OrionOfficeBusinessHoursRepository } from '../../data/repositories/OrionOfficeBusinessHoursRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-business-hours-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-business-hours-update.component.html',
  styleUrl: './orion-offices-business-hours-update.component.css',
})
export class OrionOfficesBusinessHoursUpdateComponent {
  pageTitle: string = 'Редактировать время для офиса';
  pageSubtitle: string = 'Офисы';
  public currentOfficeHours: any = undefined;
  public currentOffice: any = undefined;
  public isSubmitDisabled: boolean = true;

  public updateOfficeHoursForm = this.formBuilder.record({
    open_time: undefined,
    close_time: undefined,
    is_day_off: undefined,
    office_id: undefined,
    weekday: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficeRepo: OrionOfficeBusinessHoursRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentOfficeHours = history.state['businessDay'];
    this.currentOffice = history.state['office'];
    this.updateOfficeHoursForm.patchValue({
      'open_time': this.currentOfficeHours.openTime,
      'close_time': this.currentOfficeHours.closeTime,
      'is_day_off': this.currentOfficeHours.isDayOff,
    });

    this.updateOfficeHoursForm.get('is_day_off')?.valueChanges.subscribe((value) => {
      if (value) {
        this.updateOfficeHoursForm.get('open_time')?.disable();
        this.updateOfficeHoursForm.get('close_time')?.disable();
      } else {
        this.updateOfficeHoursForm.get('open_time')?.enable();
        this.updateOfficeHoursForm.get('close_time')?.enable();
      }
    });
  }

  async onUpdateSubmit(): Promise<void> {
    for (var i in this.updateOfficeHoursForm.controls) {
      if (this.updateOfficeHoursForm.controls[i].value == undefined) {
        delete this.updateOfficeHoursForm.value[i];
      }
    }

    await this.OrionOfficeRepo.update(this.updateOfficeHoursForm.value, this.currentOfficeHours.id);

    this.router.navigate(['/orion-offices/detail'], { state: this.currentOffice });
  }

  checkIfOfficeChanged($event: any) {
    var element = $event.target;

    if (element.value != this.currentOfficeHours[element.id.replace('update-', '')]) {
      this.isSubmitDisabled = false;
    }
  }

  protected readonly UIStateType = UIStateType;
}
