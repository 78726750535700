import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { DndDirective } from './dnd.directive';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [DndDirective, NgIf],
  selector: 'drag-and-drop-file-upload',
  templateUrl: './drag-and-drop-file-upload.component.html',
  styleUrls: ['./drag-and-drop-file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropFileUploadComponent),
      multi: true,
    },
  ],
})
export class DragAndDropFileUploadComponent implements ControlValueAccessor {
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() acceptFormats: string = 'image/*';
  public value?: Blob;
  public file?: File;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: Blob): void {
    this.value = value;
  }

  registerOnChange(fn: ($event: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onFileDropped($event: any): void {
    $event.target.files = $event.dataTransfer.files;
    this.processFile($event);
  }

  fileBrowseHandler($event: any): void {
    this.processFile($event);
  }

  processFile($event: any): void {
    if ($event.target.files[0].size > 1024 * 1024 * 15) {
      var element = $event.target;
      element.classList.add('is-invalid');
      return;
    }

    var image: Blob = $event.target.files[0];
    this.value = image;
    this.file = image as File;

    this.onFileSelect.emit($event);

    this.onChange(image);
    this.onTouch();
  }

  validateCreateFileForm($event: any) {
    var element = $event.target;
    element.classList.remove('is-invalid');
  }

  removeFile() {
    this.file = undefined;
  }
}
