import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { Router } from '@angular/router';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { FileInputComponent } from '../../../../../../entity/file-input/file-input.component';
import { ImagePreviewWithButtonComponent } from '../../../../../components/image-preview-with-button/image-preview-with-button.component';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';

@Component({
  selector: 'app-banner-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FileInputComponent,
    ImagePreviewWithButtonComponent,
    ImageCropperComponent,
  ],
  templateUrl: './banner-form.html',
})
export class BannerFormComponent implements OnInit {
  @Input() preview: any;
  @Input() fakeBtnText: string = '';
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;

  @Input() bannerForm!: FormGroup;
  @Input() submitButtonText: string = '';

  @Output() outputCroppedImage: EventEmitter<Blob> = new EventEmitter();
  @Output() bannerFormData: EventEmitter<FormGroup> = new EventEmitter();

  constructor(public router: Router) {}

  ngOnInit(): void {}

  onSubmit() {
    this.bannerFormData.emit(this.bannerForm);
  }

  changeFakeBtnText($event: any) {
    this.fakeBtnText = $event.target.value;
  }

  fileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };

    if (this.croppedImage) {
      reader.readAsDataURL(this.croppedImage);
    }

    if (this.croppedImage) {
      this.outputCroppedImage.emit(this.croppedImage);
    }
  }

  protected readonly UIStateType = UIStateType;
}
