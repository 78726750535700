import { Injectable } from '@angular/core';
import { ServiceExtensionType } from '../../../../domain/entities/service-type';
import { IServiceExtFullDescMobilePreviewComponentFactory } from './service-ext-full-desc-mobile-preview-component-factories/iservice-ext-full-desc-mobile-preview-component';
import { GeneralFullDescMobilePreviewComponentFactory } from './service-ext-full-desc-mobile-preview-component-factories/general-full-desc-mobile-preview-component-factory';

const ServiceExtMobileFullDescPreviewComponent = {
  [ServiceExtensionType.GENERAL]: GeneralFullDescMobilePreviewComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_TV_PACKET]: null,
  [ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA]: null,
  [ServiceExtensionType.CATV_PACKET]: null,
};

@Injectable({ providedIn: 'root' })
export class ServiceExtFullDescMobilePreviewFactoryAggregate {
  getFactory(
    serviceType: ServiceExtensionType,
  ): IServiceExtFullDescMobilePreviewComponentFactory | null {
    try {
      return new ServiceExtMobileFullDescPreviewComponent[serviceType]!();
    } catch {
      return null;
    }
  }
}
