import { Component, Input } from '@angular/core';
import { CommonButtonComponent } from '../common-button/common-button.component';

/**
 *
 * @example
 * <add-button (click)="add()"></add-button>
 *
 */

@Component({
  selector: 'app-add-button',
  standalone: true,
  imports: [CommonButtonComponent],
  templateUrl: './add-button.component.html',
  styleUrl: './add-button.component.css',
})
export class AddButtonComponent {
  @Input() public title = 'Создать элемент';
  @Input() public class = '';
  @Input() public disabled = false;
}
