<form [formGroup]="form">
  <div class="mb-3">
    <label class="form-label required">Название услуги</label>
    <input
      class="form-control"
      placeholder="Введите название услуги, которое будет отображаться в приложении"
      formControlName="title"
      (input)="onTitleChanged($event)"
    />
  </div>
  <div class="mb-3">
    <div class="card">
      <div class="card-body">
        <label class="form-label required">Загрузите иконку пакета каналов</label>
        <drag-and-drop-file-upload
          *ngIf="iconChangedEvent == null"
          formControlName="icon"
          (onFileSelect)="iconFileChangeEvent($event)"
        ></drag-and-drop-file-upload>
        <div class="image-cropper-container" *ngIf="iconChangedEvent !== null">
          <button class="btn btn-danger remove-icon-btn" (click)="removeIcon()">
            <i class="ti ti-trash"></i>
          </button>
          <image-cropper
            class="image-cropper"
            [aspectRatio]="1 / 1"
            [imageChangedEvent]="iconChangedEvent"
            (imageCropped)="iconCropped($event)"
            format="jpeg"
            [maintainAspectRatio]="true"
          ></image-cropper>
        </div>
      </div>

      <div class="mt-3 information_doc card-footer">
        <div class="row">
          <div class="col-md-6">
            <div class="file-req-header">Соотношение сторон</div>
            <div class="file-req-text">
              Рекомендуемое соотношение сторон для фото или видео - 1:1
            </div>
          </div>
          <div class="col-md-6">
            <div class="file-req-header">Ограничение для фото</div>
            <div class="file-req-text">Минимальный размер - 72 x 72</div>
            <div class="file-req-text">Рекомендуемый размер - 216 х 216</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <label class="form-label required">Описание услуги</label>
    <textarea
      class="form-control"
      placeholder="Введите описание услуги"
      formControlName="description"
      (input)="onDescChanged($event)"
    ></textarea>
  </div>
</form>
