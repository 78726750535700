import { IEnvironment } from './ienvironment';

import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<IEnvironment>('app.environments');

export const environment: IEnvironment = {
  production: false,
  esysApiUrl: 'https://orion-ecosystem-dev.orionnet.ru',
  authRedirectUrl: 'https://esys-dashboard-dev.orionnet.ru/auth/redirect',
  authUrl: 'https://test-oauth.orionnet.ru',
  clientId: 170,
  clientSecret: '2e2985cd3d4d96cfbbfabcf67585213f',
};
