import { Type } from '@angular/core';
import { IServiceExtFullDescMobilePreviewComponentFactory } from './iservice-ext-full-desc-mobile-preview-component';
import {
  IFullDescMobilePreviewComponentInputs,
  IFullDescMobilePreviewComponentOutputs,
} from '../../interfaces/ifull-desc-mobile-preview-component';
import { MobileFullDescPreviewComponent } from '../../../mobile-full-desc-preview/mobile-full-desc-preview.component';
import {
  IGeneralFullDescMobilePreviewComponentInputs,
  IGeneralFullDescMobilePreviewComponentOutputs,
} from '../../interfaces/igeneral-full-desc-mobile-preview-component';

export class GeneralFullDescMobilePreviewComponentFactory
  implements IServiceExtFullDescMobilePreviewComponentFactory
{
  getComponent(): Type<MobileFullDescPreviewComponent> {
    return MobileFullDescPreviewComponent;
  }

  getInputs(
    allInputs: IFullDescMobilePreviewComponentInputs,
  ): IGeneralFullDescMobilePreviewComponentInputs {
    return {
      addFullDesc: allInputs.addFullDesc,
      title: allInputs.title,
      fullDesc: allInputs.fullDesc,
      cost: allInputs.cost,
      coverPreview: allInputs.coverPreview,
    };
  }

  getOutputs(
    allOutputs: IFullDescMobilePreviewComponentOutputs,
  ): IGeneralFullDescMobilePreviewComponentOutputs {
    return {};
  }
}
