import { Type } from '@angular/core';
import { IServiceExtFormComponentFactory } from './iservice-ext-form-component-factory';
import { ServiceExtGeneralFormComponentComponent } from '../../../service-ext-general-form-component/service-ext-general-form-component.component';
import {
  IGeneralFormComponentInputs,
  IGeneralFormComponentOutputs,
} from '../../interfaces/igeneral-form-component';
import { IFormComponentInputs, IFormComponentOutputs } from '../../interfaces/iform-component';
import { FormControl } from '@angular/forms';
import { ServiceExtension } from '../../../../../domain/entities/service-extension';

export class GeneralFormComponentFactory implements IServiceExtFormComponentFactory {
  getComponent(): Type<ServiceExtGeneralFormComponentComponent> {
    return ServiceExtGeneralFormComponentComponent;
  }

  getInputs(allInputs: IFormComponentInputs): IGeneralFormComponentInputs {
    allInputs.form.addControl('banner', new FormControl(undefined));

    return {
      form: allInputs.form,
    };
  }

  getOutputs(allOutputs: IFormComponentOutputs): IGeneralFormComponentOutputs {
    return {
      outputBannerPreview: allOutputs.outputBannerPreview,
      outputCroppedBanner: allOutputs.outputCroppedBanner,
    };
  }
}
