import { Injectable } from '@angular/core';
import { ServiceExtensionType } from '../../../../domain/entities/service-type';
import { IServiceExtFullDescFormComponentFactory } from './service-ext-full-desc-form-component-factories/iservice-ext-full-desc-component-factory';
import { GeneralFullDescFormComponentFactory } from './service-ext-full-desc-form-component-factories/general-full-desc-form-component-factory';

const ServiceExtFullDescComponent = {
  [ServiceExtensionType.GENERAL]: GeneralFullDescFormComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_TV_PACKET]: null,
  [ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA]: null,
  [ServiceExtensionType.CATV_PACKET]: null,
};

@Injectable({ providedIn: 'root' })
export class ServiceExtFullDescComponentFactoryAggregate {
  getFactory(serviceType: ServiceExtensionType): IServiceExtFullDescFormComponentFactory | null {
    try {
      return new ServiceExtFullDescComponent[serviceType]!();
    } catch {
      return null;
    }
  }
}
