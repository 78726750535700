import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { ImageCropperComponent } from 'ngx-image-cropper';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { DragAndDropFileUploadComponent } from '../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { MobileAbonentServicesDetailPreviewComponent } from '../../components/mobile-abonent-services-detail-preview/mobile-abonent-services-detail-preview.component';
import { UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AbonentServicesFormShortCardComponent } from '../abonent-services-form-short-card/abonent-services-form-short-card';
import { AbonentServicesFormFullCardComponent } from '../abonent-services-form-full-card/abonent-services-form-full-card';

@Component({
  selector: 'app-abonent-services-form',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    ImageCropperComponent,
    MobilePreviewComponent,
    DragAndDropFileUploadComponent,
    MobileAbonentServicesDetailPreviewComponent,
    AbonentServicesFormShortCardComponent,
    AbonentServicesFormFullCardComponent,
  ],
  templateUrl: './abonent-services-form.html',
  styleUrl: './abonent-services-form.css',
})
export class AbonentServicesFormComponent implements OnInit {
  @Input() chosenType: string = 'web_app';
  @Input() title: string = '';
  @Input() imagePreview: string = '';

  @Output() outputCroppedImage: EventEmitter<Blob> = new EventEmitter();
  @Output() outputCroppedIcon: EventEmitter<Blob> = new EventEmitter();
  @Output() abonentServiceFormData: EventEmitter<FormGroup> = new EventEmitter();

  @Input() submitButtonText: string = '';
  @Input() abonentServiceForm!: FormGroup;

  full_description: string[] = [];

  constructor(public router: Router) {}

  ngOnInit(): void {}

  onSubmit() {
    this.abonentServiceFormData.emit(this.abonentServiceForm);
  }

  changeTitle($event: string): void {
    this.title = $event;
  }

  changeFullDescription($event: string[]): void {
    this.full_description = $event;
  }

  onOutoutCroppedIcon($event: Blob) {
    this.outputCroppedIcon.emit($event);
  }

  onOutputCroppedImage($event: Blob) {
    this.outputCroppedImage.emit($event);
  }

  onOutputImagePreview($event: string) {
    this.imagePreview = $event;
  }

  protected readonly UIStateType = UIStateType;
}
