import { ConnectionRequest } from '../../domain/connection-request';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { ConnectionRequestMapper } from '../mappers/connetion-request-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { ConnectionRequestApiDto } from '../connection-request-api-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';

@Injectable({ providedIn: 'root' })
export class ConnectionRequestRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private connectionRequestMapper: ConnectionRequestMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<
    ConnectionRequestApiDto,
    ConnectionRequest
  > = new PaginationResponseMapper(this.connectionRequestMapper);

  async findAll(): Promise<DataStatus<ConnectionRequest[]>> {
    let response: DataStatus<ConnectionRequestApiDto[]> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/connection_requests`,
    );

    return response.map((data) => {
      return data.map((item) => this.connectionRequestMapper.toDomain(item));
    });
  }

  async findAllPost(
    per_page: number,
    page_number: number,
  ): Promise<DataStatus<PaginationResponse<ConnectionRequest>>> {
    var payload = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
    };

    let response: DataStatus<PaginationResponseApiDto<ConnectionRequestApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/connection_requests`,
        payload,
      );

    let successResponse = response as DataStatusSuccess<
      PaginationResponseApiDto<ConnectionRequestApiDto>
    >;
    return successResponse.map((data) => {
      return this.paginationResponseMapper.toDomain(successResponse.data);
    });
  }
}
