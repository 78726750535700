export enum AvailableServiceTypeEnum {
  cctv = 'Видеонаблюдение',
  smart_intercom = 'Домофон 2.0',
  internet = 'Интернет',
  intercom = 'НЕО',
  security = 'Охрана',
  voip = 'Телефония',
  parking = 'Умные парковки',
  gate = 'Умный шлагбаум',
  daily = 'Ежедневная',
  monthly = 'Ежемесячная',
  common = 'Общего типа',
  access = 'Предоставление доступа',
  once = 'Разовая',
  installment = 'Рассрочка',
  rent = 'Аренда',
  itv = 'ИТВ',
  catv = 'КТВ',
}

export const BillingServiceTypeEnum: { [id: number]: string } = {
  0: 'common',
  1: 'once',
  2: 'daily',
  3: 'internet',
  4: 'catv',
  5: 'voip',
  6: 'catv',
  7: 'monthly',
  9: 'security',
  10: 'installment',
  11: 'itv',
  12: 'intercom',
  13: 'itv',
  14: 'rent',
  15: 'smart_intercom',
  16: 'cctv',
  20: 'access',
  21: 'parking',
  22: 'gate',
};

export class AvailableService {
  constructor(
    public id: number,
    public billingServiceId: number,
    public name: string,
    public description: string,
    public serviceType: string,
  ) {}
}
