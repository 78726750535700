import { Injectable } from '@angular/core';
import { ServiceDef } from '../../domain/entities/service-def';
import { ServiceDefApiDto } from '../dto/service-def-api-dto';

@Injectable({ providedIn: 'root' })
export class ServiceDefMapper {
  toDomain(dto: ServiceDefApiDto): ServiceDef {
    return new ServiceDef(
      dto.id,
      dto.service_id,
      dto.name,
      dto.service_type,
      dto.cost,
      dto.is_old,
      dto.is_deleted,
      dto.description,
      dto.params,
    );
  }
}
