import { AvailableService } from '../../domain/available-service';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { AvailableServiceMapper } from '../mappers/available-service-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { AvailableServiceApiDto } from '../available-service-api-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { PaginationResponseMapper } from '../../../../../entity/pagination-response/data/mappers/pagination-response-mapper';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationResponseApiDto } from '../../../../../entity/pagination-response/data/pagination-response-api-dto';

@Injectable({ providedIn: 'root' })
export class AvailableServiceRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private availableServiceMapper: AvailableServiceMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  private paginationResponseMapper: PaginationResponseMapper<
    AvailableServiceApiDto,
    AvailableService
  > = new PaginationResponseMapper(this.availableServiceMapper);

  async findAllPost(
    per_page: number,
    page_number: number,
    name: string | null = null,
  ): Promise<DataStatus<PaginationResponse<AvailableService>>> {
    var payload: { [key: string]: any } = {
      'pagination': {
        'per_page': per_page,
        'page_number': page_number,
      },
      'filters': [{ 'column': 'deleted_at', 'operator': 'eq', 'value': null }],
    };

    if (name) {
      payload['filters'].push({ 'column': 'name', 'operator': 'ilike', 'value': `%${name}%` });
    }

    let response: DataStatus<PaginationResponseApiDto<AvailableServiceApiDto>> =
      await this.httpService.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services`,
        payload,
      );

    let successResponse = response as DataStatusSuccess<
      PaginationResponseApiDto<AvailableServiceApiDto>
    >;
    return successResponse.map((data) => {
      return this.paginationResponseMapper.toDomain(successResponse.data);
    });
  }

  async create(new_service: any): Promise<DataStatus<AvailableService>> {
    let response: DataStatus<AvailableServiceApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services`,
      new_service,
    );

    let successResponse = response as DataStatusSuccess<AvailableServiceApiDto>;
    return successResponse.map((data) => {
      return this.availableServiceMapper.toDomain(successResponse.data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services/${id}`,
    );
  }

  async update(updated_service: any, service_id: number): Promise<DataStatus<AvailableService>> {
    let response: DataStatus<AvailableServiceApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/available_services/${service_id}`,
      updated_service,
    );

    let successResponse = response as DataStatusSuccess<AvailableServiceApiDto>;
    return successResponse.map((data) => {
      return this.availableServiceMapper.toDomain(successResponse.data);
    });
  }
}
