<app-mobile-preview>
  <div class="online-cinema-container">
    <div class="preview-header">
      <div class="preview-header-content">
        <div class="title">Услуги интерактивного ТВ</div>
        <app-preview-box
          width="100%"
          height="64px"
          [cornerRadius]="12"
          style="margin-bottom: 12px"
        ></app-preview-box>
        <app-preview-box width="100%" height="35px" [cornerRadius]="6"></app-preview-box>
      </div>
    </div>
    <div class="preview-body">
      <div class="banner" style="background-image: url(&quot;{{ bannerPreview }}&quot;)">
        <div *ngIf="!bannerPreview" class="banner-preview"></div>
      </div>
      <div class="online-cinema-item">
        <div class="online-cinema-item-body">
          <div class="icon" style="background-image: url(&quot;{{ iconPreview }}&quot;)">
            <div *ngIf="!iconPreview" class="icon-preview"></div>
          </div>
          <span *ngIf="title == undefined" class="online-cinema-item-title-placeholder"
            >Название онлайн-кинотеатра</span
          >
          <span *ngIf="title != undefined" class="online-cinema-item-title">{{ title }}</span>

          <div
            *ngIf="desc == undefined"
            class="online-cinema-item-secondary"
            style="margin-top: 8px"
          >
            Описание онлайн-кинотеатра
          </div>
          <div
            *ngIf="desc != undefined"
            class="online-cinema-item-secondary"
            style="margin-top: 8px"
          >
            {{ desc }}
          </div>
        </div>
        <hr />
        <div class="online-cinema-item-footer">
          <div class="row" style="display: flex; align-items: center">
            <div *ngIf="cost != undefined" class="col-6 cost">
              {{ cost }}<span class="cost-dot">.</span
              ><span class="cost-zeros">{{ fracPart }}</span> ₽/ мес.
            </div>
            <div *ngIf="cost == undefined" class="col-6 cost-placeholder">Стоимость</div>
            <div class="col-6">
              <div style="float: right">
                <input class="tgl tgl-flat" id="cb4" type="checkbox" />
                <label class="tgl-btn" for="cb4"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-preview-box
        width="100%"
        height="291px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
      <app-preview-box
        width="100%"
        height="291px"
        [cornerRadius]="12"
        style="margin-bottom: 12px"
      ></app-preview-box>
    </div>
  </div>
</app-mobile-preview>
