export class MacroServiceBase {
  constructor(
    public id: number,
    public type: string,
    public group: string | null,
  ) {}
}

export class MacroService {
  constructor(public macroServiceInfo: MacroServiceBase) {}
}

export class TariffInfo {
  constructor(
    public id: number,
    public tariffId: number,
    public name: string,
    public description: string | null,
    public cost: number,
    public speedDayIn: number,
    public speedNightIn: number,
  ) {}
}

export class BillingTariff {
  constructor(
    public tariffInfo: TariffInfo,
    public macroServices: MacroService[],
  ) {}
}
