import { AvailableService } from '../../domain/available-service';
import { AvailableServiceApiDto } from '../available-service-api-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AvailableServiceMapper {
  constructor() {}

  public toDomain(dto: AvailableServiceApiDto): AvailableService {
    return new AvailableService(
      dto.id,
      dto.billing_service_id,
      dto.name,
      dto.description,
      dto.service_type,
    );
  }
}
