import { Injectable } from '@angular/core';
import { OfficeBusinessHoursApiDto } from '../orion-office-business-hours-dto';
import { OfficeBusinessHours } from '../../domain/orion-office-business-hours';

@Injectable({ providedIn: 'root' })
export class OfficeBusinessHoursMapper {
  constructor() {}

  public toDomain(dto: OfficeBusinessHoursApiDto): OfficeBusinessHours {
    return new OfficeBusinessHours(
      dto.id,
      dto.office_id,
      dto.weekday,
      dto.open_time,
      dto.close_time,
      dto.is_day_off,
    );
  }
}
