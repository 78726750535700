<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <form
    id="update-banner-form"
    class="card"
    [formGroup]="createOfficeContactForm"
    (ngSubmit)="onCreateSubmit().finally()"
  >
    <div class="card-body">
      <div class="form-floating mb-3">
        <input
          id="update-phone"
          class="form-control"
          type="text"
          placeholder="Номер телефона"
          formControlName="phone"
          required
        />
        <label for="floatingInput">Номер телефона</label>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button
          type="button"
          class="btn me-auto"
          (click)="router.navigate(['/orion-offices/detail'], { state: currentOffice })"
        >
          Назад
        </button>
        <button
          id="submit-update-form-button"
          type="submit"
          class="btn btn-primary"
          [disabled]="!createOfficeContactForm.valid"
        >
          Создать
        </button>
      </div>
    </div>
  </form>
</app-page-wrapper>
