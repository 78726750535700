<app-mobile-preview>
  <div class="story-container" style="background-image: url(&quot;{{ slide }}&quot;)">
    <div class="header-and-body">
      <div class="content">
        <h2 class="header">{{ header }}</h2>
        <div class="body">{{ body }}</div>

        <div *ngFor="let button of actionButtons" class="btn-container">
          <button class="btn action-btn">{{ button['buttonName'] }}</button>
        </div>
      </div>
    </div>
  </div>
</app-mobile-preview>

<!--<div class="story-container">-->
<!--  <img class="slide" [src]="slide" />-->
<!--  <div class="container-fluid header-and-body">-->
<!--    <h2 class="header">{{ header }}</h2>-->

<!--    <div class="body">{{ body }}</div>-->

<!--    <br />-->

<!--    <div *ngFor="let button of actionButtons">-->
<!--      <button class="btn action-btn">{{ button['buttonName'] }}</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
