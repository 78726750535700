import { Type } from '@angular/core';
import { IServiceExtMobilePreviewComponentFactory } from './iservice-ext-mobile-preview-component-factory';
import {
  IMobilePreviewComponentInputs,
  IMobilePreviewComponentOutputs,
} from '../../interfaces/imoblie-preview-component';
import { MobileSmotreshkaPacketPreviewComponent } from '../../../mobile-smotreshka-packet-preview/mobile-smotreshka-packet-preview.component';
import {
  ISmotreshkaTVPacketMobilePreviewComponentInputs,
  ISmotreshkaTVPacketMobilePreviewComponentOutputs,
} from '../../interfaces/ismotreshka-tv-packet-mobile-preview-component';
import { FormControl } from '@angular/forms';

export class SmotreshkaTVPacketMobilePreviewComponentFactory
  implements IServiceExtMobilePreviewComponentFactory
{
  getComponent(): Type<MobileSmotreshkaPacketPreviewComponent> {
    return MobileSmotreshkaPacketPreviewComponent;
  }

  getInputs(
    allInputs: IMobilePreviewComponentInputs,
  ): ISmotreshkaTVPacketMobilePreviewComponentInputs {
    return {
      cost: allInputs.cost,
      title: allInputs.title,
      desc: allInputs.desc,
      iconPreview: allInputs.iconPreview,
      packId: allInputs.packId,
    };
  }

  getOutputs(
    allOutputs: IMobilePreviewComponentOutputs,
  ): ISmotreshkaTVPacketMobilePreviewComponentOutputs {
    return {
      outputNumberOfChannels: allOutputs.outputNumberOfChannels,
    };
  }
}
