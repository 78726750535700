<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Создать офис'" [routerLink]="['create']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <div class="col col-lg-2 mb-3">
    <label for="cityInput">Выберите город</label>
    <select class="form-select" id="cityInput" placeholder="Город" (change)="setOfficeCity($event)">
      <option disabled hidden value="" selected>---</option>
      <option *ngFor="let enum of keys(officeCityType)" [value]="[enum]">
        {{ getOfficeCityType(enum) }}
      </option>
    </select>
  </div>

  <app-loadable-wrapper [loadable]="orionOffices" loadingTitle="Загрузка ">
    <ng-template>
      <div class="row row-cards">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table">
              <thead>
                <tr>
                  <th *ngFor="let title of tableHeaders">{{ title }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orionOffices.data">
                  <td>{{ item.id }}</td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.latitude }}</td>
                  <td>{{ item.longitude }}</td>
                  <td>{{ getOfficeCityType(item.city) }}</td>
                  <td class="text-end">
                    <a [routerLink]="['detail']" [state]="item" class="btn"> Подробнее </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </app-loadable-wrapper>
</app-page-wrapper>
