<div [class]="'form-control-container'">
  <div *ngIf="searchString == ''" class="input-icon">
    <input
      class="form-control"
      placeholder="Введите название услуги из биллинга"
      [ngModel]="searchString"
      (ngModelChange)="queryChange$.next($event)"
      (focusout)="onFocusOut()"
      (focus)="onFocus()"
    />
    <span class="input-icon-addon">
      <i *ngIf="services?.status != UIStateType.Loading; else loading" class="ti ti-search"></i>
      <ng-template #loading>
        <div class="spinner-border spinner-border-sm text-secondary" role="status"></div>
      </ng-template>
    </span>
  </div>

  <div *ngIf="searchString != ''" class="input-icon">
    <div class="input-group input-group-flat">
      <input class="form-control" [ngModel]="searchString" disabled />
      <span class="input-group-text">
        <button type="button" class="clear-search-btn" (click)="clearSearchInput()">
          <i class="ti ti-x"></i>
        </button>
      </span>
    </div>
  </div>

  <div
    *ngIf="services && services.status == UIStateType.Success && services.data"
    class="array-result"
  >
    <app-autocomplete-search-service-def-input-result
      [foundEntities]="services.data.data"
      (selectValue)="onSelectValue($event)"
    ></app-autocomplete-search-service-def-input-result>
  </div>
</div>
