import { Banner } from '../../domain/banner';
import { BannerApiDto } from '../banner-api-dto';
import { Injectable } from '@angular/core';
import { DeeplinkMapper } from '../../../../../entity/deeplinks/data/mappers/deeplink-mapper';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';

@Injectable({ providedIn: 'root' })
export class BannerMapper {
  constructor(
    private attachmentMapper: AttachmentMapper,
    private deeplinkMapper: DeeplinkMapper,
  ) {}

  public toDomain(dto: BannerApiDto): Banner {
    return new Banner(
      dto.id,
      dto.image_id,
      dto.deeplink_id,
      dto.image !== null ? this.attachmentMapper.toDomain(dto.image) : null,
      dto.deeplink !== null ? this.deeplinkMapper.toDomain(dto.deeplink) : null,
    );
  }
}
