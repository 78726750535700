import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { StorySlideMapper } from '../mappers/story-slide';
import { APP_CONFIG } from '../../../../../environments/environment';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { StorySlide } from '../../domain/story';
import { StorySlideApiDto } from '../stories-dto';

@Injectable({ providedIn: 'root' })
export class StorySlideRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private storySlideMapper: StorySlideMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async create(body: any): Promise<DataStatus<StorySlide>> {
    let response: DataStatus<StorySlideApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/story_slides`,
      body,
    );

    let successResponse = response as DataStatusSuccess<StorySlideApiDto>;
    return successResponse.map((data) => {
      return this.storySlideMapper.toDomain(successResponse.data);
    });
  }

  async update(body: any, slideId: number): Promise<DataStatus<StorySlide>> {
    let response: DataStatus<StorySlideApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/story_slides/${slideId}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<StorySlideApiDto>;
    return successResponse.map((data) => {
      return this.storySlideMapper.toDomain(successResponse.data);
    });
  }
}
