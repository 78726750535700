import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { AbonentServiceRepository } from '../../data/repositories/AbonentServiceRepository';
import { AbonentService } from '../../domain/abonent-service';
import { AbonentServiceType } from '../../domain/abonent-service';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';
import { MobileAbonentServicesListComponent } from '../../components/mobile-abonent-services-list-preview/mobile-abonent-services-list-preview.component';

@Component({
  selector: 'app-abonent-services',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    BtnListComponent,
    AddButtonComponent,
    DragDropModule,
    MobilePreviewComponent,
    MobileAbonentServicesListComponent,
  ],
  templateUrl: './abonent-services.component.html',
  styleUrl: './abonent-services.component.css',
})
export class AbonentServicesComponent implements OnInit {
  pageTitle: string = 'Сервисы';
  pageSubtitle: string = 'Сервисы';
  public abonentServices: Loadable<AbonentService[]> = new Loadable<AbonentService[]>();
  private currentAbonentService: any = undefined;
  isEditButtonsActive: boolean = false;

  constructor(private AbonentServiceRepo: AbonentServiceRepository) {}

  async getAllAbonentServices(): Promise<void> {
    if (this.abonentServices.status != UIStateType.Loading) {
      this.abonentServices = new Loadable<AbonentService[]>({ kind: 'Loading' });
      await this.AbonentServiceRepo.findAll().then((data) => {
        this.abonentServices = Loadable.getFromDataStatus(data);
      });
    }
  }

  ngOnInit(): void {
    this.getAllAbonentServices().finally();
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.AbonentServiceRepo.delete(id);
      this.getAllAbonentServices().finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  setAbonentService(abonentService: AbonentService): void {
    this.currentAbonentService = abonentService;
  }

  getAbonentService(): AbonentService {
    return this.currentAbonentService;
  }

  getAbonentServiceType(serviceType: string): string {
    return AbonentServiceType[serviceType as keyof typeof AbonentServiceType];
  }

  activateEditButtons(): void {
    this.isEditButtonsActive = true ? this.isEditButtonsActive == false : false;
  }

  drop(event: CdkDragDrop<AbonentService[]>) {
    moveItemInArray(this.abonentServices.data, event.previousIndex, event.currentIndex);
  }

  saveOrdering() {
    for (let i = 0; i < this.abonentServices.data.length; i++) {
      this.AbonentServiceRepo.update({ 'ordering': i + 1 }, this.abonentServices.data[i].id);
    }
  }

  protected readonly UIStateType = UIStateType;
}
