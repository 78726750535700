import { IBannerRepository } from '../../domain/repositories/ibanner-repository';
import { Banner } from '../../domain/banner';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { BannerMapper } from '../mappers/banner-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { BannerApiDto } from '../banner-api-dto';
import { APP_CONFIG } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BannerRepository implements IBannerRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private bannerMapper: BannerMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(): Promise<DataStatus<Banner[]>> {
    let response: DataStatus<BannerApiDto[]> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners`,
    );

    return response.map((data) => {
      return data.map((item) => this.bannerMapper.toDomain(item));
    });
  }

  async create(deeplink_id: number, image_id: number): Promise<DataStatus<Banner>> {
    let body = {
      'deeplink_id': deeplink_id,
      'image_id': image_id,
    };

    let response: DataStatus<BannerApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners`,
      body,
    );

    let successResponse = response as DataStatusSuccess<BannerApiDto>;
    return successResponse.map((data) => {
      return this.bannerMapper.toDomain(successResponse.data);
    });
  }

  async delete(id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners/${id}`,
    );
  }

  async update(image_id: number, banner_id: number): Promise<DataStatus<Banner>> {
    let body = {
      'image_id': image_id,
    };

    let response: DataStatus<BannerApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/banners/${banner_id}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<BannerApiDto>;
    return successResponse.map((data) => {
      return this.bannerMapper.toDomain(successResponse.data);
    });
  }
}
