import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/ienvironment';
import { OAuthAccessTokenLocal } from '../../localData/oauth-access-token-local';

@Injectable()
export class OAuthApplyTokenInterceptor implements HttpInterceptor {
  private config: IEnvironment;

  constructor(
    @Inject(APP_CONFIG) config: IEnvironment,
    private accessToken: OAuthAccessTokenLocal,
  ) {
    this.config = config;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(this.accessToken.accessToken);
    let token: string | null = this.accessToken.accessToken;
    req = req.clone({
      headers: token ? req.headers.set('Authorization', `Bearer ${token}`) : req.headers,
    });
    return next.handle(req);
  }
}
