import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityType, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { OrionOfficesContactsComponent } from '../../../orion-offices-contacts/components/orion-offices-contacts/orion-offices-contacts.component';
import { OrionOfficesBusinessHoursComponent } from '../../../orion-offices-business-hours/components/orion-offices-business-hours/orion-offices-business-hours.component';
import { LoadableWrapperComponent } from '../../../../../components/common/loadable-wrapper/loadable-wrapper.component';

@Component({
  selector: 'app-orion-offices-detail',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
    OrionOfficesContactsComponent,
    OrionOfficesBusinessHoursComponent,
    LoadableWrapperComponent,
  ],
  templateUrl: './orion-offices-detail.component.html',
  styleUrl: './orion-offices-detail.component.css',
})
export class OrionOfficesDetailComponent implements OnInit {
  pageTitle: string = 'Офисы';
  pageSubtitle: string = 'Офисы';
  public orionOffice: Loadable<OrionOffice> = new Loadable<OrionOffice>({ kind: 'NotRequested' });
  public tableHeaders: Array<string> = ['ID', 'Адрес', 'Широта', 'Долгота', 'Город'];
  public officeCityType = OfficeCityType;
  public keys = Object.keys;
  private currOfficeId: number = 0;

  constructor(
    private OrionOfficeRepo: OrionOfficeRepository,
    private router: Router,
  ) {}

  ngOnInit(): void {
    var office_id = history.state.id;
    this.currOfficeId = office_id;
    this.getOrionOffice(office_id).finally();
  }

  async getOrionOffice(office_id: number) {
    if (this.orionOffice.status != UIStateType.Loading) {
      this.orionOffice = new Loadable<OrionOffice>({ kind: 'Loading' });
      await this.OrionOfficeRepo.getOfficeById(office_id).then((data) => {
        this.orionOffice = Loadable.getFromDataStatus(data);
      });
    }
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  async deleteOffice(office_id: number) {
    await this.OrionOfficeRepo.delete(office_id);
    this.router.navigate(['orion-offices']);
  }

  protected readonly UIStateType = UIStateType;
}
