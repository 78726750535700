import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_CONFIG, environment } from './environments/environment';
import { OAuthApplyTokenInterceptor } from './core/network/interceptors/oauth.token.interceptor';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { provideEnvironmentNgxMask } from 'ngx-mask';

const mapConfig: YaConfig = {
  apikey: '6714c75e-1e1e-40e1-bebf-4c1fc9d0f481',
  lang: 'ru_RU',
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    [
      { provide: APP_CONFIG, useValue: environment },
      { provide: HTTP_INTERCEPTORS, useClass: OAuthApplyTokenInterceptor, multi: true },
    ],
    importProvidersFrom(AngularYandexMapsModule.forRoot(mapConfig)),
    provideEnvironmentNgxMask(),
  ],
};
