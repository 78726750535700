<div class="container-fluid align-content-center">
  <div
    class="preview-container"
    style="background-image: url('assets/dist/img/phone-preview/body.png')"
  >
    <div class="preview-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
