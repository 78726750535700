import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MobilePreviewComponent } from '../../../../components/common/mobile-preview/mobile-preview.component';

@Component({
  selector: 'app-mobile-abonent-services-list',
  standalone: true,
  imports: [NgFor, MobilePreviewComponent],
  templateUrl: './mobile-abonent-services-list-preview.component.html',
  styleUrl: './mobile-abonent-services-list-preview.component.css',
})
export class MobileAbonentServicesListComponent {
  @Input() public title: string = '';
  @Input() public iterable: any[] = [];
}
