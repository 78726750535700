import { Attachment } from '../../../../../entity/attachments/domain/attachment';

export enum SmotreshkaCinemaType {
  start = 'START',
  amediateka = 'Амедиатека',
  premier = 'PREMIER',
}

export enum ServiceExtTypeEnum {
  general = 'Прочие услуги',
  smotreshka_online_cinema = 'Онлайн-кинотеатр ИТВ',
  smotreshka_tv_packet = 'Пакет каналов ИТВ',
  catv_packet = 'Пакет каналов КТВ',
}

export class ServiceExtension {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public serviceId: number,
    public service_type: string,
    public icon: Attachment | null,
    public banner: Attachment | null,
    public cover: Attachment | null,
    public attributes: { [id: string]: any } | null,
  ) {}
}
