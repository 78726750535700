import { Component, OnInit } from '@angular/core';
import { Loadable, UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityType, OrionOffice } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { LoadableWrapperComponent } from '../../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { AddButtonComponent } from '../../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../../components/common/buttons/btn-list/btn-list.component';

@Component({
  selector: 'app-orion-offices',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    RouterModule,
    LoadableWrapperComponent,
    AddButtonComponent,
    BtnListComponent,
  ],
  templateUrl: './orion-offices.component.html',
  styleUrl: './orion-offices.component.css',
})
export class OrionOfficesComponent implements OnInit {
  pageTitle: string = 'Офисы';
  pageSubtitle: string = 'Офисы';
  public orionOffices: Loadable<OrionOffice[]> = new Loadable<OrionOffice[]>({
    kind: 'NotRequested',
  });
  public tableHeaders: Array<string> = ['ID', 'Адрес', 'Широта', 'Долгота', 'Город'];
  public officeCityType = OfficeCityType;
  public keys = Object.keys;
  private officeCity: string | null = null;

  constructor(
    private OrionOfficeRepo: OrionOfficeRepository,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getOrionOffices(this.officeCity).finally();
  }

  async getOrionOffices(city: string | null) {
    if (this.orionOffices.status != UIStateType.Loading && this.officeCity != '') {
      this.orionOffices = new Loadable<OrionOffice[]>({ kind: 'Loading' });
      await this.OrionOfficeRepo.findAllByCity(city).then((data) => {
        this.orionOffices = Loadable.getFromDataStatus(data);
      });
    }
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  setOfficeCity($event: any) {
    this.officeCity = $event.target.value;
    this.getOrionOffices(this.officeCity).finally();
  }

  protected readonly UIStateType = UIStateType;
}
