<form
  [formGroup]="bannerForm"
  (ngSubmit)="onSubmit()"
  onsubmit="this.submit_button.disabled = true;"
>
  <div class="row">
    <div class="col-md-6">
      <app-form-component [title]="'Добавление баннера'">
        <div class="mb-3">
          <label class="form-label required">Название</label>
          <input
            class="form-control"
            formControlName="title"
            type="text"
            placeholder="Введите название баннера"
          />
        </div>

        <div class="mb-3">
          <label class="form-label"
            >Описание <span class="optional-label">(опционально)</span></label
          >
          <textarea
            class="form-control"
            formControlName="description"
            placeholder="Введите описание описание баннера"
          ></textarea>
        </div>

        <div class="mb-3">
          <div class="card">
            <div class="card-body">
              <label class="form-label required" for="form-story-file"
                >Загрузите изображение баннера</label
              >
              <drag-and-drop-file-upload
                *ngIf="imageChangedEvent === null"
                formControlName="image"
                (onFileSelect)="fileChangeEvent($event)"
                required
              ></drag-and-drop-file-upload>
              <div class="image-cropper-container" *ngIf="imageChangedEvent !== null">
                <button class="btn btn-danger remove-icon-btn" (click)="removeImage()">
                  <i class="ti ti-trash"></i>
                </button>
                <image-cropper
                  class="image-cropper"
                  [aspectRatio]="328 / 123"
                  [imageChangedEvent]="imageChangedEvent"
                  (imageCropped)="imageCropped($event)"
                  format="jpeg"
                  [maintainAspectRatio]="true"
                ></image-cropper>
              </div>
            </div>

            <div class="mt-3 information_doc card-footer">
              <div class="row">
                <div class="file-req-header">Ограничение для фото</div>
                <div class="file-req-text">Минимальный размер - 328 x 123</div>
                <div class="file-req-text">Рекомендуемый размер - 984 х 369</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Кнопка <span class="optional-label">(опционально)</span></label>
          <button
            *ngIf="!actionButtonInput"
            type="button"
            class="btn btn-link"
            (click)="addActionButtonInput()"
          >
            <i class="ti ti-plus"></i> Добавить кнопку на баннер
          </button>
          <div *ngIf="actionButtonInput">
            <div class="row">
              <div class="col-auto">
                <button type="button" class="btn action-btn">
                  {{ bannerForm.value["actionButton"] ? bannerForm.value["actionButton"] : 'Текст
                  кнопки' }}
                </button>
              </div>

              <div class="col-md-6">
                <input
                  class="form-control"
                  formControlName="actionButton"
                  placeholder="Текст кнопки"
                />
              </div>

              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-link preview-btn"
                  (click)="removeActionButton()"
                >
                  <i class="ti ti-x"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label required">Ссылка</label>
          <input
            class="form-control"
            formControlName="link"
            type="text"
            placeholder="Введите ссылку, на которую будет перенаправлять баннер из приложения"
          />
        </div>
      </app-form-component>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр баннера в приложении</h3>
        </div>
        <div class="card-body">
          <app-mobile-banner-preview
            [banner]="preview"
            [actionButtonText]="bannerForm.value['actionButton'] ? bannerForm.value['actionButton'] : ''"
          ></app-mobile-banner-preview>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/banners'])" class="btn btn-link"> Отмена </a>
            <button type="submit" class="btn btn-primary" name="submit_button">
              {{ submitButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
