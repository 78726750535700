import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorage {
  public setItem<T>(key: string, value: T) {
    if (typeof value !== 'string') {
      let jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } else {
      localStorage.setItem(key, value);
    }
  }

  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  public clear() {
    return localStorage.clear();
  }
}
