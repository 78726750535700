<div class="card">
  <ng-content [ngTemplateOutlet]="dataTemplate"></ng-content>
</div>
<div class="d-flex mt-3">
  <ul class="pagination ms-auto">
    <li class="page-item" [class.disabled]="currentPageNumber === 1">
      <button
        (click)="changePage(currentPageNumber - 1)"
        class="page-link"
        style="white-space: nowrap"
      >
        <i class="ti ti-chevron-left"></i>
      </button>
    </li>
    <li class="page-item" *ngFor="let x of createPageArray(data.data.pages)">
      <button [class.active]="x === currentPageNumber" (click)="changePage(x)" class="page-link">
        {{ x }}
      </button>
    </li>
    <li class="page-item" [class.disabled]="currentPageNumber === data.data.pages">
      <button
        class="page-link"
        (click)="changePage(currentPageNumber + 1)"
        style="white-space: nowrap"
      >
        <i class="ti ti-chevron-right"></i>
      </button>
    </li>
  </ul>
</div>
