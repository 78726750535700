import { Component } from '@angular/core';
import { PageHeaderComponent } from '../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../components/common/page-wrapper/page-wrapper.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [PageHeaderComponent, PageWrapperComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.css',
})
export class HomePageComponent {
  pageTitle: string = 'Главная';
  pageSubtitle: string = 'Статистика по модулям приложения';
}
