import { Component } from '@angular/core';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OrionOfficeBusinessHoursRepository } from '../../data/repositories/OrionOfficeBusinessHoursRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { WeekDaysEnum } from '../../domain/orion-office-business-hours';

@Component({
  selector: 'app-orion-offices-business-hours-create',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-business-hours-create.component.html',
  styleUrl: './orion-offices-business-hours-create.component.css',
})
export class OrionOfficesBusinessHoursCreateComponent {
  pageTitle: string = 'Создать время для офиса';
  pageSubtitle: string = 'Офисы';
  public currentOffice: any = undefined;
  public isSubmitDisabled: boolean = true;
  weekDayEnum = WeekDaysEnum;
  keys = Object.keys;

  public createOfficeHoursForm = this.formBuilder.record({
    open_time: undefined,
    close_time: undefined,
    is_day_off: false,
    office_id: undefined,
    weekday: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficeRepo: OrionOfficeBusinessHoursRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentOffice = history.state['office'];
    this.createOfficeHoursForm.patchValue({
      'office_id': this.currentOffice.id,
    });

    this.createOfficeHoursForm.get('is_day_off')?.valueChanges.subscribe((value) => {
      if (value) {
        this.createOfficeHoursForm.get('open_time')?.disable();
        this.createOfficeHoursForm.get('close_time')?.disable();
      } else {
        this.createOfficeHoursForm.get('open_time')?.enable();
        this.createOfficeHoursForm.get('close_time')?.enable();
      }
    });
  }

  async onCreateSubmit(): Promise<void> {
    if (this.createOfficeHoursForm.value['is_day_off']) {
      this.createOfficeHoursForm.value['open_time'] = null;
      this.createOfficeHoursForm.value['close_time'] = null;
    }

    await this.OrionOfficeRepo.create(this.createOfficeHoursForm.value);

    this.router.navigate(['/orion-offices/detail'], { state: this.currentOffice });
  }

  getWeekDay(weekday: string): string {
    return WeekDaysEnum[weekday as keyof typeof WeekDaysEnum];
  }

  protected readonly UIStateType = UIStateType;
}
