import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'dateOutput',
})
export class DateOutputPipe implements PipeTransform {
  transform(value: number): string {
    return new Date(value).toLocaleDateString('ru-RU');
  }
}
