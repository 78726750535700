<div class="page">
  <!-- Sidebar -->
  <aside class="navbar navbar-vertical navbar-expand-lg navbar-transparent">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebar-menu"
        aria-controls="sidebar-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark">
        <a href=".">
          <img
            src="assets/static/logo.svg"
            width="110"
            height="32"
            alt="Tabler"
            class="navbar-brand-image"
          />
        </a>
      </h1>
      <div class="collapse navbar-collapse" id="sidebar-menu">
        <ul class="navbar-nav pt-lg-3">
          <app-nav-item
            title="Главная"
            [isDropdown]="false"
            iconName="home"
            routerLink="statistics"
          ></app-nav-item>

          <app-nav-item
            title="Истории"
            [isDropdown]="true"
            iconName="history-toggle"
            [includedRouterLinks]="['/stories', '/stories/create-story']"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список историй"
                routerLink="stories"
              ></app-dropdown-item>
              <app-dropdown-item
                dropdownTitle="Добавление истории"
                routerLink="stories/create-story"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Баннеры"
            [isDropdown]="false"
            routerLink="banners"
            iconName="chalkboard"
          ></app-nav-item>

          <app-nav-item
            title="Заявки на подключение"
            [isDropdown]="false"
            routerLink="connection-requests"
            iconName="ghost"
          ></app-nav-item>

          <app-nav-item
            title="Сервисы"
            [isDropdown]="true"
            iconName="layout-2"
            [includedRouterLinks]="[
              '/abonent-services',
              '/abonent-services/create-abonent-service',
            ]"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Список сервисов"
                routerLink="abonent-services"
              ></app-dropdown-item>
              <app-dropdown-item
                dropdownTitle="Добавление сервиса"
                routerLink="abonent-services/create-abonent-service"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Тарифы и услуги"
            iconName="checklist"
            [isDropdown]="true"
            iconName="checkup-list"
            [includedRouterLinks]="[
              '/service-ext-list',
              '/available-services',
              '/available-tariffs',
            ]"
          >
            <app-dropdown-menu>
              <app-dropdown-item
                dropdownTitle="Расширения услуг"
                routerLink="service-ext-list"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Доступные услуги"
                routerLink="available-services"
              ></app-dropdown-item>

              <app-dropdown-item
                dropdownTitle="Доступные тарифы"
                routerLink="available-tariffs"
              ></app-dropdown-item>
            </app-dropdown-menu>
          </app-nav-item>

          <app-nav-item
            title="Офисы"
            [isDropdown]="false"
            routerLink="orion-offices"
            iconName="building-skyscraper"
          ></app-nav-item>
        </ul>
      </div>
    </div>
  </aside>
  <div class="page-wrapper">
    <router-outlet></router-outlet>

    <app-footer></app-footer>
  </div>
</div>
