import { Component, OnInit } from '@angular/core';
import {
  AvailableService,
  AvailableServiceTypeEnum,
  BillingServiceTypeEnum,
} from '../../domain/available-service';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { AvailableServiceRepository } from '../../data/repositories/AvailableServiceRepository';
import { NgForOf, NgIf, NgFor } from '@angular/common';
import { PageHeaderComponent } from '../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../components/common/breadcrumbs/breadcrumbs.component';
import { LoadableWrapperComponent } from '../../../../components/common/loadable-wrapper/loadable-wrapper.component';
import { SpinnerImgComponent } from '../../../../components/common/spinner-img/spinner-img.component';
import { AddButtonComponent } from '../../../../components/common/buttons/add-button/add-button.component';
import { BtnListComponent } from '../../../../components/common/buttons/btn-list/btn-list.component';
import { RouterModule } from '@angular/router';
import { PaginationTableViewComponent } from '../../../../components/pagination-table-view/pagination-table-view.component';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { PaginationViewComponent } from '../../../../components/pagination-view/pagination-view.component';
import { AutocompleteSearchServiceDefInputComponent } from '../../../../components/common/inputs/autocomplete-search-service-def-input/autocomplete-search-input.component';
import { ServiceDef } from '../../../services/domain/entities/service-def';
import { ICreateAvailableService } from '../../data/interfaces/icreate-available-service';

@Component({
  selector: 'app-available-services',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgFor,
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    PaginationTableViewComponent,
    LoadableWrapperComponent,
    SpinnerImgComponent,
    AddButtonComponent,
    BtnListComponent,
    RouterModule,
    PaginationViewComponent,
    AutocompleteSearchServiceDefInputComponent,
  ],
  templateUrl: './available-services.component.html',
  styleUrl: './available-services.component.css',
})
export class AvailableServiceComponent implements OnInit {
  public pageAvailableServices: Loadable<PaginationResponse<AvailableService>> = new Loadable<
    PaginationResponse<AvailableService>
  >();
  pageTitle: string = 'Список доступных услуг';
  pageSubtitle: string = 'Тарифы и услуги';
  public perPage: number = 5;
  public pageNumber: number = 1;
  public currentPageNumber: number = 1;
  public availableServiceType = AvailableServiceTypeEnum;
  public keys = Object.keys;
  private new_service!: ICreateAvailableService;
  private searchName!: string;

  constructor(private AvailableServiceRepo: AvailableServiceRepository) {}

  ngOnInit(): void {
    this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
  }

  async getAllAvailableServicesPost(
    per_page: number,
    page_number: number,
    name: string | null = null,
  ): Promise<void> {
    if (this.pageAvailableServices.status != UIStateType.Loading) {
      this.pageAvailableServices = new Loadable<PaginationResponse<AvailableService>>({
        kind: 'Loading',
      });
      await this.AvailableServiceRepo.findAllPost(per_page, page_number, name ? name : null).then(
        (data) => {
          this.pageAvailableServices = Loadable.getFromDataStatus(data);
        },
      );
    }
  }

  changePage(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.currentPageNumber = pageNumber;
    if (this.searchName) {
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber, this.searchName).finally();
    } else {
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
    }
  }

  getServiceType(type: string) {
    return AvailableServiceTypeEnum[type as keyof typeof AvailableServiceTypeEnum];
  }

  async onDelete(id: number): Promise<void> {
    try {
      await this.AvailableServiceRepo.delete(id);
      this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
    } catch (error: any) {
      console.log(error);
    }
  }

  onFoundServiceDef($event: ServiceDef) {
    this.new_service = {
      billing_service_id: $event.serviceId,
      name: $event.name,
      description: $event.description,
      cost: $event.cost,
      service_type: BillingServiceTypeEnum[$event.serviceType],
    };
  }

  async createService() {
    await this.AvailableServiceRepo.create(this.new_service);
    this.getAllAvailableServicesPost(this.perPage, this.pageNumber).finally();
  }

  async searchAvailableService($event: any) {
    this.searchName = $event.target.value;

    if (this.searchName !== '') {
      await this.getAllAvailableServicesPost(this.perPage, 1, this.searchName);
    } else {
      await this.getAllAvailableServicesPost(this.perPage, this.pageNumber);
    }
  }

  protected readonly UIStateType = UIStateType;
}
