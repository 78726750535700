import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { IconsModule } from '../../../../icons/icons.module';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-nav-item',
  standalone: true,
  imports: [NgIf, IconsModule, RouterLink, RouterLinkActive],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.css',
})
export class NavItemComponent {
  //TODO: Надо переименовать пееременную какнибудь
  @Input() title: string = '';
  @Input() isDropdown: boolean = false;
  @Input() iconName?: string;
  @Input() routerLink?: string | any[];
  @Input() includedRouterLinks?: string[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  isActiveIncludedRoute(): boolean {
    if (this.includedRouterLinks != null && this.isDropdown) {
      for (let i = 0; i < this.includedRouterLinks.length; i++) {
        if (this.includedRouterLinks[i] == this.router.url) {
          return true;
        }
      }
    }
    return false;
  }
}
