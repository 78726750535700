export class ServiceDef {
  constructor(
    public id: number,
    public serviceId: number,
    public name: string,
    public serviceType: number,
    public cost: number,
    public isOld: boolean,
    public isDeleted: boolean,
    public description: string,
    public params: { [id: string]: any } | null,
  ) {}
}
