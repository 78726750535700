<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <form
    id="update-banner-form"
    class="card"
    [formGroup]="updateOfficeForm"
    (ngSubmit)="onUpdateSubmit().finally()"
  >
    <div class="card-body">
      <div class="form-floating mb-3">
        <input
          id="update-address"
          class="form-control"
          placeholder="Адрес"
          formControlName="address"
          (change)="checkIfOfficeChanged($event)"
        />
        <label for="floatingInput">Адрес</label>
      </div>
      <div class="form-floating mb-3">
        <input
          id="update-latitude"
          class="form-control"
          placeholder="Широта"
          formControlName="latitude"
          (change)="checkIfOfficeChanged($event)"
        />
        <label for="floatingInput">Широта</label>
      </div>
      <div class="form-floating mb-3">
        <input
          id="update-longitude"
          class="form-control"
          placeholder="Долгота"
          formControlName="longitude"
          (change)="checkIfOfficeChanged($event)"
        />
        <label for="floatingInput">Долгота</label>
      </div>
      <div class="form-floating mb-3">
        <select
          id="update-city"
          class="form-select"
          placeholder="Город"
          formControlName="city"
          (change)="checkIfOfficeChanged($event)"
        >
          <option *ngFor="let enum of keys(officeCityType)" [value]="[enum]">
            {{ getOfficeCityType(enum) }}
          </option>
        </select>
        <label>Город</label>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex">
        <button
          type="button"
          class="btn me-auto"
          (click)="router.navigate(['/orion-offices/detail'], { state: currentOffice })"
        >
          Назад
        </button>
        <button
          id="submit-update-form-button"
          type="submit"
          class="btn btn-primary"
          [disabled]="isSubmitDisabled"
        >
          Обновить
        </button>
      </div>
    </div>
  </form>
</app-page-wrapper>
