import { Type } from '@angular/core';
import { IServiceExtFormComponentFactory } from './iservice-ext-form-component-factory';
import { ServiceExtCATVPacketFormComponentComponent } from '../../../service-ext-catv-packet-form-component/service-ext-catv-packet-form-component.component';
import { IFormComponentInputs, IFormComponentOutputs } from '../../interfaces/iform-component';
import {
  ICATVFormComponentInputs,
  ICATVFormComponentOutputs,
} from '../../interfaces/icatv-form-component';
import { FormControl, Validators } from '@angular/forms';
import { ServiceExtension } from '../../../../../domain/entities/service-extension';

export class CATVPacketFormComponentFactory implements IServiceExtFormComponentFactory {
  getComponent(): Type<ServiceExtCATVPacketFormComponentComponent> {
    return ServiceExtCATVPacketFormComponentComponent;
  }

  getInputs(
    allInputs: IFormComponentInputs,
    serviceExt: ServiceExtension | null,
  ): ICATVFormComponentInputs {
    let numberOfChannels = serviceExt?.attributes
      ? serviceExt.attributes['number_of_channels']
      : undefined;
    allInputs.form.addControl('numberOfChannels', new FormControl(numberOfChannels));

    return {
      form: allInputs.form,
    };
  }

  getOutputs(allOutputs: IFormComponentOutputs): ICATVFormComponentOutputs {
    return {
      title: allOutputs.title,
      desc: allOutputs.desc,
    };
  }
}
