<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>
  <form
    [formGroup]="storyForm"
    (ngSubmit)="onUpdateSubmit().finally()"
    onsubmit="this.submit_button.disabled = true;"
  >
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">История</h3>
          </div>

          <div class="card-body">
            <div class="mb-3">
              <label class="form-label">Название истории <span style="color: red">*</span></label>
              <input class="form-control" placeholder="Введите название" formControlName="name" />
            </div>

            <div class="card mb-3">
              <div class="card-body">
                <label class="form-label"
                  >Загрузите историю <span style="color: red">*</span></label
                >
                <drag-and-drop-file-upload
                  *ngIf="slideChangedEvent == null"
                  formControlName="slide"
                  [acceptFormats]="'image/*, video/mp4'"
                  (onFileSelect)="slideFileChangeEvent($event)"
                ></drag-and-drop-file-upload>
                <div class="image-cropper-container" *ngIf="slideChangedEvent !== null">
                  <button class="btn btn-danger remove-icon-btn" (click)="removeSlide()">
                    <i class="ti ti-trash"></i>
                  </button>
                  <image-cropper
                    class="image-cropper"
                    [aspectRatio]="9 / 16"
                    [imageChangedEvent]="slideChangedEvent"
                    (imageCropped)="slideCropped($event)"
                    format="jpeg"
                    [maintainAspectRatio]="true"
                  ></image-cropper>
                </div>
              </div>

              <div class="mt-3 information_doc card-footer">
                <div class="row">
                  <div class="col-md-6">
                    <div class="file-req-header">Соотношение сторон</div>
                    <div class="file-req-text">
                      Рекомендуемое соотношение сторон для фото или видео - 9:16
                    </div>
                    <div class="file-req-text">
                      При загрузке фото или видео с другими пропорциями значительная часть
                      изображения может обрезаться
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="file-req-header">Ограничение для фото</div>
                    <div class="file-req-text">
                      Минимальный размер - 720 x 1280 Рекомендуемый размер - 1080 х 1920
                    </div>
                    <div class="file-req-header">Ограничение для видео</div>
                    <div class="file-req-text">
                      Длительность не более NN секунд; размер файла не более NN Мб
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col mb-3">
              <label class="form-label"
                >Заголовок <span style="color: gray">(опционально)</span></label
              >
              <input
                class="form-control"
                placeholder="Введите заголовок"
                formControlName="header"
                (input)="changeHeader($event)"
              />
            </div>

            <div class="col mb-3">
              <label class="form-label"
                >Описание истории <span style="color: gray">(опционально)</span></label
              >
              <textarea
                class="form-control"
                placeholder="Введите описание (текст будет отображаться в открытой истории)"
                formControlName="body"
                (input)="changeBody($event)"
              ></textarea>
            </div>

            <div class="col mt-3">
              <label class="form-label">
                Кнопки <span style="color: gray">(опционально)</span>
              </label>

              <div
                formArrayName="actionButtons"
                *ngFor="let item of actionButtonsData.controls; let i = index"
              >
                <div [formGroupName]="i" class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <button type="button" class="btn action-btn">
                          {{ actionButtons.value[i].buttonName }}
                        </button>
                      </div>

                      <div class="col">
                        <button
                          type="button"
                          class="btn btn-link preview-btn"
                          (click)="removeItem(i)"
                        >
                          <i class="ti ti-x"></i>
                        </button>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col">
                        <input
                          class="form-control"
                          formControlName="buttonName"
                          placeholder="Название кнопки"
                          (input)="changeButtonText($event)"
                        />
                      </div>
                      <div class="col">
                        <input
                          class="form-control"
                          formControlName="buttonLink"
                          placeholder="Ссылка"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-link" (click)="addItem()">
                <i class="ti ti-plus"></i> Добавить кнопку в историю
              </button>
            </div>
          </div>
        </div>

        <div class="card mt-3">
          <div class="card-header">
            <h3 class="card-title">Превью</h3>
          </div>
          <div class="card-body">
            <div class="card mb-3">
              <div class="card-body">
                <label class="form-label">Загрузите превью <span style="color: red">*</span></label>
                <drag-and-drop-file-upload
                  *ngIf="imageChangedEvent == null"
                  formControlName="preview"
                  (onFileSelect)="imageFileChangeEvent($event)"
                ></drag-and-drop-file-upload>

                <div class="image-cropper-container" *ngIf="imageChangedEvent !== null">
                  <button class="btn btn-danger remove-icon-btn" (click)="removeImage()">
                    <i class="ti ti-trash"></i>
                  </button>
                  <image-cropper
                    class="image-cropper"
                    *ngIf="imageChangedEvent !== null"
                    [aspectRatio]="1 / 1"
                    [imageChangedEvent]="imageChangedEvent"
                    (imageCropped)="imageCropped($event)"
                    format="jpeg"
                    [maintainAspectRatio]="true"
                  ></image-cropper>
                </div>
              </div>

              <div class="card-footer information_doc">
                <div class="row">
                  <div class="col-md-6">
                    <div class="file-req-header">Соотношение сторон</div>
                    <div class="file-req-text">
                      Рекомендуемое соотношение сторон для фото или видео - 1:1
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="file-req-header">Ограничение для фото</div>
                    <div class="file-req-text">
                      Минимальный размер - 80 х 80 Рекомендуемый размер - 240 х 240
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label" for="form-description-preview"
                >Описание превью <span style="color: gray">(опционально)</span></label
              >
              <textarea
                id="form-description-preview"
                class="form-control"
                placeholder="Введите описание (текст будет отображаться в открытой истории)"
                formControlName="annotation"
                (input)="changeAnnotation($event)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card sticky-top">
          <div class="card-header">
            <h3 class="card-title">Предпросмотр</h3>
          </div>
          <div class="card-body">
            <div class="card-tabs">
              <!-- Cards navigation -->
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    href="#tab-top-1"
                    class="nav-link active"
                    data-bs-toggle="tab"
                    aria-selected="true"
                    role="tab"
                    >История</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    href="#tab-top-2"
                    class="nav-link"
                    data-bs-toggle="tab"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                    >Превью</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <!-- Content of card #1 -->
                <div id="tab-top-1" class="card tab-pane active show" role="tabpanel">
                  <div class="card-body">
                    <app-mobile-stories-preview
                      [slide]="slidePreview"
                      [header]="header"
                      [body]="body"
                      [actionButtons]="actionButtons.value"
                    ></app-mobile-stories-preview>
                  </div>
                </div>
                <!-- Content of card #2 -->
                <div id="tab-top-2" class="card tab-pane" role="tabpanel">
                  <div class="card-body">
                    <app-mobile-story-preview-preview
                      [preview]="imagePreview"
                      [annotation]="annotation"
                    ></app-mobile-story-preview-preview>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-around">
              <a (click)="router.navigate(['/stories'])" class="btn btn-link"> Отмена </a>
              <button type="submit" class="btn btn-primary" name="submit_button">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-page-wrapper>
