<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle">
  <app-add-button [title]="'Создать баннер'" [routerLink]="['create-banner']"></app-add-button>
</app-page-header>

<app-page-wrapper>
  <app-breadcrumbs></app-breadcrumbs>

  <app-loadable-wrapper [loadable]="banners" loadingTitle="Загрузка данных...">
    <ng-template>
      <div class="row row-cards">
        <ng-container *ngFor="let item of banners.data">
          <div class="col-lg-3">
            <div class="card h-100">
              <!-- Photo -->
              <app-spinner-img
                [imgSrc]="item.image?.content?.attributes?.content_path"
              ></app-spinner-img>
              <div *ngIf="item.deeplink" class="card-body">
                <h3 class="card-title">{{ item.deeplink.title }}</h3>
                <p class="text-secondary">{{ item.deeplink.link }}</p>
                <p class="text-secondary">{{ item.deeplink.description }}</p>
              </div>
              <!-- Card footer -->
              <div class="card-footer">
                <div class="d-flex justify-content-around">
                  <button
                    (click)="setBanner(item)"
                    class="btn btn-link"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-delete-banner"
                  >
                    Удалить
                  </button>
                  <a [routerLink]="['update-banner']" [state]="item" class="btn btn-primary"
                    >Редактировать</a
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </app-loadable-wrapper>

  <div
    class="modal modal-blur fade"
    id="modal-delete-banner"
    tabindex="-1"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-title">Вы уверены?</div>
          <div>Вы хотите удалить этот баннер?</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">
            Нет
          </button>
          <button
            (click)="onDelete(getBanner().id)"
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Да
          </button>
        </div>
      </div>
    </div>
  </div>
</app-page-wrapper>
