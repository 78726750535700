import { Component } from '@angular/core';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OrionOfficesContactsRepository } from '../../data/repositories/OrionOfficesContactsRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-business-hours-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-contacts-update.component.html',
  styleUrl: './orion-offices-contacts-update.component.css',
})
export class OrionOfficesContactsUpdateComponent {
  pageTitle: string = 'Редактировать контакты офиса';
  pageSubtitle: string = 'Офисы';
  public currentContact: any = undefined;
  public currentOffice: any = undefined;
  public isSubmitDisabled: boolean = true;

  public updateOfficeContactForm = this.formBuilder.record({
    office_id: undefined,
    phone: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficesContactsRepo: OrionOfficesContactsRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentContact = history.state['contact'];
    this.currentOffice = history.state['office'];
    this.updateOfficeContactForm.patchValue({
      'phone': this.currentContact.phone,
    });
  }

  async onUpdateSubmit(): Promise<void> {
    for (var i in this.updateOfficeContactForm.controls) {
      if (this.updateOfficeContactForm.controls[i].value == undefined) {
        delete this.updateOfficeContactForm.value[i];
      }
    }

    await this.OrionOfficesContactsRepo.update(
      this.updateOfficeContactForm.value,
      this.currentContact.id,
    );

    this.router.navigate(['/orion-offices/detail'], { state: this.currentOffice });
  }

  checkIfOfficeChanged($event: any) {
    var element = $event.target;

    if (element.value != this.currentContact[element.id.replace('update-', '')]) {
      this.isSubmitDisabled = false;
    }
  }

  protected readonly UIStateType = UIStateType;
}
