import { ServiceExtension } from '../../domain/entities/service-extension';
import { Inject, Injectable } from '@angular/core';
import { ServiceExtensionMapper } from '../mappers/service-ext-mapper';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { ServiceExtensionApiDto } from '../dto/service-ext-api-dto';
import { HttpClientWrapper } from '../../../../../core/network/http.client';
import { IEnvironment } from '../../../../../environments/ienvironment';
import { APP_CONFIG } from '../../../../../environments/environment';
import { ICreateServiceExtensionRequest } from '../dto/icreate-service-ext-request';
import { PaginationResponseDto } from '../../../../../core/dashboard/data/dto/pagination-response.dto';
import { PaginationResponse } from '../../../../../entity/pagination-response/domain/pagination-response';
import { IUpdateServiceExtensionRequest } from '../dto/iupdate-service-ext-request';

@Injectable({ providedIn: 'root' })
export class ServicesExtRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpClient: HttpClientWrapper,
    private serviceExtMapper: ServiceExtensionMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAll(): Promise<DataStatus<ServiceExtension[]>> {
    let response: DataStatus<ServiceExtensionApiDto[]> = await this.httpClient.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext`,
    );
    console.log(response);
    return response.map((data) => {
      return data.map((item) => this.serviceExtMapper.toDomain(item));
    });
  }

  async create(
    serviceExtension: ICreateServiceExtensionRequest,
  ): Promise<DataStatus<ServiceExtension>> {
    let response: DataStatus<ServiceExtensionApiDto> = await this.httpClient.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext`,
      serviceExtension,
    );
    return response.map((data) => {
      return this.serviceExtMapper.toDomain(data);
    });
  }

  public async findAllByLikeSearchCriteria(
    propertyName: string,
    propertyValue: string,
    page: number,
    perPage: number,
  ): Promise<DataStatus<PaginationResponse<ServiceExtension>>> {
    let body = {
      pagination: {
        page_number: page,
        per_page: perPage,
      },
      filters: [
        { column: 'deleted_at', operator: 'eq', value: null },
        { column: propertyName, operator: 'ilike', value: `${propertyValue}%` },
      ],
    };

    let response: DataStatus<PaginationResponseDto<ServiceExtensionApiDto>> =
      await this.httpClient.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext`,
        body,
      );
    return response.map((data) => {
      return new PaginationResponse(
        data.pages,
        data.count,
        data.per_page,
        data.data.map((item) => this.serviceExtMapper.toDomain(item)),
      );
    });
  }

  async getById(id: number): Promise<DataStatus<ServiceExtension>> {
    let response: DataStatus<ServiceExtensionApiDto> = await this.httpClient.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext/${id}`,
    );
    return response.map((data) => {
      return this.serviceExtMapper.toDomain(data);
    });
  }

  public async findAllPost(
    body: any,
    page: number,
    perPage: number,
  ): Promise<DataStatus<PaginationResponse<ServiceExtension>>> {
    body['pagination'] = {
      page_number: page,
      per_page: perPage,
    };

    let response: DataStatus<PaginationResponseDto<ServiceExtensionApiDto>> =
      await this.httpClient.post(
        `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext`,
        body,
      );

    return response.map((data) => {
      return new PaginationResponse(
        data.pages,
        data.count,
        data.per_page,
        data.data.map((item) => this.serviceExtMapper.toDomain(item)),
      );
    });
  }

  async update(serviceExtId: number, body: any): Promise<DataStatus<ServiceExtension>> {
    let response: DataStatus<ServiceExtensionApiDto> = await this.httpClient.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext/${serviceExtId}`,
      body,
    );

    let successResponse = response as DataStatusSuccess<ServiceExtensionApiDto>;
    return successResponse.map((data) => {
      return this.serviceExtMapper.toDomain(successResponse.data);
    });
  }

  async delete(serviceExtId: number): Promise<void> {
    await this.httpClient.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/service_ext/${serviceExtId}`,
    );
  }
}
