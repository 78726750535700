import { StorySlide } from '../../domain/story';
import { StorySlideApiDto } from '../stories-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorySlideMapper {
  constructor() {}

  public toDomain(dto: StorySlideApiDto): StorySlide {
    return new StorySlide(
      dto.id,
      dto.header,
      dto.body,
      dto.story_id,
      dto.attachment_id,
      dto.attachment,
      dto.action_buttons,
    );
  }
}
