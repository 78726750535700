import { Component, OnInit } from '@angular/core';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { OfficeCityType } from '../../domain/orion-office';
import { OrionOfficeRepository } from '../../data/repositories/OrionOfficeRepository';
import { PageHeaderComponent } from '../../../../../components/common/page-header/page-header.component';
import { PageWrapperComponent } from '../../../../../components/common/page-wrapper/page-wrapper.component';
import { BreadcrumbsComponent } from '../../../../../components/common/breadcrumbs/breadcrumbs.component';
import { NgFor, NgIf } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-orion-offices-update',
  standalone: true,
  imports: [
    PageHeaderComponent,
    PageWrapperComponent,
    BreadcrumbsComponent,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './orion-offices-update.component.html',
  styleUrl: './orion-offices-update.component.css',
})
export class OrionOfficesUpdateComponent implements OnInit {
  pageTitle: string = 'Редактировать офис';
  pageSubtitle: string = 'Офисы';
  public officeCityType = OfficeCityType;
  public keys = Object.keys;
  public currentOffice: any = undefined;
  public isSubmitDisabled: boolean = true;

  public updateOfficeForm = this.formBuilder.record({
    address: undefined,
    longitude: undefined,
    latitude: undefined,
    city: undefined,
  });

  constructor(
    private formBuilder: FormBuilder,
    private OrionOfficeRepo: OrionOfficeRepository,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.currentOffice = history.state;
    this.updateOfficeForm.patchValue(this.currentOffice);
  }

  async onUpdateSubmit(): Promise<void> {
    for (var i in this.updateOfficeForm.controls) {
      if (this.updateOfficeForm.controls[i].value == undefined) {
        delete this.updateOfficeForm.value[i];
      }
    }

    await this.OrionOfficeRepo.update(this.updateOfficeForm.value, this.currentOffice.id);

    this.router.navigate(['/orion-offices/detail'], { state: this.currentOffice });
  }

  getOfficeCityType(officeCity: string): string {
    return OfficeCityType[officeCity as keyof typeof OfficeCityType];
  }

  checkIfOfficeChanged($event: any) {
    var element = $event.target;

    if (element.value != this.currentOffice[element.id.replace('update-', '')]) {
      this.isSubmitDisabled = false;
    }
  }

  protected readonly UIStateType = UIStateType;
}
