import { FormGroup } from '@angular/forms';
import { IServiceExtAttributesFactory } from './iservice-ext-attributes-factory';
import { ICATVPacketAttributes } from '../../../../../data/dto/icatv-packet-attributes';

export class CATVPacketAttributesFactory implements IServiceExtAttributesFactory {
  createAttributes(form: FormGroup): ICATVPacketAttributes {
    return {
      number_of_channels: form.value['numberOfChannels'],
    };
  }
}
