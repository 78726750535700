import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { Router } from '@angular/router';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { UIStateType } from '../../../../../../core/utils/wrappers/loadable';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { NgIf } from '@angular/common';
import { MobileBannerPreviewComponent } from '../../mobile-banner-preview/mobile-banner-preview.component';

@Component({
  selector: 'app-banner-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormComponentComponent,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    MobileBannerPreviewComponent,
  ],
  templateUrl: './banner-form.html',
  styleUrl: './banner-form.css',
})
export class BannerFormComponent {
  imageChangedEvent: Event | null = null;
  croppedImage?: Blob | null;

  @Input() actionButtonInput: boolean = false;
  @Input() preview: any = '';
  @Input() bannerForm!: FormGroup;
  @Input() submitButtonText: string = '';

  @Output() outputCroppedImage: EventEmitter<Blob> = new EventEmitter();
  @Output() bannerFormData: EventEmitter<FormGroup> = new EventEmitter();

  constructor(public router: Router) {}

  onSubmit() {
    this.bannerFormData.emit(this.bannerForm);
  }

  fileChangeEvent($event: any): void {
    this.imageChangedEvent = $event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.blob;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };

    if (this.croppedImage) {
      reader.readAsDataURL(this.croppedImage);
    }

    if (this.croppedImage) {
      this.outputCroppedImage.emit(this.croppedImage);
    }
  }

  removeImage(): void {
    this.imageChangedEvent = null;
  }

  addActionButtonInput() {
    this.actionButtonInput = true;
  }

  removeActionButton() {
    this.actionButtonInput = false;
    this.bannerForm.patchValue({ actionButton: undefined });
  }

  protected readonly UIStateType = UIStateType;
}
