<app-page-header [title]="pageTitle" [subtitle]="pageSubtitle"></app-page-header>

<app-page-wrapper>
  <app-loadable-wrapper [loadable]="pageConnectionRequests" loadingTitle="Загрузка данных...">
    <ng-template>
      <app-pagination-table-view
        [data]="pageConnectionRequests"
        [perPage]="perPage"
        [pageNumber]="pageNumber"
        [currentPageNumber]="currentPageNumber"
        (changePageEvent)="changePage($event)"
        (changePerPageEvent)="changePerPage($event)"
      >
        <div class="table-responsive">
          <table class="table table-vcenter card-table table-striped">
            <thead>
              <tr>
                <th *ngFor="let title of tableHeaders">{{ title }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pageConnectionRequests.data.data">
                <td>{{ item.id }}</td>
                <td>{{ item.buildingId }}</td>
                <td>{{ item.flat }}</td>
                <td>{{ item.userId }}</td>
                <td>{{ item.requested_type }}</td>
                <td>+7{{ item.phoneNumber }}</td>
                <td>{{ item.getWantedTime() }}</td>
                <td>{{ item.tariffId }}</td>
                <td>{{ item.eventId }}</td>
                <td>{{ item.connectionType }}</td>
                <td>{{ item.city }}</td>
                <td>
                  <a [href]="item.dailyLink">{{ item.dailyLink }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-pagination-table-view>
    </ng-template>
  </app-loadable-wrapper>
</app-page-wrapper>
