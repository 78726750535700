<label for="formFile" class="form-label">Изображение</label>
<input
  id="formFile"
  class="form-control"
  type="file"
  accept="image/*"
  aria-describedby="validationImageFeedback"
  (change)="selectFile($event)"
  (click)="validateCreateFileForm($event)"
/>
<div id="validationImageFeedback" class="invalid-feedback">
  Размер файла не может превышать 15 Мб.
</div>
