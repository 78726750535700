import {
  Component,
  Input,
  EventEmitter,
  Output,
  forwardRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AutocompleteSearchServiceDefInputResultComponent } from './autocomplete-search-input-result/autocomplete-search-input-result.component';
import { DataStatus, DataStatusSuccess } from '../../../../../core/network/data.status';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, from, switchMap } from 'rxjs';
import { PaginationResponse } from '../../../../../core/dashboard/domain/pagination-response';
import { CommonModule } from '@angular/common';
import { Loadable, UIStateType } from '../../../../../core/utils/wrappers/loadable';
import { ServiceDefRepository } from '../../../../dashboard/services/data/repositories/service-def-repository';
import { ServiceDef } from '../../../../dashboard/services/domain/entities/service-def';

@Component({
  selector: 'app-autocomplete-search-service-def-input',
  standalone: true,
  templateUrl: './autocomplete-search-input.component.html',
  styleUrl: './autocomplete-search-input.component.css',
  imports: [AutocompleteSearchServiceDefInputResultComponent, FormsModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSearchServiceDefInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteSearchServiceDefInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public focus: Boolean = false;

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public set value(v: any) {
    this.selectedValue = v;
    this.onChange(v);
  }

  public get value(): any {
    return this.selectedValue;
  }

  protected readonly UIStateType = UIStateType;

  // @ViewChild('inputElement') inputElement: ElementRef | null = null;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public withClearButton: boolean = false;
  @Input() public min = 3;
  @Input() public maxHeightTableResult: number = 500;
  @Input() public disabled: boolean = false;

  @Input() public delay = 500;
  @Input() public queryParams: any[] = [];

  @Output() foundEntityEmitter = new EventEmitter<ServiceDef>();

  @Input() searchString: string = '';

  public services: Loadable<PaginationResponse<ServiceDef>> | null = null;
  public selectedValue: ServiceDef | null = null;

  public queryChange$: Subject<any> = new Subject();

  /**
   * Пути в выбранном объекте, для составления строки представления
   */
  @Input() public valuePathRepresentation: string[] = [];

  /**
   * Путь до поля в выбранном объекте, который будет содержаться в value
   */
  @Input() public ngValuePath: string | null = null;

  public clear() {
    this.services = null;
  }

  public onSelectValue(entity: ServiceDef | null) {
    this.onTouch();
    if (!entity) {
      return;
    }
    this.searchString = entity.name;
    this.value = entity;
    this.clear();
    this.foundEntityEmitter.emit(entity);
  }

  clearSearchInput() {
    this.searchString = '';
    this.value = null;
  }

  constructor(private serviceDefRepository: ServiceDefRepository) {
    this.serviceDefRepository = serviceDefRepository;
  }

  ngOnDestroy(): void {
    this.queryChange$.unsubscribe();
  }

  ngOnInit(): void {
    this.queryChange$
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
        filter((query: string) => query.length >= this.min),
        switchMap((query: string) => {
          console.log('start request');
          this.services = new Loadable({ kind: 'Loading' });
          return this.serviceDefRepository.findAllByLikeSearchCriteria('name', query, 1, 15);
        }),
      )
      .subscribe(
        (result: DataStatus<PaginationResponse<ServiceDef>>) => {
          this.services = Loadable.getFromDataStatus(result);
          console.log(this.services);
        },
        (error: any) => {
          console.log(error);
        },
      );
  }

  public onFocus() {
    this.focus = true;
  }

  public onFocusOut() {
    this.focus = false;
  }
}
