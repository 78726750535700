export enum WeekDaysEnum {
  monday = 'Понедельник',
  tuesday = 'Вторник',
  wednesday = 'Среда',
  thursday = 'Четверг',
  friday = 'Пятница',
  saturday = 'Суббота',
  sunday = 'Воскресенье',
}

export class OfficeBusinessHours {
  constructor(
    public id: number,
    public officeId: number,
    public weekday: string,
    public openTime: string | null,
    public closeTime: string | null,
    public isDayOff: boolean,
  ) {}
}
