import { Injectable } from '@angular/core';
import { ServiceExtensionType } from '../../../../domain/entities/service-type';
import { IServiceExtMobilePreviewComponentFactory } from './service-ext-mobile-preview-component-factories/iservice-ext-mobile-preview-component-factory';
import { SmotreshkaTVPacketMobilePreviewComponentFactory } from './service-ext-mobile-preview-component-factories/smotreshka-tv-packet-mobile-preview-component-factory';
import { CATVPacketMobilePreviewComponentFactory } from './service-ext-mobile-preview-component-factories/catv-mobile-preview-component-factory';
import { SmotreshkaOnlineCinemaMobilePreviewComponentFactory } from './service-ext-mobile-preview-component-factories/smotreshka-online-cinema-mobile-preview-component';
import { GeneralMobilePreviewComponentFactory } from './service-ext-mobile-preview-component-factories/general-mobile-preview-component-factory';

const ServiceExtMobilePreviewComponent = {
  [ServiceExtensionType.GENERAL]: GeneralMobilePreviewComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_TV_PACKET]: SmotreshkaTVPacketMobilePreviewComponentFactory,
  [ServiceExtensionType.SMOTRESHKA_ONLINE_CINEMA]:
    SmotreshkaOnlineCinemaMobilePreviewComponentFactory,
  [ServiceExtensionType.CATV_PACKET]: CATVPacketMobilePreviewComponentFactory,
};

@Injectable({ providedIn: 'root' })
export class ServiceExtMobilePreviewComponentFactoryAggregate {
  getFactory(serviceType: ServiceExtensionType): IServiceExtMobilePreviewComponentFactory | null {
    try {
      return new ServiceExtMobilePreviewComponent[serviceType]();
    } catch {
      return null;
    }
  }
}
