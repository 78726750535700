import { AbonentService } from '../../domain/abonent-service';
import { AbonentServiceApiDto } from '../abonent-service-dto';
import { Injectable } from '@angular/core';
import { AttachmentMapper } from '../../../../../entity/attachments/data/mappers/attachment-mapper';

@Injectable({ providedIn: 'root' })
export class AbonentServiceMapper {
  constructor(private attachmentMapper: AttachmentMapper) {}

  public toDomain(dto: AbonentServiceApiDto): AbonentService {
    return new AbonentService(
      dto.id,
      dto.type,
      dto.title,
      dto.short_description,
      dto.full_description,
      dto.service_link_web,
      dto.service_link_android,
      dto.service_link_ios,
      dto.ordering,
      this.attachmentMapper.toDomain(dto.icon),
      dto.image !== null ? this.attachmentMapper.toDomain(dto.image) : null,
    );
  }
}
