<form [formGroup]="form" (ngSubmit)="onSubmit()" onsubmit="this.submit_button.disabled = true;">
  <div class="row">
    <div class="col-md-6">
      <app-form-component [title]="'Расширение услуги из биллинга'">
        <div class="mb-3">
          <label for="service" class="form-label required">Выберите услугу</label>
          <app-autocomplete-search-service-def-input
            [searchString]="billingServiceSearchStringName"
            formControlName="service"
            (foundEntityEmitter)="onFoundEntityEmitter($event)"
          ></app-autocomplete-search-service-def-input>
        </div>
        <div class="mb-3">
          <label class="form-label required">Задайте тип услуги</label>
          <select
            formControlName="service_type"
            class="form-select"
            (change)="setServiceType($event); resetForm()"
            required
          >
            <option disabled selected value="" hidden>
              Выберите тип, к которому относится услуга
            </option>
            <option *ngFor="let enum of keys(serviceExtType)" [value]="[enum]">
              {{ getServiceExtType(enum) }}
            </option>
          </select>
        </div>
        <hr class="hr" />
        <div class="mb-3">
          <ndc-dynamic
            [ndcDynamicComponent]="getFormComponent()"
            [ndcDynamicInputs]="inputs"
            [ndcDynamicOutputs]="outputs"
          ></ndc-dynamic>
        </div>
      </app-form-component>

      <div *ngIf="chosenType == 'general'">
        <app-form-component [title]="'Подробное описание услуги'">
          <ndc-dynamic
            [ndcDynamicComponent]="getFullDescComponent()"
            [ndcDynamicInputs]="fullDescInputs"
            [ndcDynamicOutputs]="fullDescOutputs"
          ></ndc-dynamic>
        </app-form-component>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card sticky-top">
        <div class="card-header">
          <h3 class="card-title">Предпросмотр услуги в приложении</h3>
        </div>
        <div class="card-body">
          <div *ngIf="chosenType == undefined">
            <app-mobile-preview></app-mobile-preview>
          </div>

          <div *ngIf="chosenType != 'general'">
            <ndc-dynamic
              [ndcDynamicComponent]="getMobilePreviewComponent()"
              [ndcDynamicInputs]="previewInputs"
              [ndcDynamicOutputs]="previewOutputs"
            ></ndc-dynamic>
          </div>

          <div class="card-tabs" *ngIf="chosenType == 'general'">
            <!-- Cards navigation -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-1"
                  class="nav-link active"
                  data-bs-toggle="tab"
                  aria-selected="true"
                  role="tab"
                  >В списке</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  href="#tab-top-2"
                  class="nav-link"
                  data-bs-toggle="tab"
                  aria-selected="false"
                  role="tab"
                  tabindex="-1"
                  >Подробное описание</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <!-- Content of card #1 -->
              <div id="tab-top-1" class="card tab-pane active show" role="tabpanel">
                <div class="card-body">
                  <ndc-dynamic
                    [ndcDynamicComponent]="getMobilePreviewComponent()"
                    [ndcDynamicInputs]="previewInputs"
                    [ndcDynamicOutputs]="previewOutputs"
                  ></ndc-dynamic>
                </div>
              </div>
              <!-- Content of card #2 -->
              <div id="tab-top-2" class="card tab-pane" role="tabpanel">
                <div class="card-body">
                  <ndc-dynamic
                    [ndcDynamicComponent]="getMobileFullDescPreviewComponent()"
                    [ndcDynamicInputs]="previewFullDescInputs"
                    [ndcDynamicOutputs]="previewFullDescOutputs"
                  ></ndc-dynamic>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-around">
            <a (click)="router.navigate(['/service-ext-list'])" class="btn btn-link"> Отмена </a>
            <button
              type="submit"
              name="submit_button"
              class="btn btn-primary"
              [disabled]="!form.valid"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
