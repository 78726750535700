import { IEnvironment } from '../../../../environments/ienvironment';
import { HttpClientWrapper } from '../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../../../environments/environment';
import { DataStatus } from '../../../../core/network/data.status';
import { SmotreshkaChannelsMapper } from '../mappers/smotreshka-channels-mapper';
import { SmotrehskaChannelsApiDto } from '../smotreshka-channels-api-dto';
import { SmotrehskaChannels } from '../../domain/smotreshka-channels';

@Injectable({ providedIn: 'root' })
export class SmotrehskaChannelsRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpClient: HttpClientWrapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
    private smotrehskaChannelsMapper: SmotreshkaChannelsMapper,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async getByPackId(packId: string): Promise<DataStatus<SmotrehskaChannels[]>> {
    let response: DataStatus<SmotrehskaChannelsApiDto[]> = await this.httpClient.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/smotreshka_channels/${packId}`,
    );

    return response.map((data) => {
      return data.map((item) => this.smotrehskaChannelsMapper.toDomain(item));
    });
  }
}
