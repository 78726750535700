import { Type } from '@angular/core';
import { IServiceExtMobilePreviewComponentFactory } from './iservice-ext-mobile-preview-component-factory';
import {
  IMobilePreviewComponentInputs,
  IMobilePreviewComponentOutputs,
} from '../../interfaces/imoblie-preview-component';
import {
  IGeneralMobilePreviewComponentInputs,
  IGeneralMobilePreviewComponentOutputs,
} from '../../interfaces/igeneral-mobile-preview-component';
import { MobileGeneralPreviewComponent } from '../../../mobile-general-preview/mobile-general-preview.component';

export class GeneralMobilePreviewComponentFactory
  implements IServiceExtMobilePreviewComponentFactory
{
  getComponent(): Type<MobileGeneralPreviewComponent> {
    return MobileGeneralPreviewComponent;
  }

  getInputs(allInputs: IMobilePreviewComponentInputs): IGeneralMobilePreviewComponentInputs {
    return {
      cost: allInputs.cost,
      title: allInputs.title,
      desc: allInputs.desc,
      bannerPreview: allInputs.bannerPreview,
      coverPreview: allInputs.coverPreview,
    };
  }

  getOutputs(allOutputs: IMobilePreviewComponentOutputs): IGeneralMobilePreviewComponentOutputs {
    return {};
  }
}
