<div class="card">
  <div class="card-header">
    <h3 class="card-title">Контакты</h3>
    <div class="ms-auto text-secondary">
      <a [routerLink]="['create-contacts']" [state]="{ 'office': currentOffice }">
        <i class="ti ti-plus"></i>
      </a>
    </div>
  </div>
  <div class="list-group list-group-flush list-group-hoverable">
    <div class="list-group-item" *ngFor="let item of currentOfficeContacts">
      <div class="row align-items-center">
        <div class="col text-truncate">
          {{ item.phone }}
        </div>
        <div class="col-auto">
          <div class="btn-list flex-nowrap">
            <a
              [routerLink]="['update-contacts']"
              [state]="{ 'office': currentOffice, 'contact': item }"
              class="list-group-item-actions"
            >
              <i class="ti ti-pencil"></i>
            </a>
            <a class="list-group-item-actions" (click)="deleteContact(item.id)">
              <i class="ti ti-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
