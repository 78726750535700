import { Type } from '@angular/core';
import { IServiceExtFormComponentFactory } from './iservice-ext-form-component-factory';
import { ServiceExtSmotreshkaOnlineCinemaFormComponentComponent } from '../../../service-ext-smotreshka-online-cinema-form-component/service-ext-smotreshka-online-cinema-form-component.component';
import { IFormComponentInputs, IFormComponentOutputs } from '../../interfaces/iform-component';
import {
  ISmotreshkaOnlineCinemaFormComponentInputs,
  ISmotreshkaOnlineCinemaFormComponentOutputs,
} from '../../interfaces/ismotreshka-online-cinema-component';
import { FormControl } from '@angular/forms';
import { ServiceExtension } from '../../../../../domain/entities/service-extension';

export class SmotreshkaOnlineCinemaFormComponentFactory implements IServiceExtFormComponentFactory {
  getComponent(): Type<ServiceExtSmotreshkaOnlineCinemaFormComponentComponent> {
    return ServiceExtSmotreshkaOnlineCinemaFormComponentComponent;
  }

  getInputs(
    allInputs: IFormComponentInputs,
    serviceExt: ServiceExtension | null,
  ): ISmotreshkaOnlineCinemaFormComponentInputs {
    let cinemaType = serviceExt?.attributes ? serviceExt.attributes['cinema_type'] : undefined;

    allInputs.form.addControl('icon', new FormControl(undefined));
    allInputs.form.addControl('banner', new FormControl(undefined));
    allInputs.form.addControl('cinemaType', new FormControl(cinemaType));

    return {
      form: allInputs.form,
    };
  }

  getOutputs(allOutputs: IFormComponentOutputs): ISmotreshkaOnlineCinemaFormComponentOutputs {
    return {
      outputBannerPreview: allOutputs.outputBannerPreview,
      outputCroppedBanner: allOutputs.outputCroppedBanner,
      outputCroppedIcon: allOutputs.outputCroppedIcon,
      outputIconPreview: allOutputs.outputIconPreview,
    };
  }
}
