<div class="card">
  <div class="card-body border-bottom py-3">
    <div class="d-flex">
      <div class="text-secondary">
        Показывать записей:
        <div class="mx-2 d-inline-block">
          <input
            type="text"
            class="form-control form-control-sm"
            size="3"
            [value]="data.data.per_page"
            (change)="changePerPage($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <ng-content [ngTemplateOutlet]="dataTemplate"></ng-content>
  <div class="card-footer d-flex align-items-center">
    <p class="m-0 text-secondary">
      Всего записей: <span> {{ data.data.count }} </span>
    </p>
    <ul class="pagination ms-auto" style="overflow: scroll">
      <li class="page-item" [class.disabled]="currentPageNumber === 1">
        <button
          (click)="changePage(currentPageNumber - 1)"
          class="page-link"
          style="white-space: nowrap"
        >
          <i class="ti ti-chevron-left"></i>
          prev
        </button>
      </li>
      <li class="page-item" *ngFor="let x of createPageArray(data.data.pages)">
        <button [class.active]="x === currentPageNumber" (click)="changePage(x)" class="page-link">
          {{ x }}
        </button>
      </li>
      <li class="page-item" [class.disabled]="currentPageNumber === data.data.pages">
        <button
          class="page-link"
          (click)="changePage(currentPageNumber + 1)"
          style="white-space: nowrap"
        >
          next
          <i class="ti ti-chevron-right"></i>
        </button>
      </li>
    </ul>
  </div>
</div>
