import { HttpClientWrapper } from '../../../../../../core/network/http.client';
import { Inject, Injectable } from '@angular/core';
import { DataStatus, DataStatusSuccess } from '../../../../../../core/network/data.status';
import { APP_CONFIG } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/ienvironment';
import { OrionOfficeMapper } from '../mappers/orion-office';
import { OrionOffice } from '../../domain/orion-office';
import { OrionOfficeApiDto } from '../orion-office-dto';

@Injectable({ providedIn: 'root' })
export class OrionOfficeRepository {
  private applicationConfig: IEnvironment;

  constructor(
    private httpService: HttpClientWrapper,
    private orionOfficeMapper: OrionOfficeMapper,
    @Inject(APP_CONFIG) applicationConfig: IEnvironment,
  ) {
    this.applicationConfig = applicationConfig;
  }

  async findAllByCity(city: string | null): Promise<DataStatus<OrionOffice[]>> {
    var payload = {
      'city': city,
    };

    let response: DataStatus<OrionOfficeApiDto[]> = await this.httpService.post(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices`,
      payload,
    );

    return response.map((data) => {
      return data.map((item) => this.orionOfficeMapper.toDomain(item));
    });
  }

  async getOfficeById(office_id: number): Promise<DataStatus<OrionOffice>> {
    let response: DataStatus<OrionOfficeApiDto> = await this.httpService.get(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices/${office_id}`,
    );
    let successResponse = response as DataStatusSuccess<OrionOfficeApiDto>;
    return successResponse.map((data) => {
      return this.orionOfficeMapper.toDomain(successResponse.data);
    });
  }

  async update(updated_office: any, office_id: number): Promise<DataStatus<OrionOffice>> {
    let response: DataStatus<OrionOfficeApiDto> = await this.httpService.patch(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices/${office_id}`,
      updated_office,
    );
    let successResponse = response as DataStatusSuccess<OrionOfficeApiDto>;
    return successResponse.map((data) => {
      return this.orionOfficeMapper.toDomain(successResponse.data);
    });
  }

  async create(new_office: any): Promise<DataStatus<OrionOffice>> {
    let response: DataStatus<OrionOfficeApiDto> = await this.httpService.put(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices`,
      new_office,
    );
    let successResponse = response as DataStatusSuccess<OrionOfficeApiDto>;
    return successResponse.map((data) => {
      return this.orionOfficeMapper.toDomain(successResponse.data);
    });
  }

  async delete(office_id: number): Promise<void> {
    await this.httpService.delete(
      `${this.applicationConfig.esysApiUrl}/admin/api/v1/offices/${office_id}`,
    );
  }
}
