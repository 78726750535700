import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropFileUploadComponent } from '../../../../../components/common/drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgIf } from '@angular/common';
import { FormComponentComponent } from '../../../../../components/form-component/form-component.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';

@Component({
  selector: 'app-service-ext-catv-packet-form-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragAndDropFileUploadComponent,
    ImageCropperComponent,
    NgIf,
    FormComponentComponent,
    LMarkdownEditorModule,
  ],
  templateUrl: './service-ext-catv-packet-form-component.component.html',
  styleUrl: './service-ext-catv-packet-form-component.component.css',
})
export class ServiceExtCATVPacketFormComponentComponent {
  @Input() form!: FormGroup;

  @Output() title: EventEmitter<string> = new EventEmitter();
  @Output() desc: EventEmitter<string> = new EventEmitter();

  onTitleChanged($event: any) {
    let title = $event.target.value;
    this.title.emit(title);
  }

  onDescChanged($event: any) {
    let desc = $event.target.value;
    this.desc.emit(desc);
  }
}
