import { IServiceExtAttributesFactory } from './iservice-ext-attributes-factory';
import { FormGroup } from '@angular/forms';
import { IGeneralAttributes } from '../../../../../data/dto/igeneral-attributes';

export class GeneralAttributesFactory implements IServiceExtAttributesFactory {
  createAttributes(form: FormGroup): IGeneralAttributes {
    return {
      text: form.value['fullDesc'],
      additional_desc: form.value['addFullDesc'],
    };
  }
}
