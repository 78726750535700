import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-preview',
  standalone: true,
  imports: [],
  templateUrl: './mobile-preview.component.html',
  styleUrl: './mobile-preview.component.css',
})
export class MobilePreviewComponent {}
