import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

/**
 * Base button component
 *
 * @example
 * <common-button [class]='btn' [icon]='fa' [name]='Save' [routerLink]="['/services-ext', 'add']></common-button>
 */

@Component({
  selector: 'app-common-button',
  standalone: true,
  imports: [NgIf],
  templateUrl: './common-button.component.html',
  styleUrl: './common-button.component.css',
})
export class CommonButtonComponent {
  @Input() public type: string = 'button';
  @Input() public disabled: boolean = false;
  @Input() public title: string = '';
  @Input() public icon: string = '';
  @Input() public class: string = 'btn btn-primary d-none d-sm-inline-block';
  @Input() public titleClass: string = '';
  @Input() public style: string = '';
  @Input() public leftSideIcon: boolean = true;
}
